import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../actions/currentUser';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import JRLogo from '../img/JR_Logo.svg';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import './Login.css';
import { userLogin, changeRemember, clearErrors } from '../store/user.js';
import { CircularProgress } from '@material-ui/core';

/** Form for logging user in */
const LoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const errors = useSelector((st) => st.user.errors);
	const currentUser = useSelector((st) => st.user.currentUser);
	const loading = useSelector((st) => st.user.pending);
	const [windowHeight, setWindowHeight] = useState(); //state to store the height of the window screen
	const [checked, setChecked] = useState(true);
	// sets state for form data and the kind of menu, which can be toggled.

	const INITIAL_STATE = {
		username: '',
		password: '',
	};

	const [formData, setFormData] = useState(INITIAL_STATE);

	/** sets "remember me" state to true or false depending on user selection*/
	const handleRememberMe = (event) => {
		setChecked(event.target.checked);
	};

	// useEffect(
	// 	() => {
	// 		dispatch(getUser(currentUser));
	// 	},
	// 	[ currentUser.username ]
	// );

	useEffect(() => {
		currentUser.username && history.push('/home');
	}, [currentUser]);

	/** sets user "remember me" status in redux */
	useEffect(() => {
		dispatch(changeRemember(checked));
	}, [checked]);

	/** clear Errors */
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

	useEffect(() => {
		function getWindowHeight() {
			// we have set a dynamic height to make the screen responsive in all screen resolution
			setWindowHeight(window.screen.availHeight);
		}
		getWindowHeight();
		window.addEventListener('resize', getWindowHeight); // the event trigered on the resolution change
		return () => window.removeEventListener('resize', getWindowHeight);
	}, []);

	// handles the change in the form. react sets form data in state every time there is a change.
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		await dispatch(userLogin(formData));
	};

	if (loading)
		return (
			<div className='container mx-auto h-screen flex justify-center'>
				<div className='flex flex-col justify-center items-center space-y-12'>
					<CircularProgress color='secondary' size={100} />
				</div>
			</div>
		);

	return (
		<Box
			data-testid='login-form'
			className={`${
				windowHeight <= 667 ? 'max-h-max h-full' : ' h-screen'
			} bg-real-estate flex items-center justify-center relative z-0`}>
			<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: 'center', display: 'flex' }}
				maxWidth='md'
				className='mt-24 mb-10'>
				<Stack spacing={4} className='w-full iphone:w-11/12 sm:w-2/3 md:w-7/12'>
					<Stack
						spacing={4}
						className='rounded-2xl pb-6  px-5 bg-white z-10 pt-0'>
						<Stack
							spacing={1}
							className='py-8 rounded-2xl -mt-12 bg-secondary  shadow-2xl'>
							<img
								className='h-12 iphone:h-16 w-auto m-0'
								src={JRLogo}
								alt='Jane Logo'
							/>
							<div className='text-white text-center text-xl md:text-3xl font-bold'>
								Login
							</div>
						</Stack>
						{/* Form starts here */}
						<Stack
							spacing={{ xs: 2 }}
							component='form'
							onSubmit={handleSubmit}
							className='px-3 '>
							<TextField
								autoFocus={true}
								id='username'
								label='Username'
								variant='standard'
								type='text'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<AccountCircle fontSize='small' />
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								name='username'
								value={formData.username}
								onChange={handleChange}
								className='border-5'
							/>

							<TextField
								id='password'
								label='Password'
								variant='standard'
								type='password'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<LockIcon fontSize='small' />
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								name='password'
								value={formData.password}
								onChange={handleChange}
							/>

							<div className='text-red-900 mb-3 '>
								{/* {errors && Object.keys(errors).length ? ( */}
								{errors.map((e, indx) => (
									<div key={indx}>
										<small>ERROR: {e}</small>
									</div>
								))}
								{/* ) : null} */}
							</div>
							<div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
								<Box className='flex justify-center '>
									<FormControlLabel
										control={
											<Checkbox
												color='primary'
												checked={checked}
												onChange={handleRememberMe}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										className='text-gray-700 text-xs md:text-sm  '
										sx={{
											'& .MuiTypography-root': {
												xs: {
													fontSize: '.75rem',
													lineHeight: '1rem',
												},
												sm: {
													fontSize: '.85rem',
													lineHeight: '1.25rem',
												},
											},
										}}
										label='Remember me'
									/>
								</Box>

								<Box className='flex justify-center md:justify-end '>
									<NavLink
										exact
										to='/forgot-password'
										className='text-tertiary hover:text-primary lg:text-sm self-center text-xs '>
										Forgot your password?
									</NavLink>
								</Box>
							</div>
							<Box className='flex justify-center'>
								<Button
									type='submit'
									sx={{
										fontWeight: 600,
										fontSize: { xs: '1rem', sm: '1.125rem' },
										lineHeight: { xs: '1.5rem', sm: '1.75rem' },
									}}
									color='secondary'>
									Get Started
								</Button>
							</Box>
						</Stack>
					</Stack>
					<div className=' flex justify-center font-semibold sm:font-bold sm:text-lg md:text-xl text-white py-2 z-10'>
						<div>
							<div />
							Don't have an account?{' '}
							<NavLink
								exact
								to='/signup'
								className=' underline hover:text-primary block text-center'>
								Create one now!
							</NavLink>{' '}
						</div>
					</div>
				</Stack>
			</Container>
		</Box>
	);
};

export { LoginForm as default };
