export const FETCH_USERS = 'FETCH_USERS';
export const REMOVE_USERS = 'REMOVE_USERS';
export const FETCH_CURRENT = 'FETCH_CURRENT';
export const FETCH_ADMIN = 'FETCH_ADMIN';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const REMOVE_CURRENT = 'REMOVE_CURRENT';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const SHOW_ERR = 'SHOW_ERR';
export const CLEAR_ERR = 'CLEAR_ERR';
export const FETCH_TOKEN = 'FETCH_TOKEN';
export const FETCH_PRICES = 'FETCH_PRICES';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const REMOVE_PRICES = 'REMOVE_PRICES';
export const SUCCESS = 'SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
