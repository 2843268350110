import react from "react";

import { PopupButton, openPopupWidget } from "react-calendly";
import Chip from "@material-ui/core/Chip";

const CalButton = ({ url, prefill, pageSettings, utm }) => {
	const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

	return (
		<Chip
			label="More Details on This"
			variant="outlined"
			onClick={onClick}
			className="border-2 border black"
		/>
	);
};

export default CalButton;
