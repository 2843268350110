import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import 'fontsource-roboto';
import './Client.css';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from 'react-hook-form';
import SublyApi from '../../helpers/Api.js';
import { updateUser, clearSuccess, clearErrors, getAllUsers } from '../../store/user.js';
import { useParams } from 'react-router-dom';

const ClientPersonalInfoForm = ({ user, setUser, setEdit }) => {
	/************ Redux definitions ******/

	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);

	/********* useState hook definitions **********/

	//sets toggle for showing and hiding confidential data
	const [showData, setShowData] = useState({
		crmUsername: false,
		crmPassword: false
	});

	/********* useParams hook definitions **********/
	const { username } = useParams();

	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors }
	} = useForm({
		defaultValues: {
			// here i set the default values in inpur field as a value
			phone: user.phone || '',
			designations: user.designations || '',
			certifications: user.certifications || '',
			specialties: user.specialties || '',
			officeAddressOne: user.officeAddressOne || '',
			officeAddressTwo: user.officeAddressTwo || '',
			officeCity: user.officeCity || '',
			officeState: user.officeState || '',
			officeZip: user.officeZip || '',
			mlsInfo: user.mlsInfo || '',
			websiteUrl: user.websiteUrl || '',
			googleBusinessUrl: user.googleBusinessUrl || '',
			crmName: user.crmName || '',
			crmUsername: user.crmUsername || '',
			crmPassword: user.crmPassword
		}
	});

	/******* Helper Functions ********/

	// updates user data in the db. Retrives all uses after update. Sets edit state to false.
	const onSubmit = async (formData) => {
		SublyApi.updateUser(username, formData, token);
		dispatch(getAllUsers({ token }));

		setEdit(false); //state for after click on save redirect to previous page
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// toggles the showData state between true and false.
	const handleShowData = (data) => {
		setShowData((showData) => ({
			...showData,
			[data]: !showData[data]
		}));
	};

	return (
		<React.Fragment>
			<div>
				<Stack
					spacing={{ xs: 1, iphone: 2 }}
					component="form"
					className="px-3"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Grid className="edit_info_spacing " container spacing={3}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Primary Number</label>
								<TextField
									className="w-full"
									format="###-###-####"
									id="phone"
									type="text"
									error={errors.phone ? true : false}
									helperText={errors.phone ? errors.phone.message : ''}
									name="phone"
									//   value={user.phone}
									placeholder="e.g. 609-629-0019"
									{...register('phone', {
										// required : 'Primary number is required',
										pattern: {
											value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
											message: 'Please enter a valid number'
										}
									})}
								/>
								{errors.phone && <span className="Error_styling">{errors.phone.message}</span>}
							</Typography>
						</Grid>

						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Designations</label>
								<TextField
									className="w-full"
									id="designations"
									type="text"
									name="designations"
									{...register('designations')}
								/>
							</Typography>
						</Grid>

						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Certifications</label>
								<TextField
									className="w-full"
									id="certifications"
									type="text"
									name="certifications"
									{...register('certifications')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Real Estate sepecialities</label>
								<TextField
									className="w-full"
									id="specialties"
									type="text"
									name="specialties"
									{...register('specialties')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Office Address One</label>
								<TextField
									className="w-full"
									id="officeAddressOne"
									type="text"
									name="officeAddressOne"
									{...register('officeAddressOne')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Office Address Two</label>
								<TextField
									className="w-full"
									id="officeAddressTwo"
									type="text"
									name="officeAddressTwo"
									{...register('officeAddressTwo')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Office City</label>
								<TextField
									className="w-full"
									id="officeCity"
									type="text"
									name="officeCity"
									{...register('officeCity')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Office State</label>
								<TextField
									className="w-full"
									id="officeState"
									type="text"
									name="officeState"
									{...register('officeState')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Office Zip</label>
								<TextField
									className="w-full"
									id="officeZip"
									type="text"
									name="officeZip"
									{...register('officeZip')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">MLS Association</label>
								<TextField
									className="w-full"
									id="mlsInfo"
									type="text"
									name="mlsInfo"
									{...register('mlsInfo')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Website URL</label>
								<TextField
									className="w-full"
									id="websiteUrl"
									type="text"
									name="websiteUrl"
									{...register('websiteUrl')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Google Business Page</label>
								<TextField
									className="w-full"
									id="googleBusinessUrl"
									type="text"
									name="googleBusinessUrl"
									{...register('googleBusinessUrl')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">CRM Name</label>
								<TextField
									className="w-full"
									id="crmName"
									type="text"
									name="crmName"
									{...register('crmName')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">CRM Login username</label>
								<TextField
									className="w-full py-3 px-4 border-1 border-blue-100 pawicon"
									id="crmUsername"
									name="crmUsername"
									{...register('crmUsername')}
									type={showData.crmUsername ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													className="eye_icon"
													aria-label="toggle password visibility"
													onClick={() => {
														handleShowData('crmUsername');
													}}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showData.crmUsername ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">CRM Password</label>
								<TextField
									className="w-full py-3 px-4 border-1 border-blue-100 pawicon"
									id="crmPassword"
									{...register('crmPassword')}
									type={showData.crmPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													className="eye_icon"
													aria-label="toggle password visibility"
													onClick={() => {
														handleShowData('crmPassword');
													}}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showData.crmPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										)
									}}
									name="crmPassword"
								/>
							</Typography>
						</Grid>
					</Grid>

					<Box className="flex justify-end">
						<Button
							className="h-14 Client_btn client_cancel_btn text-white w-10 px-4 bg-clientBlue"
							type="button"
							onClick={() => setEdit(false)}
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Cancel
						</Button>

						<Button
							className="h-14 Client_btn text-white w-10 px-3 bg-clientBlue ml-4"
							type="submit"
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</div>
		</React.Fragment>
	);
};

export default ClientPersonalInfoForm;
