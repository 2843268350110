import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import './CreateUser.css';
import Sidebar from '../sidebar/Sidebar';
import { useForm } from 'react-hook-form';
import SublyApi from '../../helpers/Api';
import { useSelector } from 'react-redux';

/** component for creating new user */
const UserForm = () => {
	//sets toggle for showing and hiding password
	const [showPassword, setShowPassword] = useState(false);
	const [errorMsg, setErrorMsg] = useState(''); //state to menage error messages coming from api rejection
	const [successMsg, setSuccessMsg] = useState(''); // state to showing success message
	const token = useSelector((st) => st.admin.token);
	const formRef = useRef();
	const defaultValues = {
		// here i set the default values in inpur field as a value
		username: '',
		password: '',
		firstName: '',
		lastName: '',
		email: '',
	};
	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors },
		reset,
	} = useForm({
		defaultValues,
	});

	/********************** Helper Functions**************** ********/

	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => {
			return !showPassword;
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	//  reset the error or success message to empty after 1.5s of user created
	const resetSuccessMsg = () => {
		setTimeout(() => {
			setSuccessMsg('');
			setErrorMsg('');
		}, 1500);
	};

	/**Set the trigger to show errors & Checks that there are no error. If no errors, form will submit.
	 * upon submission, creates new user through admin
	 */
	const onSubmit = async (formData) => {
		await SublyApi.adminUserSignup({ data: formData, token })
			.then((response) => {
				setErrorMsg(''); //set error message to empty
				setSuccessMsg('User created successfully'); //set state to show success message on creating new user
				reset(defaultValues); // reset all input fields of form to empty
				resetSuccessMsg(); // reset the error or success message to empty after 1.5s of user created
			})
			.catch((err) => {
				setSuccessMsg(''); //set success message to empty
				setErrorMsg(err[0]); //set state to show error message on api failiar
			});
	};

	/**
	 * defines the validation pattern for user password input.
	 * The password length must be greater than or equal to 8
	 * must contain one or more uppercase characters
	 * must contain one or more lowercase characters
	 * must contain one or more numeric values
	 * must contain one or more special characters
	 *
	 */
	const passwordPattern = `(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$`;

	return (
		<React.Fragment>
			<div className='container w-full max-w-full relative'>
				<div className='dashboard_row'>
					<div className='dashboard_col dashboard_col1'>
						<Sidebar />
					</div>
				</div>
				<div className='create_user_wrapper ml-60 lg:ml-80 md:ml-80'>
					<Box className='h-full mr-5 relative z-0 py-5'>
						<Stack
							spacing={4}
							className='mx-auto dashboard_wrapper rounded-2xl user_form_wrapper pb-6 px-5 bg-white z-10 pt-0 w-5/6 xs:w-5/6 iphone:w-11/12 sm:w-3/4 md:w-4/5 lg:w-3/5 xl:w-1/2'>
							<Stack
								spacing={1}
								className='py-6 rounded-2xl -mt-10  bg-quarternary shadow-2xl'>
								<div className='text-white text-center text-xl md:text-3xl font-bold'>
									Create User
								</div>
							</Stack>
							{/* Form Begins here */}
							<Stack
								spacing={{ xs: 1, iphone: 2 }}
								component='form'
								ref={formRef}
								onSubmit={handleSubmit(onSubmit)}
								className='px-3 '
								noValidate>
								<TextField
									{...register('username', {
										required: 'username is required',
										minLength: {
											value: 5,
											message: 'Username must be at least 5 characters',
										},
										maxLength: {
											value: 30,
											message: 'Username must be no more than 20 characters',
										},
										pattern: {
											value: /^[^\s]*$/,
											message: 'Username cannot contain spaces',
										},
									})}
									error={!!errors.username}
									helperText={errors.username?.message}
									autoFocus={true}
									id='username'
									required
									label='Username'
									variant='standard'
									type='text'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<AccountCircle fontSize='small' />
											</InputAdornment>
										),
										sx: {
											fontSize: { xs: '1rem', md: '1.25rem' },
											lineHeight: { xs: '1.25rem', md: '1.5rem' },
										},
									}}
									inputProps={{
										// minLength: 5,
										// maxLength: 20,
										'aria-label': 'Username',
									}}
									name='username'
									className='border-5'
								/>

								<TextField
									{...register('email', {
										required: 'email is required',
										minLength: {
											value: 6,
											message: 'Email must be at least 6 characters',
										},
										// maxLength: {
										// 	value: 30,
										// 	message: 'Email must be no more than 30 characters',
										// },
										pattern: {
											value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
											message: 'Please enter a valid email',
										},
									})}
									id='email'
									required
									error={!!errors.email}
									helperText={errors.email?.message}
									label='Email'
									variant='standard'
									type='email'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<EmailIcon fontSize='small' />
											</InputAdornment>
										),
										sx: {
											fontSize: { xs: '1rem', md: '1.25rem' },
											lineHeight: { xs: '1.25rem', md: '1.5rem' },
										},
									}}
									inputProps={{
										// minLength: 6,
										// maxLength: 30,
										'aria-label': 'Email',
									}}
									name='email'
								/>
								<TextField
									{...register('password', {
										required: 'password is required',
									})}
									data-testid='password-input'
									aria-label='Password Input'
									required
									error={!!errors.password}
									helperText={errors.password?.message}
									id='password'
									label='Password'
									variant='standard'
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
										sx: {
											fontSize: { xs: '1rem', md: '1.25rem' },
											lineHeight: { xs: '1.25rem', md: '1.5rem' },
										},
									}}
									inputProps={{
										pattern: passwordPattern,
										'aria-label': 'Password',
									}}
									name='password'
								/>
								<TextField
									{...register('firstName', {
										required: 'First name is required',
										minLength: {
											value: 2,
											message: 'First name must be at least 2 characters',
										},
										maxLength: {
											value: 30,
											message: 'First name must be no more than 20 characters',
										},
									})}
									required
									error={!!errors.firstName}
									helperText={errors.firstName?.message}
									id='firstName'
									label='First Name'
									variant='standard'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<EmojiEmotionsIcon fontSize='small' />
											</InputAdornment>
										),
										sx: {
											fontSize: { xs: '1rem', md: '1.25rem' },
											lineHeight: { xs: '1.25rem', md: '1.5rem' },
										},
									}}
									inputProps={{
										minLength: 2,
										maxLength: 20,
										'aria-label': 'First Name',
									}}
									name='firstName'
									type='text'
								/>
								<TextField
									{...register('lastName', {
										required: 'Last name is required',
										minLength: {
											value: 2,
											message: 'Last name must be at least 2 characters',
										},
										maxLength: {
											value: 30,
											message: 'Last name must be no more than 20 characters',
										},
									})}
									required
									error={!!errors.lastName}
									helperText={errors.lastName?.message}
									id='lastName'
									label='Last Name'
									variant='standard'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<EmojiEmotionsIcon fontSize='small' />
											</InputAdornment>
										),
										sx: {
											fontSize: { xs: '1rem', md: '1.25rem' },
											lineHeight: { xs: '1.25rem', md: '1.5rem' },
										},
									}}
									inputProps={{
										minLength: 2,
										maxLength: 20,
										'aria-label': 'Last Name',
									}}
									name='lastName'
									type='text'
								/>

								<div className='text-red-900 mb-3 '>
									{errorMsg ? <small>ERROR: {errorMsg}</small> : null}
								</div>
								<div className='text-green-800 mb-3 '>
									{successMsg ? <small> {successMsg}</small> : null}
								</div>
								<Box className='flex justify-center'>
									<Button
										color='quarternary'
										type='submit'
										sx={{
											fontWeight: 600,
											fontSize: { xs: '1rem', iphone: '1.125rem' },
											lineHeight: { xs: '1.5rem', iphone: '1.75rem' },
										}}>
										Add
									</Button>
								</Box>
							</Stack>
						</Stack>
					</Box>
				</div>
			</div>
		</React.Fragment>
	);
};

export default UserForm;
