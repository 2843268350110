import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nav from '../navComponents/Nav';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const currentUser = useSelector((st) => st.user.currentUser);

	const withNav = (Component) => (props) => {
		return (
			<>
				<Nav />
				<Component {...props} />
			</>
		);
	};

	return (
		<Route
			{...rest}
			render={
				currentUser && currentUser.verified
					? withNav(Component)
					: () => <Redirect to='/login' />
			}
		/>
	);
};

export default ProtectedRoute;
