import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import SublyApi from '../helpers/Api';
import { filterObject } from '../helpers/helperFunctions';

/** info we don't want in redux */
const privateUserInfo = ['crmPassword', 'crmUsername', 'notes', 'hashtags'];
// First, create the thunk
export const userSignup = createAsyncThunk(
	'user/userSignup',
	async (data, { rejectWithValue }) => {
		try {
			const { token, user } = await SublyApi.userSignup(data);

			//create customer from stripe, update user with customer id, and reload user data into storage.
			const name = `${user.firstName} ${user.lastName}`;
			const customerId = await SublyApi.createCustomer(user.email, name, token);
			const response = await SublyApi.updateUser(
				user.username,
				{ customerId },
				token
			);

			console.log(response.token);

			// send email to admin to notify of new user
			await SublyApi.sendNewUserEmail({
				token: token,
			});

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			// add token to user response
			response.token = token;

			console.log(response);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Thunk for user login
export const userLogin = createAsyncThunk(
	'user/userLogin',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.userLogin(data);

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
// Thunk for getting user
export const getUser = createAsyncThunk(
	'user/getUser',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getUser(data.username, data.token);

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Thunk for updating user
export const updateUser = createAsyncThunk(
	'user/updateUser',
	async (data, { rejectWithValue }) => {
		try {
			// throw [ 'This is an error' ];
			const response = await SublyApi.updateUser(
				data.username,
				data.data,
				data.token
			); //note: token only given when verified status is updated

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Thunk for user verification
export const changeToVerified = createAsyncThunk(
	'user/changeToVerified',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.updateUser(
				data.username,
				{ verified: true },
				data.token
			);

			await SublyApi.sendWelcomeEmail({
				username: data.username,
				token: response.token,
			});

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

//Thunk for updating images
export const updateImages = createAsyncThunk(
	'user/updateImages',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.updateImages(
				data.username,
				data.data,
				data.token
			);

			// delete sensitive information
			filterObject(response.user, privateUserInfo);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

/** Function for retrieving and storing user's stripe subscriptions
 *
 * This will get all products corresponding to user's active subscriptions on the Jane Rothe Stripe account
 *
 * response === {stripeSubs: [{stripe price object}, {stripe price object}]}
 */
export const getUserSubs = createAsyncThunk(
	'user/getUserSubs',
	async ({ customerId, token }, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getStripeSubscriptions(customerId, token);
			return response;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

/** Gets all users from API. */

export const getAllUsers = createAsyncThunk(
	'user/getAllUsers',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getUsers(data.token);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

/** Gets all subs from API*/

export const getAllSubs = createAsyncThunk(
	'user/getAllSubs',
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getAllStripeSubscriptions(data.token);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

/** Gets user's form responses Typeform API
 * data = {username, token, formsPackage}
 */

export const getTypeforms = createAsyncThunk(
	'user/getTypeforms',
	async (data, { rejectWithValue }) => {
		try {
			const typeforms = {};
			const { username, token, formPackage } = data;

			for (let i = 0; i < formPackage.length; i++) {
				const response = await SublyApi.getTypeformResponses({
					username,
					formId: formPackage[i].id,
					token,
				});
				typeforms[formPackage[i].name] = response.items;
			}

			return { typeforms };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const initialState = {
	allUsers: [],
	currentUser: {},
	allSubs: [],
	stripeSubs: [],
	typeforms: {},
	token: null,
	loading: false,
	errors: [],
	success: false, // monitors the success of an api call, in case it needs to be passed to a component
	remember: true, // controls whether a user's info is stored after dismount
	pending: false, // !note: same as loading. just a duplicate
	newRes: false, // monitors Typeforn webhook for new responses
};

export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		userLogout: (state, action) => {
			return initialState;
		},
		clearSuccess: (state, action) => {
			state.success = false;
		},
		clearErrors: (state, action) => {
			state.errors = [];
		},
		addToCurrentUser: (state, action) => {
			state.currentUser = { ...state.currentUser, ...action.payload };
		},
		changeRemember: (state, action) => {
			state.remember = action.payload;
		},
		changeNewRes: (state, action) => {
			state.newRes = action.payload;
		},
		addErrors: (state, action) => {
			state.errors = Array.isArray(action.payload)
				? action.payload
				: [action.payload];
		},
	},

	extraReducers: (builder) => {
		builder
			//* User Signup
			.addCase(userSignup.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.token = action.payload.token;
				state.errors = [];
				state.pending = false;
			})
			.addCase(userSignup.pending, (state, action) => {
				state.errors = [];
				state.pending = true;
			})
			.addCase(userSignup.rejected, (state, action) => {
				state.errors = Array.isArray(action.payload)
					? action.payload
					: [action.payload];
				state.pending = false;
			})
			//* User Login
			.addCase(userLogin.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.token = action.payload.token;
				state.errors = [];
				state.pending = false;
			})
			.addCase(userLogin.rejected, (state, action) => {
				state.errors = action.payload;
				state.pending = false;
			})
			.addCase(userLogin.pending, (state, action) => {
				state.errors = [];
				state.pending = true;
			})
			//* Get User
			.addCase(getUser.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(getUser.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Get All Users
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.allUsers = action.payload.users;
				// state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Update User
			.addCase(updateUser.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.errors = [];
				state.success = true;
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Change to Verified
			.addCase(changeToVerified.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(changeToVerified.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Update Images
			.addCase(updateImages.fulfilled, (state, action) => {
				state.currentUser = action.payload.user;
				state.errors = [];
				state.success = true;
				state.loading = false;
			})
			.addCase(updateImages.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(updateImages.rejected, (state, action) => {
				state.errors = action.payload;
				state.loading = false;
			})
			//* Get User Subs
			.addCase(getUserSubs.fulfilled, (state, action) => {
				state.stripeSubs = action.payload.stripeSubs;
				state.errors = [];
			})
			.addCase(getUserSubs.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Get typeforms
			.addCase(getTypeforms.fulfilled, (state, action) => {
				state.typeforms = action.payload.typeforms;
				state.errors = [];
			})
			.addCase(getTypeforms.rejected, (state, action) => {
				state.errors = action.payload;
			})
			//* Get all Subs
			.addCase(getAllSubs.fulfilled, (state, action) => {
				state.allSubs = action.payload.stripeSubs;
				state.errors = [];
			})
			.addCase(getAllSubs.rejected, (state, action) => {
				state.errors = action.payload;
			});
	},
});

export const {
	userLogout,
	clearSuccess,
	clearErrors,
	addToStorage,
	changeRemember,
	changeNewRes,
	addErrors,
} = userSlice.actions;

export default userSlice.reducer;
