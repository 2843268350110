import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import 'fontsource-roboto';
import './Client.css';
import ClientNotesDetail from './AdminInfo.js';
import SublyApi from '../../helpers/Api.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, clearSuccess, clearErrors, getAllUsers } from '../../store/user.js';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const AdminInfoForm = ({ user, setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();

	const token = useSelector((st) => st.admin.token);

	/********* useParams hook definitions **********/
	const { username } = useParams(); //here i used useparams for passing the username through Routing

	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors }
	} = useForm({
		defaultValues: {
			// here i set the default values in inpur field as a value
			notes: user.notes || '',
			hashtags: user.hashtags || '',
			spendingTracker: user.spendingTracker || '',
			customerId: user.customerId || ''

		}
	});

	/******* Helper Functions ********/

	//fetching the form data and passing to the API
	const onSubmit = async (formData) => {
		formData.spendingTracker = formData.spendingTracker.trim();  // trim the empty string
		await SublyApi.updateUser(username, formData, token);
		dispatch(getAllUsers({ token })).then((response) => {
			if (response.meta.requestStatus == "fulfilled") {
				setEdit(false);//state for after click on save redirect to previous page
			}

		});
	};

	return (
		<React.Fragment>
			<div>
				<Stack
					spacing={{ xs: 1, iphone: 2 }}
					component="form"
					className="px-3"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Grid className="edit_info_spacing" container spacing={3}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className="client_form_wrapper">
								<div className="client_notes_btn mb-6">
									<h5>Client Notes</h5>
								</div>
								<Typography className="w-2/4 p-0 inline edit_notes_fiels">
									<label className="leading-5 text-lg text-gray-500" />
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="notes"
										name="notes"
										minRows={2}
										type="text"
										{...register('notes')}
									/>
								</Typography>
							</div>
						</Grid>

						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className="client_form_wrapper">
								<div className="client_notes_btn mb-6">
									<h5>Hashtags</h5>
								</div>
								<Typography className="w-2/4 p-0 inline edit_notes_fiels">
									<label className="leading-5 text-lg text-gray-500" />
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="hashtags"
										name="hashtags"
										minRows={2}
										type="text"
										{...register('hashtags')}
									/>
								</Typography>
							</div>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className="client_form_wrapper">
								<div className="client_notes_btn mb-6">
									<h5>Spending Tracker</h5>
								</div>
								<Typography className="w-2/4 p-0 inline edit_notes_fiels">
									<label className="leading-5 text-lg text-gray-500" />
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="spendingTracker"
										name="spendingTracker"
										minRows={2}
										error={errors.spendingTracker && errors.spendingTracker.message}
										helperText={errors.spendingTracker && errors.spendingTracker.message}
										type="text"
										{...register('spendingTracker', {
											pattern: {
												value: /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?|(^(?!.*\S))/,
												message: 'Please enter a valid url'
											}
										})}
									/>
								</Typography>
							</div>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className="client_form_wrapper">
								<div className="client_notes_btn mb-6">
									<h5>Customer Id</h5>
								</div>
								<Typography className="w-2/4 p-0 inline edit_notes_fiels">
									<label className="leading-5 text-lg text-gray-500" />
									<TextField
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="customerId"
										name="customerId"
										type="text"
										{...register('customerId')}
									/>
								</Typography>
							</div>
						</Grid>
					</Grid>

					<Box className="flex justify-end">
						<Button
							className="h-14 Client_btn client_cancel_btn text-white w-10 px-4"
							type="button"
							onClick={() => setEdit(false)}
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Cancel
						</Button>
						<Button
							className="h-14 Client_btn text-white w-10 px-3 bg-clientBlue ml-4"
							type="submit"
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</div>
		</React.Fragment>
	);
};

export default AdminInfoForm;
