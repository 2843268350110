import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Typography,
	Button,
	Tab,
	Box,
	Tabs,
	Grid,
	Container,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import 'fontsource-roboto';
import './Settings.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProfileInfo from './ProfileInfo.js';
import ChangePassword from './ChangePassword.js';
import DetailCard from './DetailCard';

import { clearErrors } from '../../store/user';

/** Component for Settings page. */

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box
					sx={{ p: 3 }}
					className='rounded-xl border shadow border-t-12 border-t-quarternary overflow-hidden'>
					{children}
					{/* <Typography></Typography> */}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Settings() {
	const dispatch = useDispatch();
	const scrollToRef = useRef();
	const [value, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	/** clear Errors on mount and dismount */
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

	// Will scroll smoothly to the top of the next section on page load
	useLayoutEffect(() => {
		if (
			scrollToRef.current &&
			typeof scrollToRef.current.scrollIntoView === 'function'
		) {
			scrollToRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
		}
	}, []);

	const handleClickScroll = () => {
		// Will scroll smoothly to the top of the next section
		scrollToRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
	};

	return (
		// Main Setting Page Start

		<div className='tab_wrapper pb-10 '>
			{/* Main header start */}
			<div className='main_heading max-w-7xl mx-auto mb-3 px-2 sm:px-6 lg:px-4  pb-14  '>
				<Stack
					direction='row'
					justifyContent='center'
					spacing={2}
					className=' my-10'>
					<Box
						component='span'
						className=' font-sans font-extrabold text-zinc-600 text-3xl md:text-5xl p-4'>
						My Settings
					</Box>
				</Stack>
				<Stack spacing={2} className='my-6'>
					<Box component='span' className=' py-2 flex items-center'>
						<Link
							to='/home'
							className='flex items-center lg:justify-start md:justify-start sm:justify-start '>
							<Button
								variant='contained'
								size='small '
								sx={{
									bgcolor: 'septenary.main',
									borderRadius: '15%',
									'&:hover': {
										bgcolor: 'quarternary.main',
										cursor: 'pointer',
										'& .arrowIcon': {
											color: 'white',
										},
									},
								}}>
								<ArrowBackIosIcon
									className='arrowIcon'
									sx={{ color: 'octonary.main' }}
								/>
							</Button>
							<span className='ml-3  md:inline text-gray-500 font-semibold'>
								Home
							</span>
						</Link>
					</Box>
				</Stack>
			</div>

			<Box
				ref={scrollToRef}
				className='setting_wrapper flex justify-between max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 '
				sx={{ flexGrow: 1, display: 'flex' }}>
				<Grid container spacing={2}>
					{/* Tab Section start */}
					<Grid
						className=' setting_options'
						item
						lg={3}
						xl={3}
						md={4}
						sm={5}
						iphone={4}
						xs={12}>
						<Tabs
							className=' shadow border rounded-lg h-40'
							orientation='vertical'
							variant='scrollable'
							value={value}
							onChange={handleChange}>
							<Tab
								onClick={() => handleClickScroll()}
								className=' text-base '
								label='Profile'
								{...a11yProps(0)}
							/>
							<Tab
								onClick={() => handleClickScroll()}
								className='text-base'
								label='Change Password'
								{...a11yProps(1)}
							/>
							<Tab
								onClick={() => handleClickScroll()}
								className='text-base'
								label='Details'
								{...a11yProps(2)}
							/>
						</Tabs>
					</Grid>
					{/* Add Profile Form section start */}
					<Grid
						className=' setting_form_wrapper'
						item
						lg={9}
						xl={9}
						md={8}
						sm={7}
						iphone={8}
						xs={12}>
						<TabPanel value={value} index={0}>
							<div className=' p-8 pt-12 bg-white shadow  '>
								<div className='profile_form_wrapper pb-4'>
									<ProfileInfo />
								</div>
							</div>
						</TabPanel>

						{/* Change Password Form section start */}
						<TabPanel value={value} index={1}>
							<ChangePassword />
						</TabPanel>

						{/* Add Detail Form section start */}
						<TabPanel className='detail_box' value={value} index={2}>
							<DetailCard />
						</TabPanel>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
