import React, { useState, useContext, useEffect } from 'react';
import {
	Card,
	ButtonBase,
	IconButton,
	CardContent,
	Typography,
	CardActions,
	Button,
	CardActionArea,
	Tooltip,
	Paper,
} from '@material-ui/core';
import './ProductPreview.css';
import ConfirmationDialog from './ConfirmationDialog';

import { useSelector, useDispatch } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

import { BASE_URL, STRIPE_PUBLISHABLE_KEY } from '../../config';

import SublyApi from '../../helpers/Api';

// Make sure to call `loadStripe` outside of a component’s render to avoid re-renders
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

/** This component is used for users to browse product before subscribing.
 * This is a tile of product information
 */

const ProductPreview = ({
	icon,
	price,
	description,
	title,
	id,
	toggleDrawer,
	setLoading,
	className,
	iconColor,
	actionColor,
	subtitle,
}) => {
	/************ Redux definitions ******/
	const token = useSelector((st) => st.user.token);
	const currentUser = useSelector((st) => st.user.currentUser);
	const dispatch = useDispatch();

	/********* useState hook definitions **********/

	const [dialogOpen, setDialogOpen] = useState(false);

	/******* Helper Functions ********/

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	/**handles the clicking of each product tile
	 * creates a stripe checkout session using the product's priceId and customer Id
	 * redirects user to hosted stripe checkout using session Id
	 */

	const handleClick = async (event) => {
		setLoading(true);
		try {
			const stripe = await stripePromise;

			const response = await SublyApi.createCheckoutSession(
				{ priceId: id, customerId: currentUser.customerId },
				token
			);

			const sessionId = response.sessionId;

			// When the customer clicks on the button, redirect them to Checkout.
			const result = await stripe.redirectToCheckout({
				sessionId: sessionId,
			});

			if (result.error) {
				// If `redirectToCheckout` fails due to a browser or network
				// error, display the localized error message to your customer
				// using `result.error.message` or a customized message.
				setLoading(false);
				alert(
					'Oh Darn! Something went wrong when redirecting. Please alert admin'
				);
			}
		} catch (err) {
			console.log(err);
			setLoading(false);
			//this is designed to catch errors from our api while trying create a session.
			alert('Oh Darn! Something went wrong. Please alert admin');
		}
	};

	return (
		<div className={`w-full hover:drop-shadow-xl ${className}`}>
			<Tooltip title={description} arrow placement='top'>
				<ButtonBase className='w-full h-full' onClick={handleDialogOpen}>
					<Card
						className='w-full h-full flex flex-col'
						sx={{ overflow: 'visible' }}>
						<CardContent className='  flex-grow '>
							<span
								className={`relative bottom-10 inline-flex items-center justify-center p-2 ${iconColor} rounded-md shadow-lg border-2 border-stone-500`}>
								{icon}
							</span>
							<Typography className='' variant='subtitle1'>
								{title}
							</Typography>
							<Typography className='' variant='subtitle1'>
								{subtitle}
							</Typography>
							<Typography className=''>
								<b>${price}/month</b>
							</Typography>
						</CardContent>
						<CardActionArea component='div' className=''>
							<Button component='div' size='small' color={actionColor}>
								Subscribe
							</Button>
						</CardActionArea>
					</Card>
				</ButtonBase>
			</Tooltip>
			<ConfirmationDialog
				title='Request your subscription'
				handleClose={handleDialogClose}
				handleConfirmation={handleClick}
				open={dialogOpen}
				close={'Not now'}
				confirmation={'Confirm'}
				text={`You want to subscribe to the ${title} service for $${price} a month? (Confirm and you will be taken to a payment page)`}
			/>
		</div>
	);
};

export default ProductPreview;
