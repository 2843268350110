import { useState, useEffect } from 'react';

/**
 * A custom hook that manages the state of a form package based on the provided stripe subscriptions.
 *
 * @param {Array} stripeSubs - An array of stripe subscriptions.
 * @returns {Array} The current form package based on the matching subscriptions.
 */

export function useFormPackage(stripeSubs, Forms) {
	const [formPackage, setFormPackage] = useState([]);

	useEffect(() => {
		// Iterate through the available form packages
		for (const pkg of Forms.packages) {
			// Check if the user has any of the trigger subscriptions for the current package
			const hasSubscription = pkg.triggerSubscriptions.some((subName) =>
				stripeSubs.some((s) => s.productName?.includes(subName))
			);
			// If the user has a matching subscription
			if (hasSubscription) {
				// Set the corresponding form package in the state
				setFormPackage(pkg.forms);
				// Break the loop since we've found the matching package
				break;
			}
		}
	}, [stripeSubs]); // Re-run the effect when the stripeSubs change

	return formPackage;
}
