import IconGTranslate from '../img/service-detail/service-detail1.png';
// import IconFacebook from '../img/service-detail/service-detail9.png';
import IconAtSymbolIcon from '../img/service-detail/service-detail2.png';
import IconWebOutlined from '../img/service-detail/service-detail3.png';
import IconInstagram from '../img/service-detail/service-detail4.png';
import IconRefreshIcon from '../img/service-detail/service-detail5.png';
import IconMailIcon from '../img/service-detail/service-detail6.png';
import IconServerIcon from '../img/service-detail/service-detail7.png';
import IconFireIcon from '../img/service-detail/service-detail8.png';

class Offerings {
	static services = [
		{
			id: 1,
			name: 'Google Ads Management',
			icon: IconGTranslate,
			price: 175,
			features: [
				'Google PPC & Local Service Ads Management',
				'Google Ads Account Setup',
				'Ongoing Ad Optimization',
				'​​Keyword Research',
				'Manual Bidding',
				'Geo-Targeting',
				'Search Term Audit',
				'Selection of Keyword Match Types',
				'Conversion Tracking ',
				'Service-Category Targeting',
				'Ad Scheduling',
			],
			extra: '+Ad Spend',
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},
		// {
		// 	id          : 2,
		// 	name        : 'Facebook & Instagram Ads',
		// 	icon        : IconFacebook,
		// 	price       : 125,
		// 	features    : [
		// 		'Advertising Software Setup & Maintenance',
		// 		'Facebook & Instagram Retargeting Ad Campaign',
		// 		'Geographic Targeting & Custom Audience Remarketing',
		// 		'Ongoing Ad Optimization',
		// 		'Monthly Reporting (as Requested)',
		// 		'Ad Scheduling'
		// 	],
		// 	extra       : '+Ad Spend',
		// 	Description :
		// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
		// 	border      : 'border'
		// },
		{
			id: 3,
			name: 'Digital Billboards - Retargeting',
			icon: IconWebOutlined,
			features: [
				'Strategically Placed Internet Ads',
				'Banner Advertising',
				'Mobile Advertising',
				'Target Your Sphere of Influence',
				'Target Lookalike Audiences',
				'Target Geo Specific Audiences',
				'Personalized Designs',
				'Software Setup & Management',
				'Ad Optimization',
			],
			price: 125,
			extra: '+Ad Spend',
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},
		{
			id: 4,
			name: 'Social Media Marketing',
			icon: IconInstagram,
			price: 395,
			features: [
				'Brand Building',
				'Increase Following',
				'Increase Engagement',
				'Increase Traffic',
				'Personalized Images',
				'Posts 3x week',
			],
			chip: true,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},
		{
			id: 5,
			name: 'Email Marketing',
			icon: IconAtSymbolIcon,
			features: [
				'24 Emails',
				'Send to Database 1-2x a Month',
				'Option to Send up to 4x a Month',
				'Branded Email Templates',
				'Software Setup & Management',
			],
			price: 100,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},
		{
			id: 6,
			name: 'Listing & Post-Close Execution',
			icon: IconRefreshIcon,
			features: [
				'Listing - We help you market your listings and provide you material that impresses your clients',
				'Closing - We manage multiple closing tasks, including reputation management and client nurturing.',
			],
			price: 395,
			// chip     : <Chip label="More Details on This" variant="outlined" onClick={handleClick} />,
			chip: true,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},

		{
			id: 7,
			name: 'Direct Mail Marketing',
			icon: IconMailIcon,
			features: [
				'Graphic Design',
				'Campaign Management',
				'Hard Mailers 12, 6, or 4x Yearly',
				'Mailers Sent to Database',
			],
			price: 195,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
			starting: 'Starting @',
		},
		{
			id: 8,
			name: 'Website + CRM Management',
			icon: IconServerIcon,
			features: [
				'Website Set Up',
				'Website Maintenance & Updates',
				'SEO',
				'CRM Setup and Management',
			],
			price: 295,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border',
		},
		{
			id: 9,
			name: 'The Gold Package',
			icon: IconFireIcon,
			features: [
				'Everything Above - Minus Website & CRM',
				'Business Book',
				'Sellers Guide',
				'Buyers Guide',
			],
			price: 850,
			extra: '+Hard Costs',
			chip: true,
			Description:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt...',
			border: 'border-2 border-primary',
		},
	];
}

export default Offerings;
