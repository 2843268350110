/** takes an object and an array of keys.  Deletes all the keys in the object */
export const filterObject = (obj, keys) => {
	keys.map((key) => {
		delete obj[key];
	});
};

export const groupHoverColorVariants = {
	primary: 'group-hover:text-primary',
	secondary: 'group-hover:text-secondary',
	tertiary: 'group-hover:text-tertiary',
	quarternary: 'group-hover:text-quarternary',
	quinary: 'group-hover:text-quinary',
	senary: 'group-hover:text-senary',
};
export const textColorVariants = {
	primary: 'text-primary',
	secondary: 'text-secondary',
	tertiary: 'text-tertiary',
	quarternary: 'text-quarternary',
	quinary: 'text-quinary',
	senary: 'text-senary',
};
export const bgGroupHoverColorVariants = {
	primary: 'group-hover:bg-primary',
	secondary: 'group-hover:bg-secondary',
	tertiary: 'group-hover:bg-tertiary',
	quarternary: 'group-hover:bg-quarternary',
	quinary: 'group-hover:bg-quinary',
	senary: 'group-hover:bg-senary',
};

export const bgColorVariants = {
	primary: 'bg-primary',
	secondary: 'bg-secondary',
	tertiary: 'bg-tertiary',
	quarternary: 'bg-quarternary',
	quinary: 'bg-quinary',
	senary: 'bg-senary',
};
export const bgFadedColorVariants = {
	primary: 'bg-fadedPrimary',
	secondary: 'bg-fadedSecondary',
	tertiary: 'bg-fadedTertiary',
	quarternary: 'bg-fadedQuarternary',
	quinary: 'bg-quinary',
	senary: 'bg-senary',
};
