export default function BlockQuote({ quote, avatar, person }) {
	return (
		<div className="mt-8 border-t border-gray-200 pt-6">
			<blockquote>
				<div>
					<p className="text-base text-gray-500 px-0">&ldquo;{quote}&rdquo;</p>
				</div>
				<footer className="mt-3">
					<div className="flex items-center space-x-3">
						<div className="flex-shrink-0">
							<img className="h-6 w-6 rounded-full ml-0" src={avatar} alt="" />
						</div>
						<div className=" text-sm iphone:text-base font-medium text-gray-700">{person}</div>
					</div>
				</footer>
			</blockquote>
		</div>
	);
}
