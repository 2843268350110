import React, { useState,useEffect } from 'react';
import Popup from '../../img/service-detail/sd_popup.png';
import PoClosepup from '../../img/service-detail/popup_close.png';
import PopopCheck from '../../img/service-detail/popup_check.png';  

function ServiceDetailPopup({ setOpenModal,serviceDetail }) { 
  console.log("calling", serviceDetail)

  return (
    <>
    <div className="modalBackground"> 
      <div className="modalContainer">
        <div className="modalContainerSec">
          <div className="titleCloseBtn">
          <img className="" src={Popup} alt="popup"/>
            <button 
              onClick={() => {  
                setOpenModal(false);
              }}
            >   
            <img className="" src={PoClosepup} alt="popup"/>
            </button>
          </div>
          <div className="title">
            <h1 className="text-2xl font-bold text-center">{serviceDetail.name}</h1>
          </div>
          <div className="price pt-10">
            <p className="text-5xl font-bold">{serviceDetail.price}</p> 
            <h5 className="text-xl text-slate-200 font-medium">{serviceDetail.extra}</h5> 
          </div>   
          <div className="body modal_body">
          <p className="text-sm text-slate-400 py-4 px-12 sd_popup_text text-center modal_body_text m-auto">{serviceDetail.Description}</p>
          <ul>
          {serviceDetail.features.map((feature, index) => (
            <li>   
              <span><img className="w-5 h-5 inline" src={PopopCheck} alt="popup"/></span>   
                <p className="text-left p-0 leading-none block">
                {feature}
                </p>          
            </li>
            ))}
          </ul>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ServiceDetailPopup;