import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, TextField, Grid, Typography, TextareaAutosize } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import 'fontsource-roboto';
import './Settings.css';
// import { updateUser } from '../../actions/currentUser';

import { updateUser, clearSuccess, clearErrors } from '../../store/user.js';
import Alert from '@mui/material/Alert';

const OtherInfoForm = ({ setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();
	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);

	const errors = useSelector((st) => st.user.errors); // holds server side errors

	//holds success state of call to edit-user api
	const success = useSelector((st) => st.user.success);

	/********* useState hook definitions **********/

	const INITIAL_STATE = {
		serviceAreas: currentUser.serviceAreas || '',
		uniqueness: currentUser.uniqueness || '',
		favoritePlaces: currentUser.favoritePlaces || '',
		travelDestinations: currentUser.travelDestinations || '',
		hobbies: currentUser.hobbies || '',
		otherDetails: currentUser.otherDetails || ''
	};

	// sets state for form data
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for form error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers to true when user submits form. Errors will display if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	/**************** useEffect hook definitions ************/

	/** changes the edit state depending on the success of call to api.
	 * if success is false, then there has been no call to api or there is an error. user stays on form
	 * if success is true, user has successfully submitted edit to api. From changes to card.
	 **/

	useEffect(
		() => {
			setEdit(!success);
		},
		[success]
	);

	// clears error and success messages from store when component dismounts
	useEffect(() => {
		return () => {
			dispatch(clearSuccess());
			dispatch(clearErrors());
		};
	}, []);

	/********* useRef hook definitions *************/

	//sets ref for form. used on form submit to check for errors
	const formRef = useRef();

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((formData) => ({ ...formData, [name]: value }));

		setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: e.target.validationMessage }));
	};

	/**
	 * handles submission of the form. Sets error trigger on to show potential errors.
	 * Updates user only if the form has no errors. 
	 */

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorTrigger(true);

		console.log(formRef.current.checkValidity());

		if (formRef.current.checkValidity()) {
			dispatch(updateUser({ username: currentUser.username, data: formData, token: token }));
		}
	};

	return (
		<div>
			{/* Add Detail Broker Information section start */}
			{Object.keys(errors).length ? (
				<div className="generic_error mt-6">
					<Alert
						className="mt-0 p-0 w-full bottom-0 left-0 z-10 "
						severity="error"
						onClose={() => dispatch(clearErrors())}
					>
						{'ERROR: Something went wrong with submission. Please report to admin.'}
					</Alert>
				</div>
			) : null}

			<div className="detail_sec2">
				<Stack spacing={{ xs: 1, iphone: 2 }} component="form" onSubmit={handleSubmit} noValidate ref={formRef}>
					<div className="profile_form_wrapper edit_info_spacing pt-6">
						<Grid container spacing={3}>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What are your main Service Areas?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="serviceAreas"
										name="serviceAreas"
										value={formData.serviceAreas}
										minRows={1}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What makes you unique in real state?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 text-gray-900"
										id="uniqueness"
										name="uniqueness"
										value={formData.uniqueness}
										minRows={1}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										Where are some of your favorite places within your service area(s)?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 text-gray-900"
										id="favoritePlaces"
										name="favoritePlaces"
										value={formData.favoritePlaces}
										onChange={handleChange}
										minRows={1}
										type="text"
										placeholder=" i.e.
										parks, eateries, bakeries, coffee shops, wineries,etc."
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										Where is your favorite place(s) to which you have traveled?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 text-gray-900"
										id="travelDestinations"
										name="travelDestinations"
										value={formData.travelDestinations}
										minRows={1}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What are some of your hobbies?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 text-gray-900"
										id="hobbies"
										name="hobbies"
										value={formData.hobbies}
										minRows={1}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										Other fun details about you
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 text-gray-900"
										id="otherDetails"
										name="otherDetails"
										value={formData.otherDetails}
										minRows={1}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
						</Grid>

						<div className="detail_btn_box lg:flex md:flex sm:block lg:justify-between md:justify-between sm:justify-between items-end">
							<Typography className="text-right flex justify-end" mt={3}>
								<Button
									className="h-12 setting_cancel_btn setting_btn text-white bg-clientBlue"
									type="button"
									onClick={() => setEdit(false)}
									sx={{
										fontWeight: 600,
										fontSize: { xs: '1rem', iphone: '1.125rem' },
										lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
									}}
								>
									Cancel
								</Button>
								<Button
									className="h-12 setting_btn text-white setting_btn2"
									type="submit"
									sx={{
										fontWeight: 600,
										fontSize: { xs: '1rem', iphone: '1.125rem' },
										lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
									}}
								>
									Save
								</Button>
							</Typography>
						</div>
					</div>
				</Stack>
			</div>
		</div>
	);
};

export default OtherInfoForm;
