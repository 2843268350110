/** @format */
import React, { useEffect, useState } from 'react';
import "fontsource-roboto";
import { Typography, Grid, Button } from '@material-ui/core';
import ClientOtherInfoForm from './ClientOtherInfoForm.js' 
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import "./Client.css";  
  
/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const ClientOtherInfo = (props) => {
  const [ edit, setEdit ] = useState(false);
return (   
<>
<div>
  {edit? <ClientOtherInfoForm user={props.user} setUser={props.setUser} setEdit={setEdit}/> : 
    <div className="profile_form_wrapper edit_info_spacing client_font_stypeP">  
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">What are your main service area? </h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.serviceAreas}</p>
    </Typography>
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">What makes you unique in real estate?</h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.uniqueness}</p>
    </Typography>
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">Where are some of your favorite places within your service area(S) i.e parks, Activities, 
Eateries, Bakeries, Live Music, Coffee, wineries, etc.
 </h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.travelDestinations}</p>
    </Typography>
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">Where is/are your favorite place (S) you have traveled to? </h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.favoritePlaces}</p>
    </Typography>
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">What are some of your hobies? </h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.hobbies}</p>
    </Typography>
    <Typography className="pb-6">
      <h3 className="text-gray-900 text-lg leading-8">Other details </h3>
      <p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{props.user.otherDetails}</p> 
    </Typography>
    
    <Typography className="text-right flex justify-end" mt={6}>
         <button
            type="submit"
            onClick={() => setEdit(true)}
         variant="contained"
         className="h-14 client_btn"
         >
            <ModeEditOutlineIcon />
         </button>
      </Typography>
</div>
}
</div>
</>
);
};
export default ClientOtherInfo;
