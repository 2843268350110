import SectionHeader from './SectionHeader';
import HeroSection from './HeroSection';
import FeaturesRight from './FeaturesRight';
import FeaturesLeft from './FeaturesLeft';
import Steps from './Steps';
import {
	CurrencyDollarIcon,
	SparklesIcon,
	FingerPrintIcon,
} from '@heroicons/react/outline';
import dealTeal from '../img/dealTeal.svg';
import buildBrandTeal from '../img/buildBrandTeal.svg';
import personalizeTeal from '../img/personalizeTeal.svg';
import Pricing from './Pricing';
import Footer from './Footer';
import jerry from '../img/jerry_photo.jpg';
import carla from '../img/carla_saheed.jpg';
import { PopupButton } from 'react-calendly';
import ReactGA from '../helpers/analytics';

const valuePropOne = {
	title: "It's time to level up your brand",
	message:
		'The power of a massive brand is right within reach.  We strategize for you to increase your visibility and engagement everywhere.',
	icon: <SparklesIcon className='h-6 w-6 text-white' aria-hidden='true' />,
	buttonText: "Let's Level Up!",
	quote:
		'I was referred to Jane by a good friend.  When I came on board, I was immediately impressed. Jane offers more value in terms of personalization, strategies, and going over and beyond for their clients. I am getting more bang for my buck. Switching companies has been so good for my business!',
	avatar: carla,
	person: 'Carla S., Sacramento, CA',
	buttonLink: '/signup',
};

const valuePropTwo = {
	title: "Let's close more deals",
	message:
		'Get ahead of the competition! Generate more top of mind and net-new leads with our multi touchpoint strategy. We use advanced marketing and advertising systems curated perfectly to fit your needs.',
	icon: (
		<CurrencyDollarIcon className='h-6 w-6 text-white' aria-hidden='true' />
	),
	buttonText: 'I want more deals!',
	buttonLink: '/signup',
};

const valuePropThree = {
	title: 'We make it about you',
	message:
		'All your outward facing marketing materials are a direct reflection on who you are. So we personalize your marketing and speak in your voice. ',
	icon: <FingerPrintIcon className='h-6 w-6 text-white' aria-hidden='true' />,
	buttonText: 'Tell me more!',
	quote:
		'I have been extremely satisfied with what Jane has done to support and market my business. There are very few marketing companies for real estate agents that dedicate so much time to personalize the content to match my brand. Our content and designs look very distinct from others. Additionally, their marketing strategies work! I have already gotten multiple leads from the work they are doing for me on Google.',
	avatar: jerry,
	person: 'Jerry A., Los Angeles, CA',
	buttonLink: 'https://calendly.com/amy-janerothe/one-one',
};

const sectionHeaderOne = {
	snippet: 'innovative marketing',
	title: 'We execute for you',
	body: 'Your time is very important! Don’t worry about keeping up with new marketing and tech trends. While you sell, leave it to us to efficiently build and execute your marketing operation.',
};

const sectionHeaderTwo = {
	snippet: 'We Got This!',
	title: 'We are here to make your life better',
	body: "At Jane, we blend the efficiency of technology with the comfort and warmth of high quality service, ensuring your marketing strategy is both innovative and personal. Let's create something great together!",
};

const sectionHeaderThree = {
	snippet: 'we are here for you',
	title: "You don't have to do this alone",
	body: "Real estate can be difficult and lonely.  Don't worry, we have your back! We offer complimentary advisory calls to help you strategize and organize your business. Join the family, let’s level up your business together! ",
};

export default function LandingPage() {
	return (
		<>
			<a id='signup' />
			<HeroSection />
			<a id='about' />
			<SectionHeader
				snippet={sectionHeaderOne.snippet}
				title={sectionHeaderOne.title}
				body={sectionHeaderOne.body}
			/>
			<FeaturesRight
				title={valuePropOne.title}
				message={valuePropOne.message}
				icon={valuePropOne.icon}
				img={buildBrandTeal}
				buttonText={valuePropOne.buttonText}
				quote={valuePropOne.quote}
				avatar={valuePropOne.avatar}
				person={valuePropOne.person}
				buttonLink={valuePropOne.buttonLink}
			/>
			<FeaturesLeft
				title={valuePropTwo.title}
				message={valuePropTwo.message}
				icon={valuePropTwo.icon}
				img={dealTeal}
				buttonText={valuePropTwo.buttonText}
				buttonLink={valuePropTwo.buttonLink}
			/>
			<FeaturesRight
				title={valuePropThree.title}
				message={valuePropThree.message}
				icon={valuePropThree.icon}
				img={personalizeTeal}
				buttonText={valuePropThree.buttonText}
				quote={valuePropThree.quote}
				avatar={valuePropThree.avatar}
				person={valuePropThree.person}
				buttonLink={valuePropThree.buttonLink}
				info
			/>
			<a id='how_it_works' />
			<SectionHeader
				snippet={sectionHeaderTwo.snippet}
				title={sectionHeaderTwo.title}
				body={sectionHeaderTwo.body}
			/>
			<Steps />
			<a id='pricing' />
			<Pricing />
			<SectionHeader
				snippet={sectionHeaderThree.snippet}
				title={sectionHeaderThree.title}
				body={sectionHeaderThree.body}
			/>
			<div className='flex justify-center'>
				<div
					onClick={() => {
						ReactGA.event({
							category: 'Button',
							action: 'Click',
							label: 'I could use some help!',
						});
					}}>
					<PopupButton
						url='https://calendly.com/erin-janerothe/zoomcall'
						text='I could use some help!'
						className='inline-flex items-center px-4 iphone:px-16 py-4 mt-6 border shadow border-transparent iphone:text-lg font-medium rounded-md shadow-sm text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-prinmary'></PopupButton>
				</div>
			</div>

			<Footer />
		</>
	);
}
