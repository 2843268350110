"use strict";

/** Shared config for application; can be required many places. */

//get the base URL depending on which devolopment environment we are in
// const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : 'http://localhost:3001';

// get the base ur depending on deployment environment.
// deployment is a environment variable created and set in vercel.

function getBaseURL() {
	if (process.env.REACT_APP_DEPLOYMENT === "production") {
		return process.env.REACT_APP_BASE_URL;
	} else if (process.env.REACT_APP_DEPLOYMENT === "staging") {
		return process.env.REACT_APP_STAGING_BASE_URL;
	} else {
		return "http://localhost:3001";
	}
}

const BASE_URL = getBaseURL();

const getTypeformIds = () => {
	const productionIds = {
		longListing: "SD62lY8y",
		longClosing: "iMANRg25",
		clientGifts: "snaSBQiW",
		movingBoxes: "CC5xdhDE",
		shortListing: "jy2NE5uA",
		shortClosing: "KBGBSCcD",
	};
	const testIds = {
		longListing: "IUDXChoS",
		longClosing: "D1DZd9a9",
		clientGifts: "ub0fh7Be",
		movingBoxes: "xTB3O8nz",
		shortListing: "IUDXChoS",
		shortClosing: "D1DZd9a9",
	};

	if (process.env.REACT_APP_DEPLOYMENT === "production") {
		return productionIds;
	} else {
		return testIds;
	}
};

const typeformIds = getTypeformIds();

// Get Stripe publishable Key depending on deployment environment
// deployment is a environment variable created and set in vercel.
const STRIPE_PUBLISHABLE_KEY =
	process.env.REACT_APP_DEPLOYMENT === "production"
		? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
		: process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;

// const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export { BASE_URL, STRIPE_PUBLISHABLE_KEY, typeformIds };
