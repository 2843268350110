/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/outline';

import { Facebook, GTranslate, Instagram } from '@material-ui/icons';

import Offerings from '../helpers/offerings';
import React, { cloneElement, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import { PopupButton, openPopupWidget } from 'react-calendly';
import CalButton from './CalButton';
import ReactGA from '../helpers/analytics';

export default function Pricing() {
	return (
		<div className='relative bg-white py-16 sm:py-24 lg:py-32'>
			<div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
				<a id='pricing' />
				<h2 className='text-base font-semibold tracking-wider text-tertiary econdary uppercase'>
					Services & Pricing
				</h2>

				<p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>
					Only pay for what you need
				</p>
				<p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
					Once on your dashboard, you can mix and match services that fit your
					need. If you don't see what you are looking for, it's likely a subset
					of one of the categories below. NOTE: prices are for single agents
					only. Feel free to inquire about team accomodations!
				</p>
				<div className='mt-12'>
					<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
						{Offerings.services.map((service) => (
							<div key={service.name} className='pt-6  '>
								<div
									className={`bg-gray-100 rounded-lg h-full ${service.border} flex flex-col`}>
									<div className='-mt-6'>
										<div className=' '>
											<span className='inline-flex items-center justify-center p-3 bg-primary  rounded-md shadow-lg'>
												<img
													className='h-6 w-6 text-white'
													src={service.icon}
													alt='Menu'
												/>
												{/* <service.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
											</span>
										</div>
										<h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>
											{service.name}
										</h3>
										<div className='mt-4 border-b pb-4 '>
											<span className='text-xl font-large text-gray-900 mr-1  font-bold'>
												{service.starting}{' '}
											</span>
											<span className='text-4xl font-extrabold text-gray-900'>
												${service.price}
											</span>{' '}
											<span className='text-base font-medium text-gray-500'>
												/mo
											</span>{' '}
											<span className='text-base font-medium text-gray-500'>
												{service.extra}
											</span>
										</div>
									</div>
									<div className='flex-grow flex flex-col justify-between bg-white rounded-b-lg'>
										<ul className='mt-8 space-y-5 lg:space-y-0 lg:grid lg:gap-y-5 mx-5 lg:mx-5'>
											{service.features.map((feature) => (
												<li
													key={feature}
													className='flex items-start lg:col-span-1'>
													<div className='flex-shrink-0'>
														<CheckCircleIcon
															className='h-5 w-5 text-quinary'
															aria-hidden='true'
														/>
													</div>
													<div className='ml-3 text-sm text-gray-700 px-0 text-left'>
														{feature}
													</div>
												</li>
											))}
										</ul>

										<div
											onClick={() => {
												ReactGA.event({
													category: 'Button',
													action: 'Click',
													label: service.name + 'service',
												});
											}}
											className='my-10'>
											{service.chip && (
												<CalButton url='https://calendly.com/erin-janerothe/zoomcall' />
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

// service.chip &&
