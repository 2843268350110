import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';

const BrokerInfoCard = ({ setEdit }) => {
	const currentUser = useSelector((st) => st.user.currentUser);

	return (
		<div className="profile_form_wrapper edit_info_spacing pt-6">
			<Grid container spacing={3}>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Name</label>
						<div>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerName}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Email</label>
						<div>
							<p className="w-full text-lg break-all text-gray-600 font-medium justify-start">
								{currentUser.brokerEmail}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Broker Address One</label>
						<div>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerAddressOne}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Broker Address Two</label>
						<div>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerAddressTwo}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<div>
							<label className="leading-5 text-lg text-gray-900">Broker City</label>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerCity}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Broker State</label>
						<div>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerState}
							</p>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Broker Zip</label>
						<p className="w-full text-lg text-black font-medium justify-start">{currentUser.brokerZip}</p>
					</div>
				</Grid>
				<Grid item lg={4} md={4} sm={12} xs={12}>
					<div className="w-2/4 p-0 inline">
						<label className="leading-5 text-lg text-gray-900">Broker License</label>
						<div>
							<p className="w-full text-lg text-gray-600 font-medium justify-start">
								{currentUser.brokerLicense}
							</p>
						</div>
					</div>
				</Grid>
			</Grid>
			<div className="detail_btn_box lg:flex md:flex sm:block lg:justify-between md:justify-between sm:justify-between items-end">
				<Typography className="text-right flex justify-end" mt={6}>
					<Button
						onClick={() => setEdit(true)}
						type="submit"
						variant="contained"
						className="h-12 setting_btn"
					>
						Edit
					</Button>
				</Typography>
			</div>
		</div>
	);
};

export default BrokerInfoCard;
