import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';
import OtherInfoForm from './OtherInfoForm.js';
import OtherInfoCard from './OtherInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import { clearErr } from '../../actions/errors';

const OtherInfo = () => {
	const [edit, setEdit] = useState(false);
	const dispatch = useDispatch();

	// sets api errors to null when the component dismounts.
	// useEffect(() => {
	// 	return () => {
	// 		//dismount
	// 		dispatch(clearErr());
	// 	};
	// }, []);
	return (
		<div className="detail_sec2 detail_sec3 pt-4">
			{edit ? <OtherInfoForm setEdit={setEdit} /> : <OtherInfoCard setEdit={setEdit} />}
		</div>
	);
};

export default OtherInfo;
