import React, { useEffect, useState } from 'react';
import { Widget, PopupButton } from '@typeform/embed-react';
import { Stack, Container, Box, Divider, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Homemenu from '../../helpers/home.js';
import { getTypeformResponses } from '../../helpers/Api';
import Forms from '../../helpers/forms';
import { useSelector, useDispatch } from 'react-redux';
import LaunchIcon from '@mui/icons-material/Launch';
import { getTypeforms } from '../../store/user.js';
import { Link } from 'react-router-dom';
import TabPanel from './TabPanels.js';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import { useFormPackage } from '../../hooks/useFormPackage.js';
import { useTypeformResponses } from '../../hooks/useTypeformResponses.js';

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const MyForms = () => {
	/************ Redux definitions ******/

	const stripeSubs = useSelector((st) => st.user.stripeSubs);
	const currentUser = useSelector((st) => st.user.currentUser); // current user object
	const token = useSelector((st) => st.user.token);
	const typeforms = useSelector((st) => st.user.typeforms);
	const dispatch = useDispatch();

	/************ useState definitions ******/

	const [value, setValue] = useState(0);
	// const [formPackage, setFormPackage] = useState([]);
	const [tabNames, setTabNames] = useState([
		'listing',
		'closing',
		'client gifts',
		'moving boxes',
	]);
	// this state use for show the loader & close the loader
	const [tabChange, setTabChange] = useState();

	/************ Other Hooks definitions ******/

	// This constant holds the appropriate form package depending on the user's subsription.
	const formPackage = useFormPackage(stripeSubs, Forms);

	const handleChange = (event, newValue) => {
		setTabChange(true);
		setValue(newValue);
	};

	return (
		<React.Fragment>
			(
			<Container maxWidth='xl'>
				<Stack
					direction='row'
					justifyContent='center'
					spacing={2}
					className=' my-10'>
					<Box
						component='span'
						className=' font-sans font-extrabold text-zinc-600 text-3xl md:text-5xl p-4'>
						My Forms
					</Box>
				</Stack>
				<Stack spacing={2} className='my-6'>
					<Box component='span' className=' py-2 flex items-center'>
						<Link
							to='/home'
							className='flex items-center lg:justify-start md:justify-start sm:justify-start '>
							<Button
								variant='contained'
								size='small '
								sx={{
									bgcolor: 'septenary.main',
									borderRadius: '15%',
									'&:hover': {
										bgcolor: 'primary.main',
										cursor: 'pointer',
										'& .arrowIcon': {
											color: 'white',
										},
									},
								}}>
								<ArrowBackIosIcon
									className='arrowIcon'
									sx={{ color: 'octonary.main' }}
								/>
							</Button>
							<span className='ml-3  md:inline text-gray-500 font-semibold'>
								Home
							</span>
						</Link>
					</Box>
				</Stack>
				<Box className='mx-auto lg:w-3/4 w-full'>
					<Box
						sx={{ borderBottom: 1, borderColor: 'divider' }}
						className='my-8'>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label='tabs for various forms'
							centered
							variant='fullWidth'>
							{tabNames.map((n, indx) => (
								<Tab key={indx} label={n} {...a11yProps(indx)} />
							))}
						</Tabs>
					</Box>
				</Box>
				{tabNames.map((n, indx) => (
					<TabPanel
						key={indx}
						value={value}
						index={indx}
						tabName={n}
						formPackage={formPackage}
						typeforms={typeforms}
						setTabChange={setTabChange}
						tabChange={tabChange}
					/>
				))}
			</Container>
			)
		</React.Fragment>
	);
};

export default MyForms;
