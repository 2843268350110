import TotalClients from '../img/admin/total-client.png';
import TotalServices from '../img/admin/total-service.png';
import TotalSubscriptions from '../img/admin/total-subscription.png';

const handleClick = () => {
	console.info('You clicked the super Chip.');
};

class DashboardInfo {
	static summary = {
		clients       : {
			image : `${TotalClients}`,
			title : 'Client Count'
		},

		services      : {
			image : `${TotalServices}`,
			title : 'Services Count'
		},
		subscriptions : {
			image : `${TotalSubscriptions}`,
			title : 'Subscription Count'
		}
	};

	static serviceList = [
		{
			serviceName : `Hillside Hideaway`,
			serviceUser : `100`
		},
		{
			serviceName : `Bears Lair`,
			serviceUser : `100`
		},
		{
			serviceName : `Vally View  Villa`,
			serviceUser : `100`
		},
		{
			serviceName : `Acorn House`,
			serviceUser : `100`
		},
		{
			serviceName : `Lazy Moose Lodge`,
			serviceUser : `100`
		},
		{
			serviceName : `Lookout Lodge`,
			serviceUser : `100`
		},
		{
			serviceName : `Misty Mountain Hideaway`,
			serviceUser : `100`
		}
	];
}

export default DashboardInfo;
