import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import 'fontsource-roboto';
import './Client.css';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SublyApi from '../../helpers/Api.js';
import { updateUser, getAllUsers } from '../../store/user.js';
import { useDispatch, useSelector } from 'react-redux';

const ClientOtherInfoForm = ({ user, setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();

	const token = useSelector((st) => st.admin.token);

	/********* useParams hook definitions **********/
	const { username } = useParams(); //here i used useparams for passing the username through Routing

	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState    : { errors }
	} = useForm({
		defaultValues : {
			// here i set the default values in inpur field as a value
			serviceAreas       : user.serviceAreas || '',
			uniqueness         : user.uniqueness || '',
			favoritePlaces     : user.favoritePlaces || '',
			travelDestinations : user.travelDestinations || '',
			hobbies            : user.hobbies || '',
			otherDetails       : user.otherDetails || ''
		}
	});

	/******* Helper Functions ********/

	//fetching the form data and passing to the API
	const onSubmit = async (formData) => {
		SublyApi.updateUser(username, formData, token);
		dispatch(getAllUsers({ token }));

		setEdit(false); //state for after click on save redirect to previous page
	};

	return (
		<React.Fragment>
			<div>
				<Stack
					spacing={{ xs: 1, iphone: 2 }}
					component="form"
					className="px-3"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="profile_form_wrapper edit_info_spacing pt-6">
						<Grid container spacing={3}>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What are your main service area?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="serviceAreas"
										name="serviceAreas"
										minRows={1}
										type="text"
										{...register('serviceAreas')}
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What makes you unique in real estate?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="uniqueness"
										name="uniqueness"
										minRows={1}
										type="text"
										{...register('uniqueness')}
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										Where are some of your favorite places within your service area(S) i.e parks,
										Activities, Eateries, Bakeries, Live Music, Coffee, wineries, etc.
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="travelDestinations"
										name="travelDestinations"
										minRows={1}
										type="text"
										{...register('travelDestinations')}
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										Where is/are your favorite place (S) you have traveled to?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="favoritePlaces"
										name="favoritePlaces"
										minRows={1}
										type="text"
										{...register('favoritePlaces')}
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">
										What are some of your hobbies?
									</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="hobbies"
										name="hobbies"
										minRows={1}
										type="text"
										{...register('hobbies')}
									/>
								</Typography>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Other Details</label>
									<TextareaAutosize
										className="w-full border-1 border-blue-100 opacity-100 text-gray-900"
										id="otherDetails"
										name="otherDetails"
										minRows={1}
										type="text"
										{...register('otherDetails')}
									/>
								</Typography>
							</Grid>
						</Grid>

						<Box className="flex justify-end">
							<Button
								className="h-14 Client_btn client_cancel_btn text-white w-10 px-4"
								type="button"
								onClick={() => setEdit(false)}
								sx={{
									fontWeight : 600,
									fontSize   : { xs: '1rem', iphone: '1.125rem' },
									lineHeight : { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Cancel
							</Button>
							<Button
								className="h-14 Client_btn text-white w-10 px-3 bg-clientBlue ml-4"
								type="submit"
								sx={{
									fontWeight : 600,
									fontSize   : { xs: '1rem', iphone: '1.125rem' },
									lineHeight : { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Save
							</Button>
						</Box>
					</div>
				</Stack>
			</div>
		</React.Fragment>
	);
};

export default ClientOtherInfoForm;
