/** external icons (found by mindii)  */

import IconGTranslate from '../img/service-detail/service-detail1.png';
import IconFacebook from '../img/service-detail/service-detail9.png';
import IconAtSymbolIcon from '../img/service-detail/service-detail2.png';
import IconWebOutlined from '../img/service-detail/service-detail3.png';
import IconInstagram from '../img/service-detail/service-detail4.png';
import IconRefreshIcon from '../img/service-detail/service-detail5.png';
import IconMailIcon from '../img/service-detail/service-detail6.png';
import IconServerIcon from '../img/service-detail/service-detail7.png';
import IconFireIcon from '../img/service-detail/service-detail8.png';

/** standard MUI icons   */
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WebIcon from '@mui/icons-material/Web';
import InstagramIcon from '@mui/icons-material/Instagram';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

class serviceIcons {
	static icons = {
		mail    : (
			<MailIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		google  : (
			<GoogleIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		website : (
			<WebIcon
				fontSize="Large"
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		social  : (
			<InstagramIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		email   : (
			<AlternateEmailIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		listing : (
			<AutorenewIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		ads     : (
			<AdUnitsIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		),
		works   : (
			<LocalFireDepartmentIcon
				sx={{
					color      : 'white',
					fontSize   : '1.5rem',
					lineHeight : '2rem'
				}}
			/>
		)
	};
}

export default serviceIcons;
