import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { adminSignup, clearErrors } from '../../store/admin';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import JRLogo from '../../img/JR_Logo.svg';
// import './Login.css';

/** Form for signing up new admin. Adds admin to the db.
 *
 * Controlled form: admin's input is pulled in state and then displayed in form. Controlled by {@link handleChange } function
 *
 * Textfield Validation: Erros determined by attributes set in TextField's inputProps
 *
 * Error Visibility: triggered by errorTrigger state only when form is submitted via {@link handleSubmit}. State must be true for errors to show. Error shown via Texfield's helper text.
 *
 * Submission: checks if form is valid. If true, form data is dispatched. If one field is invalid, entire form is invalid--form won't submit, and errors become visible
 */
const AdminSignupForm = () => {
	const dispatch = useDispatch();
	const error = useSelector((st) => st.admin.errors);
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);
	const formRef = useRef();

	const INITIAL_STATE = {
		username: '',
		password: '',
		firstName: '',
		lastName: '',
		email: '',
	};

	// sets state for form data
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for object for error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers when admin submits. Errors will trigger if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	//sets toggle for showing and hiding password
	const [showPassword, setShowPassword] = useState(false);

	// sets api errors to null when the component first mounts and when it dismounts, and every time the admin types.
	// We do not expect api errors but in case we do, we want it to disappear when admin starts typing again.
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			//dismount
			dispatch(clearErrors());
		};
	}, []);

	/**
	 * This useEffect identifies the form input elements, extracts the initial validation messages and stores it in state.
	 * useRef hook used to identify the form element and named "form ref"
	 * formRef.current is the actual form object.
	 * The form object has child nodes identified in an array.  Some of those nodes are input elements.
	 */
	// useEffect(() => {
	// 	for (let node of formRef.current) {
	// 		const { name, validationMessage } = node;
	// 		if (name) {
	// 			setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: validationMessage }));
	// 		}
	// 	}
	// }, []);

	useEffect(() => {
		if (formRef.current && formRef.current.children) {
			for (let node of formRef.current.children) {
				const { name, validationMessage } = node;
				if (name) {
					setFormErrorMessage((formErrorMessage) => ({
						...formErrorMessage,
						[name]: validationMessage,
					}));
				}
			}
		}
	}, []);

	// handles the change in the form. We set form data and error message in state every time there is a change in a field
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));

		if (name === 'password') {
			e.target.validity.patternMismatch
				? e.target.setCustomValidity(
						'Password should have at least 8 characters, one number, one of these special characters: !@#$%^&*, one upper case letter, one lower case letter'
				  )
				: e.target.setCustomValidity('');
		}
		setFormErrorMessage((formErrorMessage) => ({
			...formErrorMessage,
			[name]: e.target.validationMessage,
		}));
	};

	/**Set the trigger to show errors & Checks that there are no error. If no errors, form will submit.
	 * upon submission, creates new admin object in redux store
	 * creates a stripe customer and stores customer Id on admin object
	 */
	const handleSubmit = (e) => {
		e.preventDefault();

		setErrorTrigger(true);

		if (formRef.current.checkValidity()) dispatch(adminSignup(formData));
	};

	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => {
			return !showPassword;
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	/**
	 * defines the validation pattern for admin password input.
	 * The password length must be greater than or equal to 8
	 * must contain one or more uppercase characters
	 * must contain one or more lowercase characters
	 * must contain one or more numeric values
	 * must contain one or more special characters
	 *
	 */
	const passwordPattern = `(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$`;

	return (
		<Box
			data-testid='admin-signup'
			className=' h-screen  bg-real-estate flex items-center justify-center relative z-0'>
			<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: 'center', display: 'flex', padding: '0.125rem' }}
				className=''
				maxWidth='md'>
				<Stack
					spacing={4}
					className='rounded-2xl pb-6 px-5 bg-white z-10 pt-0 w-full iphone:w-11/12 sm:w-2/3 md:w-7/12 '>
					<Stack
						spacing={1}
						className='py-8 rounded-2xl -mt-12 bg-tertiary shadow-2xl'>
						<img
							className='h-12 iphone:h-16 w-auto m-0'
							src={JRLogo}
							alt='Jane Logo'
						/>
						<div className='text-white text-center text-xl md:text-3xl font-bold'>
							Admin Sign Up
						</div>
					</Stack>
					{/* Form Begins here */}
					<Stack
						spacing={{ xs: 1, iphone: 2 }}
						component='form'
						onSubmit={handleSubmit}
						className='px-3 '
						noValidate
						ref={formRef}>
						<TextField
							required
							error={errorTrigger && formErrorMessage.username}
							helperText={errorTrigger && formErrorMessage.username}
							autoFocus={true}
							id='username'
							label='Username'
							variant='standard'
							type='text'
							sx={{
								backgroundColor: { required: 'red' },
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<AccountCircle fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 5,
								maxLength: 20,
							}}
							name='username'
							value={formData.username}
							onChange={handleChange}
							className='border-5'
						/>
						<TextField
							id='email'
							required
							error={errorTrigger && formErrorMessage.email}
							helperText={errorTrigger && formErrorMessage.email}
							label='Email'
							variant='standard'
							type='email'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmailIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 6,
								maxLength: 30,
							}}
							name='email'
							value={formData.email}
							onChange={handleChange}
						/>
						<TextField
							required
							error={errorTrigger && formErrorMessage.password}
							helperText={errorTrigger && formErrorMessage.password}
							id='password'
							label='Password'
							variant='standard'
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 8,
								maxLength: 30,
								pattern: passwordPattern,
							}}
							name='password'
							value={formData.password}
							onChange={handleChange}
						/>
						<TextField
							required
							error={errorTrigger && formErrorMessage.firstName}
							helperText={errorTrigger && formErrorMessage.firstName}
							id='firstName'
							label='First Name'
							variant='standard'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmojiEmotionsIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 2,
								maxLength: 20,
							}}
							name='firstName'
							value={formData.firstName}
							onChange={handleChange}
							type='text'
						/>
						<TextField
							required
							error={errorTrigger && formErrorMessage.lastName}
							helperText={errorTrigger && formErrorMessage.lastName}
							id='lastName'
							label='Last Name'
							variant='standard'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmojiEmotionsIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 2,
								maxLength: 20,
							}}
							name='lastName'
							value={formData.lastName}
							onChange={handleChange}
							type='text'
						/>
						<div className='text-red-600 mb-3 '>
							{Object.keys(error).length
								? error.map((e, indx) => (
										<div key={indx}>
											<small>ERROR: {e}</small>
										</div>
								  ))
								: null}
						</div>
						<Box className='flex justify-center'>
							<Button
								color='quarternary'
								type='submit'
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' },
								}}>
								Let's get to work
							</Button>
						</Box>
						<div className=' text-xs md:text-sm text-gray-600 py-2'>
							Already have an account?{' '}
							<NavLink
								exact
								to='/admin/login'
								className='text-senary hover:text-primary'>
								Log in here
							</NavLink>{' '}
						</div>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
};

export default AdminSignupForm;
