import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { logout, deleteToken } from '../../actions/currentUser';
import { clearErr } from '../../actions/errors';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import 'fontsource-roboto';
import './Settings.css';
import SublyApi from '../../helpers/Api';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Alert from '@mui/material/Alert';

/** Form for changing password */
const ChangePassword = () => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();
	const history = useHistory();
	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);

	/********* useState hook definitions **********/

	const INITIAL_STATE = {
		oldPassword: '',
		password: '',
		confirmPassword: '',
	};
	// sets state for form data
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for form validation  error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);
	const [passwordMatchError, setPasswordMatchError] = useState(); //true if new password and confirm password matches
	const [apiError, setApiError] = useState(); // error if incorrect current password entered.
	const [successMessage, setSuccessMessage] = useState(); // retrieved from api if change password works

	//These three hold the visibility state of the three password fields
	const [showPassword, setShowPassword] = useState(true);
	const [showNewPassword, setShowNewPassword] = useState(true);
	const [showConfPassword, setShowConfPassword] = useState(true);

	// sets state for error trigger. Triggers when user submits. Errors will trigger if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	/**************** useEffect hook definitions ************/

	/**
	 * This useEffect identifies the form input elements, extracts the initial validation messages and stores it in state.
	 * useRef hook used to identify the form element and named "form ref"
	 * formRef.current is the actual form object.
	 * The form object has child nodes identified in an array.  Some of those nodes are input elements.
	 */
	useEffect(() => {
		for (let node of formRef.current) {
			const { name, validationMessage } = node;
			if (name) {
				setFormErrorMessage((formErrorMessage) => ({
					...formErrorMessage,
					[name]: validationMessage,
				}));
			}
		}
	}, []);

	/**************** useRef hook definitions ************/

	const formRef = useRef();

	/******* Helper Functions ********/

	//The below six functions handle the visibility of the three password fields

	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => {
			return !showPassword;
		});
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleClickNewShowPassword = () => {
		setShowNewPassword((showNewPassword) => {
			return !showNewPassword;
		});
	};
	const handleMouseNewDownPassword = (event) => {
		event.preventDefault();
	};
	const handleClickConfShowPassword = () => {
		setShowConfPassword((showNewPassword) => {
			return !showConfPassword;
		});
	};
	const handleMouseConfDownPassword = (event) => {
		event.preventDefault();
	};

	// handles the change in the form. We set form data and error mesaage in state every time there is a change in a field
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));
		if (name === 'oldPassword') {
			e.target.validity.patternMismatch
				? e.target.setCustomValidity(
						'Password should have at least 8 characters, one number, one of these special characters: !@#$%^&*, one upper case letter, one lower case letter'
				  )
				: e.target.setCustomValidity('');
		}

		if (name === 'password') {
			e.target.validity.patternMismatch
				? e.target.setCustomValidity(
						'Password should have at least 8 characters, one number, one of these special characters: !@#$%^&*, one upper case letter, one lower case letter'
				  )
				: e.target.setCustomValidity('');
		}

		if (name === 'confirmPassword') {
			e.target.validity.patternMismatch
				? e.target.setCustomValidity(
						'Password should have at least 8 characters, one number, one of these special characters: !@#$%^&*, one upper case letter, one lower case letter'
				  )
				: e.target.setCustomValidity('');
		}

		setFormErrorMessage((formErrorMessage) => ({
			...formErrorMessage,
			[name]: e.target.validationMessage,
		}));
	};

	/**Set the trigger to show errors & Checks that there are no error. If no errors, form will submit.
	 *
	 */
	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorTrigger(true);
		if (formRef.current.checkValidity()) {
			if (formData.password !== formData.confirmPassword) {
				setPasswordMatchError('Confirm password did not match new password');
				setApiError('');
			} else {
				setPasswordMatchError('');
				const userPassword = {
					password: formData.password,
					oldPassword: formData.oldPassword,
				};
				SublyApi.changePassword(currentUser.username, userPassword, token)
					.then(function (response) {
						setSuccessMessage(response.message);
						setTimeout(() => {
							setFormData(INITIAL_STATE);
							setSuccessMessage('');
						}, 3000);
						setApiError('');
						setErrorTrigger(false);
					})
					.catch(function (error) {
						setApiError(error);
					});
			}
		}
	};

	/**
	 * defines the validation pattern for user password input.
	 * The password length must be greater than or equal to 8
	 * must contain one or more uppercase characters
	 * must contain one or more lowercase characters
	 * must contain one or more numeric values
	 * must contain one or more special characters
	 *
	 */
	const passwordPattern = `(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$`;
	return (
		<div className=' px-8 pt-3 pb-12 bg-white border shadow'>
			<div className='profile_form_wrapper edit_info_spacing pt-6'>
				{/* <div className="setting_heading">
					<h3>Change Password</h3>
				</div> */}
				{successMessage ? (
					<div className='generic_error mt-6'>
						<Alert
							className='mt-0 p-0 w-full bottom-0 left-0 z-10 '
							severity='success'
							onClose={() => setSuccessMessage('')}>
							{successMessage}
						</Alert>
					</div>
				) : null}
				{passwordMatchError ? (
					<div className='generic_error mt-6'>
						<Alert
							className='mt-0 p-0 w-full bottom-0 left-0 z-10 '
							severity='error'
							onClose={() => setPasswordMatchError('')}>
							{passwordMatchError}
						</Alert>
					</div>
				) : null}
				<Stack
					spacing={{ xs: 1, iphone: 2 }}
					component='form'
					onSubmit={handleSubmit}
					noValidate
					ref={formRef}>
					<Grid container spacing={2}>
						<Grid item lg={9} md={10} sm={12} xs={12}>
							<Typography className='p-0 inline'>
								<label className='leading-9 text-gray-900'>
									Current Password
								</label>
								<TextField
									className='w-full pawicon'
									required
									error={formErrorMessage.oldPassword ? errorTrigger : false}
									helperText={errorTrigger && formErrorMessage.oldPassword}
									id='oldPassword'
									inputProps={{
										minLength: 8,
										maxLength: 30,
										pattern: passwordPattern,
									}}
									name='oldPassword'
									value={formData.oldPassword}
									onChange={handleChange}
									type={showPassword ? 'password' : 'text'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Typography>
							<div className='passwordError'>{apiError}</div>
						</Grid>
						<Grid item lg={9} md={10} sm={12} xs={12}>
							<Typography className='p-0 inline'>
								<label className='leading-9 text-gray-900'>New Password</label>
								<TextField
									className='w-full pawicon'
									required
									error={formErrorMessage.password ? errorTrigger : false}
									helperText={errorTrigger && formErrorMessage.password}
									id='password'
									inputProps={{
										minLength: 8,
										maxLength: 30,
										pattern: passwordPattern,
									}}
									name='password'
									value={formData.password}
									onChange={handleChange}
									type={showNewPassword ? 'password' : 'text'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickNewShowPassword}
													onMouseDown={handleMouseNewDownPassword}
													edge='end'>
													{showNewPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Typography>
						</Grid>
						<Grid item lg={9} md={10} sm={12} xs={12}>
							<Typography className='p-0 inline'>
								<label className='leading-9 text-gray-900'>
									Confirm Password
								</label>
								<TextField
									className='w-full pawicon'
									required
									error={
										formErrorMessage.confirmPassword ? errorTrigger : false
									}
									helperText={errorTrigger && formErrorMessage.confirmPassword}
									id='confirmPassword'
									inputProps={{
										minLength: 8,
										maxLength: 30,
										pattern: passwordPattern,
									}}
									name='confirmPassword'
									value={formData.confirmPassword}
									onChange={handleChange}
									type={showConfPassword ? 'password' : 'text'}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickConfShowPassword}
													onMouseDown={handleMouseConfDownPassword}
													edge='end'>
													{showConfPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Typography>
						</Grid>
					</Grid>

					<Box className='flex justify-end'>
						<Button
							className='h-12 setting_btn text-white'
							type='submit'
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' },
							}}>
							Submit
						</Button>
					</Box>
				</Stack>
			</div>
		</div>
	);
};

export default ChangePassword;
