import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, TextField, Grid } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import 'fontsource-roboto';
import './Settings.css';
// import { updateUser } from '../../actions/currentUser';
import { updateUser, clearSuccess, clearErrors } from '../../store/user.js';

import Alert from '@mui/material/Alert';

const BrokerInfoForm = ({ setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();
	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);

	const errors = useSelector((st) => st.user.errors); // holds server side errors

	//holds success state of call to edit-user api
	const success = useSelector((st) => st.user.success);

	/********* useState hook definitions **********/

	const INITIAL_STATE = {
		brokerName: currentUser.brokerName || '',
		brokerEmail: currentUser.brokerEmail || '',
		brokerAddressOne: currentUser.brokerAddressOne || '',
		brokerAddressTwo: currentUser.brokerAddressTwo || '',
		brokerCity: currentUser.brokerCity || '',
		brokerState: currentUser.brokerState || '',
		brokerZip: currentUser.brokerZip || '',
		brokerLicense: currentUser.brokerLicense || ''
	};

	// sets state for form data
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for form error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers to true when user submits form. Errors will display if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	/********* useRef hook definitions *************/

	//sets ref for form. used on form submit to check for errors
	const formRef = useRef();

	/**************** useEffect hook definitions ************/

	/** changes the edit state depending on the success of call to api.
	 * if success is false, then there has been no call to api or there is an error. user stays on form
	 * if success is true, user has successfully submitted edit to api. From changes to card.
	 **/

	useEffect(
		() => {
			setEdit(!success);
		},
		[success]
	);

	// clears error and success messages from store when component dismounts
	useEffect(() => {
		return () => {
			dispatch(clearSuccess());
			dispatch(clearErrors());
		};
	}, []);

	/******* Helper Functions ********/
	/**
	 * Handles the change in the form. 
	 * Sets form data and error message in state every time there is a change in a field.
	 */

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((formData) => ({ ...formData, [name]: value }));

		setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: e.target.validationMessage }));
	};

	/**
	 * handles submission of the form. Sets error trigger on to show potential errors.
	 * Updates user only if the form has no errors. 
	 */

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorTrigger(true);

		console.log(formRef.current.checkValidity());

		if (formRef.current.checkValidity()) {
			dispatch(updateUser({ username: currentUser.username, data: formData, token: token }));
			// setEdit(false);
		}
	};

	return (
		<div>
			{/* Add Detail Broker Information section start */}
			{Object.keys(errors).length ? (
				<div className="generic_error mt-6">
					<Alert
						className="mt-0 p-0 w-full bottom-0 left-0 z-10 "
						severity="error"
						onClose={() => dispatch(clearErrors())}
					>
						{'ERROR: Something went wrong with submission. Please report to admin.'}
					</Alert>
				</div>
			) : null}
			<div className="detail_sec2">
				<Stack spacing={{ xs: 1, iphone: 2 }} component="form" onSubmit={handleSubmit} noValidate ref={formRef}>
					<div className="profile_form_wrapper edit_info_spacing pt-6">
						<Grid container spacing={3}>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Name</label>
									<TextField
										className="w-full"
										id="brokerName"
										inputProps={{
											minLength: 2,
											maxLength: 20
										}}
										name="brokerName"
										value={formData.brokerName}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Email</label>
									<TextField
										className="w-full"
										id="brokerEmail"
										error={formErrorMessage.brokerEmail && formData.brokerEmail == '' ? errorTrigger : false}
										helperText={errorTrigger && formData.brokerEmail == '' && formErrorMessage.brokerEmail}
										type="email"
										inputProps={{
											minLength: 6,
											maxLength: 30
										}}
										name="brokerEmail"
										value={formData.brokerEmail}
										onChange={handleChange}
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Broker Address One</label>
									<TextField
										id="brokerAddressOne"
										name="brokerAddressOne"
										value={formData.brokerAddressOne}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Broker Address Two</label>
									<TextField
										id="brokerAddressTwo"
										name="brokerAddressTwo"
										value={formData.brokerAddressTwo}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Broker City</label>
									<TextField
										id="brokerCity"
										name="brokerCity"
										value={formData.brokerCity}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Broker State</label>
									<TextField
										id="brokerState"
										name="brokerState"
										value={formData.brokerState}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 p-0 inline">
									<label className="leading-5 text-lg text-gray-500">Broker Zip</label>
									<TextField
										id="brokerZip"
										name="brokerZip"
										value={formData.brokerZip}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Typography className="w-2/4 md:p-0 inline">
									<label className="leading-5 text-gray-800 block">License #</label>
									<TextField
										id="brokerLicense"
										name="brokerLicense"
										value={formData.brokerLicense}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
						</Grid>

						<Box className="flex justify-end" mt={2}>
							<Button
								className="h-12 setting_cancel_btn setting_btn text-white bg-clientBlue"
								type="button"
								onClick={() => setEdit(false)}
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Cancel
							</Button>
							<Button
								className="h-12 setting_btn text-white"
								type="submit"
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Save
							</Button>
						</Box>
					</div>
				</Stack>
			</div>
		</div>
	);
};

export default BrokerInfoForm;
