import { Stack, Box, Button } from '@mui/material';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Arrow from '../../img/arrow.png';
import { useEffect, useState, useRef } from 'react';
import ConfirmationDialog from '../myServices/ConfirmationDialog';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import Notrecord from '../../img/not_record_found.png';

const SpendingTracker = () => {
	const iFrameRef = useRef(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true); // state for managing loader show/hide
	const currentUser = useSelector((st) => st.user.currentUser); // current user object
	const [trackerData, setTrackerData] = useState(
		currentUser && currentUser.spendingTracker
	);
	const [iFrameHeight, setIFrameHeight] = useState(); //state to store the height of iframe

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	useEffect(() => {
		function getWindowHeight() {
			// we have set a dynamic height to iframe as per the screen resolution
			setIFrameHeight((window.screen.availHeight * 65) / 100);
		}
		getWindowHeight();
		window.addEventListener('resize', getWindowHeight); // the event trigered on the resolution change
		return () => window.removeEventListener('resize', getWindowHeight);
	}, []);

	// function for handling loading of iframe url

	const handleIframeLoad = () => {
		setLoading(false);
	};

	return (
		<>
			{trackerData && loading ? (
				<CircularProgress
					sx={{
						position: 'absolute',
						left: '0px',
						right: 0,
						margin: 'auto',
						top: 0,
						bottom: 0,
						zIndex: 10,
					}}
					color='tertiary'
				/>
			) : null}
			<div className='tab_wrapper pb-10' data-testid='spending-tracker'>
				{/* Main header start */}
				<div className='main_heading max-w-7xl mx-auto py-3 px-2 sm:px-6 lg:px-4 pt-4 pb-4 mt-8'>
					<Stack
						direction='row'
						justifyContent='center'
						spacing={2}
						className=' my-6'>
						<Box
							component='span'
							className=' font-sans font-extrabold text-zinc-600 text-3xl md:text-5xl p-4'>
							Spending Tracker
						</Box>
					</Stack>
					<Stack spacing={2} className='my-6'>
						<Link
							to='/home'
							className='flex items-center  lg:justify-start md:justify-start sm:justify-start xs:justify-start'>
							<Button
								sx={{
									bgcolor: 'septenary.main',
									borderRadius: '15%',
									'&:hover': {
										bgcolor: 'tertiary.main',
										cursor: 'pointer',
										'& .arrowIcon': {
											color: 'white',
										},
									},
								}}
								variant='contained'
								size='small '>
								<ArrowBackIosIcon
									className='arrowIcon'
									sx={{ color: 'octonary.main' }}
								/>
							</Button>
							<span className='ml-3  md:inline text-gray-500 font-semibold'>
								Home
							</span>
						</Link>
					</Stack>

					{trackerData ? (
						<Grid container>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Stack
									direction='row'
									justifyContent='center'
									spacing={2}
									className=' mb-0 mt-0'>
									<Box
										component='span'
										position='relative'
										className=' font-sans w-full flex justify-center font-extrabold text-zinc-600 text-3xl md:text-5xl '>
										<iframe
											id='test'
											ref={iFrameRef}
											height={iFrameHeight}
											width='100%'
											className={`${
												loading
													? 'invisible'
													: 'border border-slate-300 rounded-lg '
											}`}
											onLoad={handleIframeLoad}
											src={trackerData}
											// src={
											// 	'https://docs.google.com/spreadsheets/d/1cfa4sT3HHhsPgWcCUgOfZBA6KxO-nJY2l-3DZC6Gcqs/edit#gid=0'
											// }
										></iframe>
									</Box>
								</Stack>
							</Grid>
						</Grid>
					) : (
						<Grid container className='pt-8'>
							<img
								src={Notrecord}
								alt={Notrecord}
								className='m-auto h-36 w-36'
							/>
							<p className='text-2xl text-stone-400 text-center'>
								Sorry! You don't have a spending tracker yet.
							</p>
						</Grid>
					)}
				</div>
			</div>
		</>
	);
};

export default SpendingTracker;
