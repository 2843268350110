/** @format */
import React, { useEffect, useState } from 'react';
import 'fontsource-roboto';
import { Typography, Grid, Button } from '@material-ui/core';
import ClientBrokerForm from './ClientBrokerForm.js';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import './Client.css';

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const ClientBrokerInfo = (props) => {
	const [edit, setEdit] = useState(false);
	return (
		<>
			{edit ? (
				<ClientBrokerForm
					user={props.user}
					setUser={props.setUser}
					setEdit={setEdit}
				/>
			) : (
				<div className=''>
					<Grid
						className='edit_info_spacing client_font_stypeP'
						container
						spacing={4}>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>Name</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerName}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>Email</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerEmail}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									Broker Address One
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerAddressOne}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									Broker Address Two
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerAddressTwo}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									Broker City
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerCity}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									Broker State
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerState}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									Broker zip
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerZip}
								</p>
							</Typography>
						</Grid>
						<Grid className='form_box' item lg={6} md={6} sm={12} xs={12}>
							<Typography className='w-2/4 p-0 inline'>
								<label className='leading-5 text-gray-900 text-lg'>
									License #
								</label>
								<p className='w-full text-lg text-gray-600 font-medium justify-start pt-1'>
									{props.user.brokerLicense}
								</p>
							</Typography>
						</Grid>
						<Typography className='text-right flex justify-end' mt={6}>
							<button
								type='submit'
								onClick={() => setEdit(true)}
								variant='contained'
								className='h-14 client_btn'>
								<ModeEditOutlineIcon />
							</button>
						</Typography>
					</Grid>
				</div>
			)}
		</>
	);
};
export default ClientBrokerInfo;
