import react, { Fragment } from 'react';

import rocketBlue from '../img/rocketTeal.svg';
import { NavLink } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
// import ReactGA from "react-ga4";
import ReactGA from '../helpers/analytics';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function HeroSection() {
	return (
		<div className='relative bg-white'>
			<main className='lg:relative  '>
				<div className='mx-auto max-w-7xl w-full pt-4 pb-5 text-center lg:py-16 lg:text-left grid grid-cols-1 lg:grid-cols-2 gap-px  '>
					{/* Hero Word Copy Section */}
					<div className='px-4  sm:px-8 xl:pr-16  '>
						<h1 className='text-4xl tracking-tight font-extrabold md:font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
							<span className='block xl:inline '>
								Give Your Real Estate Business a
							</span>{' '}
							<span className='block text-primary xl:inline '>Boost!</span>
						</h1>
						<h2 className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl '>
							Accelerate the growth of your business with your dedicated
							marketing partner. Together, we will elevate your brand.
						</h2>
						<div className='mt-10 iphone:flex iphone:justify-center lg:justify-start mb-6 '>
							<div className='rounded-md shadow border'>
								{/* <NavLink
									exact
									to='/signup'
									className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-test md:py-4 md:text-lg md:px-10 '>
									Try for free!
								</NavLink> */}
								<PopupButton
									url='https://calendly.com/erin-janerothe/zoomcall'
									text='Schedule a call!'
									className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary md:py-4 md:text-lg md:px-10 '
								/>
								{/* <div
									onClick={() => {
										console.log('we are analyzing');
										ReactGA.event({
											category: 'Button',
											action: 'Click',
											label: 'Get Started',
										});
										// Your click event logic
									}}>
									<PopupButton
										url='https://calendly.com/amy-janerothe/one-one'
										text="Let's get Started!"
										className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-test md:py-4 md:text-lg md:px-10 '
									/>
								</div> */}
							</div>
							<div className='mt-3 rounded-md shadow iphone:mt-0 iphone:ml-3 border '>
								<a
									href='#pricing'
									className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 '>
									More Info
								</a>
								{/* <PopupButton
									url='https://calendly.com/erin-janerothe/zoomcall'
									text='More Info'
									className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 '
								/> */}
							</div>
						</div>
					</div>
					{/* Hero Illustration Section */}
					<div className=' lg:pr-4'>
						<div className='w-3/4 lg:w-full mx-auto lg:h-full flex items-center '>
							<img
								className=' h-auto w-full sm:w-3/4 lg:w-full  block mx-auto my-0 '
								src={rocketBlue}
								alt='Rocket Illustration'
							/>
						</div>
					</div>
				</div>

				{/* <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={rocket}
            alt="Rocket Illustration"
          />
        </div> */}
			</main>
		</div>
	);
}
