import React, { useState, useEffect } from 'react';
import ServiceDetailOfferings from '../../helpers/ServiceDetailOfferings.js';
import { Typography, Button, Grid, Box } from '@material-ui/core';
import 'fontsource-roboto';
import ServiceDetailPopup from './ServiceDetailPopup.js';
import './ServiceDetail.css';

/** Service Detail componernt start **/
const ServiceDetail = ({ offeringservices }) => {
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ serviceDetail, setServiceDetail ] = useState('');

	const addClass = (service) => {
		setModalOpen(true);
		setServiceDetail(service);
	};

	/** Adding class on body tag for adding some css **/
	useEffect(
		() => {
			if (modalOpen) {
				document.body.classList.add('add_overflow');
			} else {
				document.body.classList.remove('add_overflow');
			}
		},
		[ modalOpen ]
	);

	return (
		<div>
			<div className="main_heading sd_wrapper max-w-7xl mx-auto py-3 px-2 sm:px-6 lg:px-4 pt-4 pb-14">
				<Typography
					variant="h5"
					className="uppercase text-center text-lg text-black font-extrabold opacity-90 sd_main_heading"
				>
					service detail
				</Typography>
				<div className="service_detail_box mt-12">
					<div className="Container">
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={4}>
								{/*Service Detail start*/}
								{ServiceDetailOfferings.offeringservices.map((service, index) => (
									<Grid item lg={4} md={6} sm={12} xs={12}>
										<div className="text-center">
											<div
												key={index}
												className={`rounded-3xl shadow border text-center py-5 px-5 servicedetail `}
											>
												<div className="sd_icon_sec bg-slate-300 p-4">
													<Typography variant="span" className="sd_icon bg-sky-800 ">
														<img
															className="box_img_clr p-3"
															src={service.icon}
															alt="Menu"
														/>
													</Typography>
													<p className="pt-4 text-base text-center">{service.name}</p>
												</div>
												<div className="sd_price_sec pt-10">
													<h5 className="text-2xl font-bold">{service.price}</h5>
													<h6 className="text-sm text-slate-400 font-bold opacity-60 pt-1.5">
														{service.extra}
													</h6>
													<p className="text-sm text-slate-400">
														{service.Description}{' '}
														<Button
															className="openModalBtn"
															onClick={() => {
																addClass(service);
															}}
														>
															View more
														</Button>
													</p>
												</div>
											</div>
										</div>
									</Grid>
								))}
								{modalOpen && (
									<ServiceDetailPopup serviceDetail={serviceDetail} setOpenModal={setModalOpen} />
								)}
							</Grid>
						</Box>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceDetail;
