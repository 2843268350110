import { composeWithDevTools } from "redux-devtools-extension";
import { createMigrate, persistStore, persistReducer } from "redux-persist"; // allows your app to persist by saving to local storage
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { configureStore } from "@reduxjs/toolkit";
import { getPersistConfig } from "redux-deep-persist";

import thunk from "redux-thunk";
// import root from "./reducers/root";
import newRoot from "./store/root";
// import { createStore, applyMiddleware } from 'redux';
// import hardSet from 'redux-persist/es/stateReconciler/hardSet';

//example migration. Only use when making big redux changes
const migrations = {
	1: (state) => {
		return {
			...state,
			users: [],
		};
	},
};

const persistConfig = getPersistConfig({
	key: "root",
	storage,
	stateReconciler: autoMergeLevel2,
	blacklist: ["user.errors", "user.success", "user.pending", "user.newRes"],
	rootReducer: newRoot,
	version: 0,
	migrate: createMigrate(migrations, { debug: true }),
});

const persistedReducer = persistReducer(persistConfig, newRoot);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: [thunk],
});

// export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);
