/** @format */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'fontsource-roboto';
import { Typography, Tab, Tabs, Grid, Box } from '@material-ui/core';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Sidebar from '../sidebar/Sidebar';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Button from '@mui/material/Button';
import ClientBusinessInfo from './ClientBusinessInfo.js';
import ClientBrokerInfo from './ClientBrokerInfo.js';
import ClientOtherInfo from './ClientOtherInfo.js';
import AdminInfo from './AdminInfo.js';
import './Client.css';
import SublyApi from '../../helpers/Api.js';
import { saveAs } from 'file-saver';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import JRLogo from '../../img/JR_Logo.svg';
import Moment from 'react-moment';
import { success, clearSuccess } from '../../actions/success';
import CircularProgress from '@mui/material/CircularProgress';
import loaderimage from '../../img/gif/loading-arrow.gif';
// import { updateImages, updateUser } from '../../actions/currentUser';
import {
	updateImages,
	updateUser,
	getAllUsers,
	getUser,
} from '../../store/user.js';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import axios from 'axios';

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const ClientDetails = () => {
	/************ Redux definitions ******/

	//generic state for success of call to api
	const pending = useSelector((st) => st.user.pending);
	const users = useSelector((st) => st.user.allUsers);
	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);
	const currentUser = useSelector((st) => st.user.currentUser);

	/********* useState hook definitions **********/

	const INITIAL_STATE = {
		logo: '',
		headshot: '',
	};
	const [user, setUser] = useState({});

	const [value, setValue] = React.useState(0);
	const [shareBox, setShareBox] = useState(false);

	//set state for showing subscription details of subscriber from the API
	const [subscriptions, setSubscriptions] = useState([]);

	//sets state for image loader. Toggles to true when user uploads file. Back to false when image url is retrieved.
	const [imageLoader, setImageLoader] = useState({
		logo: false,
		headshot: false,
	});

	// sets state for image form data. i.e. name of the file
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for upload error. Holds client side error message.
	const [imageErr, setImageErr] = useState(INITIAL_STATE);

	/********* useParams hook definitions **********/

	// const token = SublyApi.adminToken;
	const { username } = useParams(); //useparams for passing the username and customer id through Routing

	/********* useEffect hook definitions **********/

	useEffect(() => {
		dispatch(getAllUsers({ token }));
	}, [currentUser]);

	useEffect(() => {
		//filter all users to find user whose username is passed through useparams
		setUser(users.find((u) => u.username === username));
	}, [users]);

	useEffect(() => {
		const getSubs = async (customerId, token) => {
			const response = await SublyApi.getStripeSubscriptions(customerId, token);

			setSubscriptions(response.stripeSubs);
		};
		if (user.customerId) {
			getSubs(user.customerId, token);
		}
		console.log(subscriptions);
	}, [user]);

	//clear image loader and success state in redux whenever we get a successful api call.
	useEffect(() => {
		if (!pending) {
			setImageLoader({ logo: false, headshot: false });
		}
	}, [pending]);

	/******* Helper Functions ********/

	//--- function for downloading logo and headshot ---

	const downloadImage = async (imageUrl) => {
		try {
			console.log(imageUrl);
			const response = await axios.get(imageUrl, { responseType: 'blob' });
			const filename = imageUrl.split('/').pop() || 'downloaded_logo';
			saveAs(response.data, filename);
		} catch (error) {
			console.error('There was a problem with the download:', error);
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const Sharehandle = () => {
		shareBox == false ? setShareBox(true) : setShareBox(false);
	};

	/**
	 * handles change in file form
	 *
	 * Validates that the file is not too large
	 *
	 * Takes BLOB data and sends it to API. Data is sent to AWS S3 and returned a URL.
	 *
	 * URL is stored in db and retrieved in redux.
	 */

	const imageChange = async (e) => {
		if (validateSize(e)) {
			const { name, value, files } = e.target;

			//resets image error if there were any previously.
			setImageErr((imageErr) => ({ ...imageErr, [name]: '' }));

			//sets image loader while URL is retrieved.
			setImageLoader((imageLoader) => ({ ...imageLoader, [name]: pending }));

			//control form data by accessing file name
			setFormData((formData) => ({ ...formData, [name]: value }));

			// dispatch binary file data to API
			const form = new FormData();
			form.append(name, files[0]);
			dispatch(updateImages({ username, data: form, token }));
		}
	};

	// function for removing logo and headshot image from form
	const removeImage = (image) => {
		setFormData((formData) => ({ ...formData, [image]: '' }));

		dispatch(
			updateUser({
				username,
				data: {
					[image]: '',
				},
				token,
			})
		);
	};

	/**
	 * Validates the size of an image to not be any larger that 1MG
	 *
	 * Sets error message & returns false if file is too large.
	 */

	const validateSize = (e) => {
		console.log('im in validate size');
		const { name, files } = e.target;
		const file = files[0];
		const size = 1024 * 1024;

		if (file.size > size) {
			const err =
				file.type + 'is too large, please pick a file smaller than 1GB\n';
			setImageErr((imageErr) => ({ ...imageErr, [name]: err }));
			return false;
		}
		return true;
	};

	return (
		<React.Fragment>
			<div className='container w-full max-w-full relative'>
				<div className='dashboard_row' spacing={4}>
					<div className='dashboard_col dashboard_col1'>
						<Sidebar />
					</div>
					<div className='dashboard_col2'>
						<div className='dashboard_wrapper mt-0 mb-5'>
							<div className='client_info_grid'>
								<div className='client_detail_row'>
									<div
										className='client_detail_col1'
										item
										lg={3}
										md={3}
										sm={3}
										xs={12}>
										<div className='client_info_left mt-4'>
											<h3 className='text-clientHeading font-semibold text-lg mb-4'>
												Clients Details
											</h3>
											<div className='client_info_box text-center'>
												<div className='logo_headshot'>
													<div className='logo_Box'>
														<p>Logo</p>
														<div
															className='admin_logo'
															data-toggle='tooltip'
															data-placement='bottom'
															title='Logo'>
															<div>
																{imageLoader.logo && (
																	<CircularProgress
																		sx={{
																			position: 'absolute',
																			left: '0px',
																			right: 0,
																			margin: 'auto',
																			top: 0,
																			bottom: 0,
																			zIndex: 10,
																		}}
																		color='primary'
																	/>
																)}
															</div>
															<img
																className='w-full h-full '
																src={!user.logo ? JRLogo : user.logo}
															/>
															{user.logo && (
																<span
																	className='download_logo'
																	onClick={() => downloadImage(user.logo)}>
																	<DownloadForOfflineIcon />
																</span>
															)}
														</div>
														<span className='relative'>
															<label htmlFor='logo'>Upload New Logo </label>
															{user.logo && (
																<button
																	type='button'
																	className='remove_button font-normal text-gray-500 text-base opacity-100'
																	onClick={() => removeImage('logo')}>
																	Remove
																</button>
															)}
														</span>
														<input
															value={formData.logo}
															type='file'
															name='logo'
															id='logo'
															accept='image/png, image/jpeg'
															onChange={(e) => {
																imageChange(e);
															}}
														/>

														{imageErr.logo && (
															<Alert
																className='mt-3'
																severity='error'
																onClose={() => {
																	setImageErr((imageErr) => ({
																		...imageErr,
																		logo: '',
																	}));
																	setFormData((formData) => ({
																		...formData,
																		logo: '',
																	}));
																}}>
																{imageErr.logo}
															</Alert>
														)}
													</div>
													<div className='headshot_Box'>
														<p className='headshot_heading'>Headshot</p>
														<div
															className='admin_logo'
															data-toggle='tooltip'
															data-placement='bottom'
															title='Headshot'>
															<div>
																{imageLoader.headshot && (
																	<CircularProgress
																		sx={{
																			position: 'absolute',
																			left: '0px',
																			right: 0,
																			margin: 'auto',
																			top: 0,
																			bottom: 0,
																			zIndex: 10,
																		}}
																		color='primary'
																	/>
																)}
															</div>
															<img
																className='w-full h-full'
																src={!user.headshot ? JRLogo : user.headshot}
															/>
															{user.headshot && (
																<span
																	className='download_headshot'
																	onClick={() => downloadImage(user.headshot)}>
																	<DownloadForOfflineIcon />
																</span>
															)}
														</div>
														<span className='relative'>
															<label htmlFor='headshot'>
																Upload New headshot
															</label>
															{user.headshot && (
																<button
																	type='button'
																	className='remove_button2 font-normal text-gray-500 text-base opacity-100'
																	onClick={() => removeImage('headshot')}>
																	Remove
																</button>
															)}
														</span>
														<input
															value={formData.headshot}
															type='file'
															name='headshot'
															id='headshot'
															accept='image/png, image/jpeg'
															onChange={(e) => {
																imageChange(e);
															}}
														/>
														{imageErr.headshot && (
															<Alert
																className='mt-3'
																severity='error'
																onClose={() => {
																	setImageErr((imageErr) => ({
																		...imageErr,
																		headshot: '',
																	}));
																	setFormData((formData) => ({
																		...formData,
																		headshot: '',
																	}));
																}}>
																{imageErr.headshot}
															</Alert>
														)}
													</div>
												</div>
												<h5 className='mt-6 mb-2 text-black text-lg opacity-100'>
													<b>Name: </b>
													<span className='text-gray-800'>
														{user.firstName} {user.lastName}
													</span>
												</h5>
												<h5 className='mt-0 mb-2 font-normal text-black text-lg opacity-100'>
													<b>Email: </b> {user.email}
												</h5>
												<h5 className='mt-0 mb-5 font-normal text-black text-lg opacity-100'>
													<b>Username: </b> {user.username}
												</h5>
												<div className='client_info_left_subs'>
													<h5 className='mt-0 mb-5 font-medium text-gray-800 text-lg opacity-100'>
														Subscriptions
													</h5>
													{subscriptions
														? subscriptions.map((value, index) => (
																<ul key={index}>
																	{value.productName && (
																		<li className=''>{value.productName}</li>
																	)}
																	<p className='mt-0 mb-5 font-normal text-gray-500 text-lg opacity-100'>
																		<Moment format='LL'>{value.created}</Moment>
																	</p>
																</ul>
														  ))
														: ''}
												</div>
											</div>
										</div>
									</div>
									<div
										className='client_detail_col2'
										item
										lg={9}
										md={9}
										sm={12}
										xs={12}>
										<Box className='flex justify-center pb-3'>
											<Tabs
												className='client_dtl_tabs'
												value={value}
												onChange={handleChange}>
												<Tab
													className='text-gray-500 text-base'
													label='Business Info'
												/>
												<Tab
													className='text-gray-500 text-base'
													label='Broker Information'
												/>
												<Tab
													className='text-gray-500 text-base'
													label='Other Information'
												/>
												<Tab
													className='text-gray-500 text-base'
													label='Admin Info'
												/>
											</Tabs>
										</Box>
										{/* Add Profile Form section start */}
										<TabPanel
											className='client_tablepane_wrapper max-w-5xl mx-auto py-3 px-2 lg:px-4 pt-4 pb-5 bg-gray-50 rounded-lg shadow'
											value={value}
											index={0}>
											<ClientBusinessInfo user={user} />
											{/* <ClientBusinessInfo user={user} setUser={setUser} /> */}
										</TabPanel>
										{/* Change Password Form section start */}
										<TabPanel
											className='client_tablepane_wrapper max-w-5xl mx-auto py-3 px-2 lg:px-4 pt-4 pb-5 bg-gray-50 rounded-lg shadow'
											value={value}
											index={1}>
											{/* <ClientBrokerInfo user={user} setUser={setUser} /> */}
											<ClientBrokerInfo user={user} />
										</TabPanel>
										{/* Add Detail Form section start */}
										<TabPanel
											className='client_tablepane_wrapper max-w-5xl mx-auto py-3 px-2 lg:px-4 pt-4 pb-5 bg-gray-50 rounded-lg shadow'
											value={value}
											index={2}>
											{/* <ClientOtherInfo user={user} setUser={setUser} /> */}
											<ClientOtherInfo user={user} />
										</TabPanel>
										{/* Add Detail Form section start */}
										<TabPanel
											className='client_tablepane_wrapper max-w-5xl mx-auto py-3 px-2 lg:px-4 pt-4 pb-5 bg-gray-50 rounded-lg shadow'
											value={value}
											index={3}>
											<AdminInfo user={user} />
											{/* <AdminInfo user={user} setUser={setUser} /> */}
										</TabPanel>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default ClientDetails;
