/** @format */
import React, { useEffect, useState } from 'react';
import "fontsource-roboto";
import { Typography, Grid, Button } from '@material-ui/core';
import ClientBusinessInfoForm from './ClientBusinessInfoForm.js'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import "./Client.css";
import { useSelector, useDispatch } from "react-redux";

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const ClientPersonalDetail = (props) => {
   const [edit, setEdit] = useState(false);

   return (
      <>
         <div>
            {/* here i am passing the state and user as props to another components */}
            {edit ? <ClientBusinessInfoForm user={props.user} setUser={props.setUser} setEdit={setEdit} /> :
               <Grid className="edit_info_spacing client_font_stypeP" container spacing={4}>
                  <Grid className="form_box" item xl={6} lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Primary Phone</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.phone}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Designations</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.designations}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Certifications?</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.certifications}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Real Estate sepecialities</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.specialties}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Office Address One</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.officeAddressOne}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Office Address Tow</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.officeAddressTwo}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Office City</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.officeCity}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Office State</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.officeState}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Office Zip</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.officeZip}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">MLS Association</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.mlsInfo}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Website URL</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.websiteUrl}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">Google Business Page</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.googleBusinessUrl}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">CRM Name</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           {props.user.crmName}
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">CRM Login username</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           **************
                        </p>
                     </Typography>
                  </Grid>
                  <Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
                     <Typography className="w-2/4 p-0 inline">
                        <label className="leading-5 text-gray-900 text-lg">CRM Password</label>
                        <p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
                           *************
                        </p>
                     </Typography>
                  </Grid>
                  <Typography className="text-right flex justify-end" mt={6}>
                     <button
                        type="submit"
                        onClick={() => setEdit(true)}
                        variant="contained"
                        className="h-14 client_btn"
                     >
                        <ModeEditOutlineIcon />
                     </button>
                  </Typography>
               </Grid>
            }
         </div>

      </>
   );
};
export default ClientPersonalDetail;