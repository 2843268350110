import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	adminLogin,
	getAdmin,
	changeRemember,
	clearErrors,
} from '../../store/admin';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import JRLogo from '../../img/JR_Logo.svg';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
// import './Login.css';

/** Form for logging user in */
const LoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const errors = useSelector((st) => st.admin.errors);
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);

	const [checked, setChecked] = useState(true);

	/** sets "remember me" state to true or false depending on user selection*/
	const handleRememberMe = (event) => {
		setChecked(event.target.checked);
	};

	/** sets user "remember me" status in redux */
	useEffect(() => {
		dispatch(changeRemember(checked));
	}, [checked]);

	/** clear Errors */
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

	const INITIAL_STATE = {
		username: '',
		password: '',
	};

	// sets state for form data and the kind of menu, which can be toggled.
	const [formData, setFormData] = useState(INITIAL_STATE);

	// handles the change in the form. react sets form data in state every time there is a change.
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// setLoginLoading(true);
		dispatch(adminLogin(formData));
	};

	return (
		<Box
			data-testid='admin-login'
			className=' h-screen  bg-real-estate flex items-center justify-center relative z-0'>
			<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: 'center', display: 'flex' }}
				maxWidth='md'
				className=''>
				<Stack spacing={4} className='w-full iphone:w-11/12 sm:w-2/3 md:w-7/12'>
					<Stack
						spacing={4}
						className='rounded-2xl pb-6  px-5 bg-white z-10 pt-0'>
						<Stack
							spacing={1}
							className='py-8 rounded-2xl -mt-12 bg-green-500  shadow-2xl'>
							<img
								className='h-12 iphone:h-16 w-auto m-0'
								src={JRLogo}
								alt='Jane Logo'
							/>
							<div className='text-white text-center text-xl md:text-3xl font-bold'>
								Admin Login
							</div>
						</Stack>
						{/* Form starts here */}
						<Stack
							spacing={{ xs: 2 }}
							component='form'
							onSubmit={handleSubmit}
							className='px-3 '>
							<TextField
								autoFocus={true}
								id='username'
								label='Username'
								variant='standard'
								type='text'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<AccountCircle fontSize='small' />
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								name='username'
								value={formData.username}
								onChange={handleChange}
								className='border-5'
							/>

							<TextField
								id='password'
								label='Password'
								variant='standard'
								type='password'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<LockIcon fontSize='small' />
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								name='password'
								value={formData.password}
								onChange={handleChange}
							/>

							<div className='text-red-900 mb-3 '>
								{Object.keys(errors).length
									? errors.map((e, indx) => (
											<div key={indx}>
												<small>ERROR: {e}</small>
											</div>
									  ))
									: null}
							</div>
							<div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
								<Box className='flex justify-center '>
									<FormControlLabel
										control={
											<Checkbox
												color='primary'
												checked={checked}
												onChange={handleRememberMe}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										className='text-gray-700 text-xs md:text-sm  '
										sx={{
											'& .MuiTypography-root': {
												xs: {
													fontSize: '.75rem',
													lineHeight: '1rem',
												},
												sm: {
													fontSize: '.85rem',
													lineHeight: '1.25rem',
												},
											},
										}}
										label='Remember me'
									/>
								</Box>

								<Box className='flex justify-center md:justify-end '>
									<NavLink
										exact
										to='/forgot-password'
										className='text-tertiary hover:text-primary lg:text-sm self-center text-xs '>
										Forgot your password?
									</NavLink>
								</Box>
							</div>
							<Box className='flex justify-center'>
								<Button
									type='submit'
									sx={{
										fontWeight: 600,
										fontSize: { xs: '1rem', sm: '1.125rem' },
										lineHeight: { xs: '1.5rem', sm: '1.75rem' },
									}}
									color='secondary'>
									Get Started
								</Button>
							</Box>
						</Stack>
					</Stack>
					<div className=' flex justify-center font-semibold sm:font-bold sm:text-lg md:text-xl text-white py-2 z-10'>
						<div>
							<div />
							Don't have an account?{' '}
							<NavLink
								exact
								to='/admin/signup'
								className=' underline hover:text-primary block text-center'>
								Create one now!
							</NavLink>{' '}
						</div>
					</div>
				</Stack>
			</Container>
		</Box>
	);
};

export default LoginForm;
