import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';
import SublyApi from '../../helpers/Api';
import BusinessInfoForm from './BusinessInfoForm';
import BusinessInfoCard from './BusinessInfoCard';
import ImageUpload from './ImageUpload.js';

const BusinessInfo = () => {
	const [edit, setEdit] = useState(false);

	return (
		<div>
			{/* Business Information section start */}

			<div className="detail_sec1">
				<div className="profile_img lg:flex md:flex sm:block lg:justify-between md:justify-between sm:justify-between items-center pt-8">
					<ImageUpload />
				</div>
				{edit ? <BusinessInfoForm setEdit={setEdit} /> : <BusinessInfoCard setEdit={setEdit} />}
			</div>
		</div>
	);
};

export default BusinessInfo;
