import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';
import SublyApi from '../../helpers/Api';
import BrokerInfoForm from './BrokerInfoForm';
import BrokerInfoCard from './BrokerInfoCard';

const BrokerInfo = () => {
	const [edit, setEdit] = useState(false);

	return (
		<div className="detail_sec2 pt-4">
			{edit ? <BrokerInfoForm setEdit={setEdit} /> : <BrokerInfoCard setEdit={setEdit} />}
		</div>
	);
};

export default BrokerInfo;
