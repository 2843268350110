import Client from '../img/admin/client1.jpg';
  

const handleClick = () => {
	console.info('You clicked the super Chip.');
};

class clientHelper {
	static clientInfo = [
		{
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},
        {
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},
        {
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},
        {
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},
        {
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},
        {
			image     : `${Client}`,
			clientName     :  'John Deo',
			clientEmail     :  'john@gmail.com',

		},



	];
}

export default clientHelper;