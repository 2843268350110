import './App.css';
import Routes from './routes/Routes';
import { useSelector, useDispatch } from 'react-redux';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import React, { useState, useEffect } from 'react';
import { userLogout } from './store/user';
import { adminLogout } from './store/admin';

// import resolveConfig from 'tailwindcss/resolveConfig';
// import tailwindConfigModule from '../tailwind.config.js';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

// import ReactGA from './helpers/analytics'; // need this here even if we don't use it.

// Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });

// import ReactGA from "./helpers/analytics";
import ReactGA from 'react-ga4';

const trackingId = 'G-CPSC7Z0WT4'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
//

// Send pageview with a custom path
ReactGA.send({ hitType: 'pageview', page: '/', title: 'Custom Title' });

// const tailwindConfig = resolveConfig(tailwindConfigModule);
const theme = createTheme({
	palette: {
		primary: { main: '#CC1DB9' }, //pink
		// secondary: { main: "#1DB9CC" }, //teal
		secondary: { main: '#3CACAE' }, //teal
		fadedSecondary: { main: '#D8EEEE' }, //faded teal
		tertiary: { main: '#CC881D' }, //gold
		quarternary: { main: '#1D61CC' }, // blue
		quinary: { main: '#1DCC30' }, //green
		senary: { main: '#B9CC1D' },
		septenary: { main: '#F9FAFB' }, //white
		octonary: { main: '#6B7280' }, //grey
	},
	//using tailwind breakpoints in material UI.  Added extra iphone break point
	breakpoints: {
		values: {
			xs: 0,
			iphone: 375,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
			xxl: 1536,
		},
	},
});

function App() {
	const dispatch = useDispatch();

	const currentUser = useSelector((st) => st.user.currentUser);
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);
	const adminRemember = useSelector((st) => st.admin.remember);
	const userRemember = useSelector((st) => st.user.remember);

	/** If admin unchecks the remember me box, it will remove user info out of redux when this page unloads */
	useEffect(() => {
		window.onbeforeunload = function () {
			if (!adminRemember) {
				dispatch(adminLogout());
			}
		};
	}, [adminRemember]);

	/** If user unchecks the remember me box, it will remove user info out of redux when this page unloads */
	useEffect(() => {
		window.onbeforeunload = function () {
			if (!userRemember) {
				dispatch(userLogout());
			}
		};
	}, [userRemember]);

	return (
		<ThemeProvider theme={theme}>
			<div className='App'>
				<Routes />
				{/* <MessengerCustomerChat pageId="114425623304645" appId="397024515130926" /> */}
			</div>
		</ThemeProvider>
	);
}

export default App;
