import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';
import EditProfileForm from './EditProfileForm';
import { useSelector, useDispatch } from 'react-redux';

const ProfileInfo = () => {
	const currentUser = useSelector((st) => st.user.currentUser);
	const dispatch = useDispatch();
	const error = useSelector((st) => st.user.errors);
	const [edit, setEdit] = useState(false);

	return (
		<div className="edit_info_spacing">
			{edit ? (
				<EditProfileForm setEdit={setEdit} />
			) : (
				<Grid container spacing={4}>
					<Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
						<div className="w-2/4 p-0 inline">
							<label className="leading-5 text-gray-900 text-lg">Username</label>
							<p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
								{currentUser.username}
							</p>
						</div>
					</Grid>
					<Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
						<div className="w-2/4 p-0 inline">
							<label className="leading-5 text-gray-900 text-lg">Email Address</label>
							<p className="w-full break-all text-lg text-gray-600 font-medium justify-start pt-1">
								{currentUser.email}
							</p>
						</div>
					</Grid>
					<Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
						<div className="w-2/4 p-0 inline">
							<label className="leading-5 text-gray-900 text-lg">First Name</label>
							<p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
								{currentUser.firstName}
							</p>
						</div>
					</Grid>
					<Grid className="form_box" item lg={6} md={6} sm={12} xs={12}>
						<div className="w-2/4 p-0 inline">
							<label className="leading-5 text-gray-900 text-lg">Last Name</label>
							<p className="w-full text-lg text-gray-600 font-medium justify-start pt-1">
								{currentUser.lastName}
							</p>
						</div>
					</Grid>

					<Typography className="text-right flex justify-end" mt={2}>
						<Button
							type="submit"
							onClick={() => setEdit(true)}
							variant="contained"
							className="h-12 setting_btn"
						>
							Edit
						</Button>
					</Typography>
				</Grid>
			)}
		</div>
	);
};

export default ProfileInfo;
