import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SublyApi from "../helpers/Api";

/** Function for retrieving and storing stripe products
 *
 * This will get all active products on the Jane Rothe Stripe account
 *
 * Returns {prices: [{id...unit_amount... product:{name, description}...}...]}
 */

export const getPrices = createAsyncThunk(
	"prices/getPrices",
	async ({ token }, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getStripePrices(token);
			return response;
		} catch (err) {
			return rejectWithValue(err);
		}
	}
);

export const initialState = {
	allPrices: [],
	loading: false,
	errors: [],
};

export const priceSlice = createSlice({
	name: "price",
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPrices.fulfilled, (state, action) => {
				state.allPrices = action.payload.prices;
				state.errors = [];
			})
			.addCase(getPrices.rejected, (state, action) => {
				state.errors = action.payload;
			});
	},
});

export default priceSlice.reducer;

// extraReducers : {
// 	[getPrices.fulfilled]: (state, action) => {
// 		state.allPrices = action.payload.prices;
// 		state.errors = [];
// 	},
// 	[getPrices.rejected]: (state, action) => {
// 		state.errors = action.payload;
// 	}
// }
