import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import JRLogo from '../img/JR_Logo.svg';
import './Login.css';
import { userSignup, clearErrors } from '../store/user.js';

import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

/** Form for singing up new users. Adds user to the db.
 *
 * Controlled form: user's input is pulled in state and then displayed in form. Controlled by {@link handleChange } function
 *
 * Textfield Validation: Erros determined by attributes set in TextField's inputProps
 *
 * Error Visibility: triggered by errorTrigger state only when form is submitted via {@link handleSubmit}. State must be true for errors to show. Error shown via Texfield's helper text.
 *
 * Submission: checks if form is valid. If true, form data is dispatched. If one field is invalid, entire form is invalid--form won't submit, and errors become visible
 */
const SignupForm = () => {
	const dispatch = useDispatch();
	const apiError = useSelector((st) => st.user.errors);
	const currentUser = useSelector((st) => st.user.currentUser);
	const formRef = useRef();
	const history = useHistory();

	const loading = useSelector((st) => st.user.pending);

	//sets toggle for showing and hiding password
	const [showPassword, setShowPassword] = useState(false);
	//state to store the height of the window screen
	const [windowHeight, setWindowHeight] = useState();
	// const [loading, setLoading] = useState(false); // state controls loading/spinner element

	useEffect(() => {
		function getWindowHeight() {
			// we have set a dynamic height to make the screen responsive in all screen resolution
			setWindowHeight(window.screen.availHeight);
		}
		getWindowHeight();
		window.addEventListener('resize', getWindowHeight); // the event trigered on the resolution change
		return () => window.removeEventListener('resize', getWindowHeight);
	}, []);

	// sets api errors to null when the component first mounts and when it dismounts, and every time the user types.
	// We do not expect api errors but in case we do, we want it to disappear when user starts typing again.
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors()); //dismount
		};
	}, []);

	useEffect(() => {
		currentUser.username && history.push('/home');
	}, [currentUser, history]);

	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors },
	} = useForm({
		defaultValues: {
			// here i set the default values in inpur field as a value
			username: '',
			password: '',
			firstName: '',
			lastName: '',
			email: '',
		},
	});

	/********************** Helper Functions**************** ********/

	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => {
			return !showPassword;
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	/**Set the trigger to show errors & Checks that there are no error. If no errors, form will submit.
	 * upon submission, creates new user object in redux store
	 * creates a stripe customer and stores customer Id on user object
	 */
	const onSubmit = (formData) => {
		dispatch(userSignup(formData));
	};

	/**
	 * defines the validation pattern for user password input.
	 * The password length must be greater than or equal to 8
	 * must contain one or more uppercase characters
	 * must contain one or more lowercase characters
	 * must contain one or more numeric values
	 * must contain one or more special characters
	 *
	 */
	const passwordPattern = `(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$`;

	if (loading)
		return (
			<div className='container mx-auto h-screen flex justify-center'>
				<div className='flex flex-col justify-center items-center space-y-12'>
					<CircularProgress color='secondary' size={100} />
					<div className='font-mono hidden iphone:block text-sm iphone:text-base md:text-2xl italic font-semibold text-slate-600'>
						Hang tight! We are rerouting you...
					</div>
				</div>
			</div>
		);

	return (
		<Box
			data-testid='signup-form'
			className={`${
				windowHeight <= 768 ? 'max-h-max h-full' : ' h-screen'
			} bg-real-estate flex items-center justify-center relative z-0`}>
			<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: 'center', display: 'flex', padding: '0.125rem' }}
				className='mt-24 mb-10'
				maxWidth='md'>
				<Stack
					spacing={4}
					className='rounded-2xl pb-6 px-5 bg-white z-10 pt-0 w-full iphone:w-11/12 sm:w-2/3 md:w-7/12 '>
					<Stack
						spacing={1}
						className='py-8 rounded-2xl -mt-12 bg-quarternary  shadow-2xl'>
						<img
							className='h-12 iphone:h-16 w-auto m-0'
							src={JRLogo}
							alt='Jane Logo'
						/>
						<div className='text-white text-center text-xl md:text-3xl font-bold'>
							Sign Up
						</div>
					</Stack>
					{/* Form Begins here */}
					<Stack
						spacing={{ xs: 1, iphone: 2 }}
						component='form'
						onSubmit={handleSubmit(onSubmit)}
						className='px-3 '
						noValidate>
						<TextField
							{...register('username', {
								required: 'username is required',
								minLength: {
									value: 5,
									message: 'Username must be at least 5 characters',
								},
								maxLength: {
									value: 30,
									message: 'Username must be no more than 30 characters',
								},
								pattern: {
									value: /^[^\s]*$/,
									message: 'Username cannot contain spaces',
								},
							})}
							error={!!errors.username}
							helperText={errors.username?.message}
							autoFocus={true}
							id='username'
							label='Username'
							variant='standard'
							type='text'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<AccountCircle fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								// minLength: 5,
								// maxLength: 20,
								'aria-label': 'Username',
							}}
							name='username'
							className='border-5'
						/>

						<TextField
							{...register('email', {
								required: 'email is required',
								minLength: {
									value: 6,
									message: 'Email must be at least 6 characters',
								},
								// maxLength: {
								// 	value: 30,
								// 	message: 'Email must be no more than 30 characters',
								// },
								pattern: {
									value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
									message: 'Please enter a valid email',
								},
							})}
							id='email'
							required
							error={!!errors.email}
							helperText={errors.email?.message}
							label='Email'
							variant='standard'
							type='email'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmailIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								// minLength: 6,
								// maxLength: 30,
								'aria-label': 'Email',
							}}
							name='email'
						/>
						<TextField
							{...register('password', {
								required: 'password is required',
								minLength: {
									value: 8,
									message: 'Password must be at least 8 characters',
								},
								maxLength: {
									value: 50,
									message: 'Password must be no more than 50 characters',
								},
							})}
							data-testid='password-input'
							aria-label='Password Input'
							required
							error={!!errors.password}
							helperText={errors.password?.message}
							id='password'
							label='Password'
							variant='standard'
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 8,
								// maxLength: 30,
								pattern: passwordPattern,
								'aria-label': 'Password',
							}}
							name='password'
						/>
						<TextField
							{...register('firstName', {
								required: 'First name is required',
								minLength: {
									value: 2,
									message: 'First name must be at least 2 characters',
								},
								maxLength: {
									value: 30,
									message: 'First name must be no more than 30 characters',
								},
							})}
							required
							error={!!errors.firstName}
							helperText={errors.firstName?.message}
							id='firstName'
							label='First Name'
							variant='standard'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmojiEmotionsIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								// minLength: 2,
								// maxLength: 20,
								'aria-label': 'First Name',
							}}
							name='firstName'
							type='text'
						/>
						<TextField
							{...register('lastName', {
								required: 'Last name is required',
								minLength: {
									value: 2,
									message: 'Last name must be at least 2 characters',
								},
								maxLength: {
									value: 30,
									message: 'Last name must be no more than 30 characters',
								},
							})}
							required
							error={!!errors.lastName}
							helperText={errors.lastName?.message}
							id='lastName'
							label='Last Name'
							variant='standard'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<EmojiEmotionsIcon fontSize='small' />
									</InputAdornment>
								),
								sx: {
									fontSize: { xs: '1rem', md: '1.25rem' },
									lineHeight: { xs: '1.25rem', md: '1.5rem' },
								},
							}}
							inputProps={{
								minLength: 2,
								// maxLength: 20,
								'aria-label': 'Last Name',
							}}
							name='lastName'
							type='text'
						/>
						<div className='text-red-600 mb-3 '>
							{apiError.map((e, indx) => (
								<div key={indx}>
									<small>ERROR: {e}</small>
								</div>
							))}
						</div>
						<Box className='flex justify-center'>
							<Button
								color='quarternary'
								type='submit'
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' },
								}}>
								Get Started
							</Button>
						</Box>
						<div className=' text-xs md:text-sm text-gray-600 py-2'>
							Already have an account?{' '}
							<NavLink
								exact
								to='/login'
								color='green'
								className='text-tertiary hover:text-primary'>
								Log in here
							</NavLink>{' '}
						</div>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
};

export default SignupForm;
