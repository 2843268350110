import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, clearSuccess, clearErrors } from '../../store/user.js';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import 'fontsource-roboto';
import './Settings.css';
import SublyApi from '../../helpers/Api';

const EditProfileForm = ({ setEdit }) => {
	const dispatch = useDispatch();
	const errors = useSelector((st) => st.user.errors);

	//holds success state of call to edit-user api
	const success = useSelector((st) => st.user.success);

	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);
	const formRef = useRef();
	const INITIAL_STATE = {
		username: '',
		firstName: '',
		lastName: '',
		email: ''
	};

	// sets state for form data
	const [formData, setFormData] = useState(currentUser);

	// sets state for object of for error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers when user submits. Errors will trigger if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	const [loading, setLoading] = useState(false); // state for managing loader show/hide

	// clears error and success messages from store when component dismounts
	useEffect(() => {
		return () => {
			dispatch(clearSuccess());
			dispatch(clearErrors());
		};
	}, []);

	//clear loader and success state in redux whenever we get a successful api call.
	useEffect(
		() => {
			if (success) {
				setLoading(false)
			}
		},
		[success]
	);

	/** changes the edit state depending on the success of call to api.
	 * if success is false, then there has been no call to api or there is an error. user stays on form
	 * if success is true, user has successfully submitted edit to api. From changes to card.
	 **/

	useEffect(
		() => {
			setEdit(!success);
		},
		[success]
	);

	/**
	 * This useEffect identifies the form input elements, extracts the initial validation messages and stores it in state. 
	 * useRef hook used to identify the form element and named "form ref"
	 * formRef.current is the actual form object. 
	 * The form object has child nodes identified in an array.  Some of those nodes are input elements. 
	 * Note: this useEffect is to capture the initial error message for each fied, if there are any. 
	 */
	useEffect(() => {
		for (let node of formRef.current) {
			const { name, validationMessage } = node;
			if (name) {
				setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: validationMessage }));
			}
		}
	}, []);

	// handles the change in the form. We set form data and error message in state every time there is a change in a field
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));
		console.log(formData.lastName);
		setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: e.target.validationMessage }));
	};

	/**Set the trigger to show errors & Checks that there are no error. If no errors, form will submit.
	 * upon submission, it edits user profile info in db and updates user object in redux store
	  */
	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorTrigger(true);
		if (formErrorMessage == "") {
			setLoading(true)
		}
		const profileData = {
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email
		};

		if (formRef.current.checkValidity()) {
			dispatch(updateUser({ username: currentUser.username, data: profileData, token: token }));
		}
	};

	return (
		<div>
			{loading ?
				<CircularProgress
					sx={{
						position: 'absolute',
						left: '0px',
						right: 0,
						margin: 'auto',
						top: 0,
						bottom: 0,
						zIndex: 10
					}}
					color="primary"
				/>
				: null}
			{Object.keys(errors).length ? (
				<div className="generic_error mt-6">
					<Alert
						className="mt-0 p-0 w-full bottom-0 left-0 z-10 "
						severity="error"
						onClose={() => dispatch(clearErrors())}
					>
						{'ERROR: Something went wrong with submission. Please report to admin.'}
					</Alert>
				</div>
			) : null}
			<Stack spacing={{ xs: 1, iphone: 2 }} component="form" onSubmit={handleSubmit} noValidate ref={formRef}>
				<Grid className="form_left_align" container spacing={3}>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Typography className="p-0 inline">
							<label className="leading-9 text-gray-900 block">Username</label>
							<TextField
								disabled
								className="w-full"
								required
								id="username"
								type="text"
								inputProps={{
									minLength: 5,
									maxLength: 20
								}}
								name="username"
								value={formData.username}
								onChange={handleChange}
								helperText="Username cannot be changed"
							/>
						</Typography>
					</Grid>

					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Typography className="p-0 inline">
							<label className="leading-9 text-gray-900 block">Email</label>
							<TextField
								className="w-full"
								required
								error={errorTrigger && formErrorMessage.email}
								helperText={errorTrigger && formErrorMessage.email}
								id="email"
								type="email"
								inputProps={{
									minLength: 6,
									maxLength: 30
								}}
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
						</Typography>
						{/* <span style={{ color: 'red' }}>{emailError}</span> */}
					</Grid>

					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Typography className="p-0 inline">
							<label className="leading-9 text-gray-900 block">First Name</label>
							<TextField
								className="w-full"
								required
								error={errorTrigger && formErrorMessage.firstName}
								helperText={errorTrigger && formErrorMessage.firstName}
								id="firstName"
								inputProps={{
									minLength: 2,
									maxLength: 20
								}}
								name="firstName"
								value={formData.firstName}
								onChange={handleChange}
								type="text"
							/>
						</Typography>
					</Grid>

					<Grid item lg={6} md={6} sm={12} xs={12}>
						<Typography className="p-0 inline">
							<label className="leading-9 text-gray-900 block">Last Name</label>
							<TextField
								className="w-full"
								required
								error={errorTrigger && formErrorMessage.lastName}
								helperText={errorTrigger && formErrorMessage.lastName}
								id="lastName"
								inputProps={{
									minLength: 2,
									maxLength: 20
								}}
								name="lastName"
								value={formData.lastName}
								onChange={handleChange}
								type="text"
							/>
						</Typography>
					</Grid>
					{/* {Object.keys(error).length ? (
						error.map((e, indx) => (
							<Grid key={indx} className="form_box" item lg={12} md={12} sm={12} xs={12}>
								<div className="text-red-800 ">
									<div>
										<small>
											{'ERROR: Something went wrong with submission. Please report to admin.'}
										</small>
									</div>
								</div>
							</Grid>
						))
					) : null} */}
				</Grid>

				<Box className="flex justify-end">
					<Button
						className="h-12 setting_cancel_btn setting_btn text-white bg-clientBlue"
						type="button"
						onClick={() => setEdit(false)}
						sx={{
							fontWeight: 600,
							fontSize: { xs: '1rem', iphone: '1.125rem' },
							lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
						}}
					>
						Cancel
					</Button>
					<Button
						className="h-12 setting_btn text-white bg-clientBlue ml-2"
						type="submit"
						sx={{
							fontWeight: 600,
							fontSize: { xs: '1rem', iphone: '1.125rem' },
							lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
						}}
					>
						Save
					</Button>
				</Box>
			</Stack>
		</div>
	);
};

export default EditProfileForm;
