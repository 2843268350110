import { typeformIds } from '../config';

class Forms {
	static packages = [
		{
			packageName: 'Gold',
			triggerSubscriptions: [
				'The Gold Package',
				'Listing & Post-Close Execution',
			],
			forms: [
				{
					image: ``,
					name: 'listing',
					index: 0,
					// id    : 'Ew161S12'
					id: typeformIds.longListing,
				},
				{
					image: ``,
					name: 'closing',
					index: 1,
					id: typeformIds.longClosing,
				},
				{
					image: ``,
					name: 'client gifts',
					index: 2,
					id: typeformIds.clientGifts,
				},
				{
					image: ``,
					name: 'moving boxes',
					index: 3,
					id: typeformIds.movingBoxes,
				},
			],
		},
		{
			packageName: 'Social Media',
			triggerSubscriptions: ['Social Media Marketing'],
			forms: [
				{
					image: ``,
					name: 'listing',
					index: 0,
					id: typeformIds.shortListing,
				},
				{
					image: ``,
					name: 'closing',
					index: 1,
					id: typeformIds.shortClosing,
				},
			],
		},
	];
}

export default Forms;
