import React, { useState, useEffect } from "react";

const Timer = ({ seconds, setSeconds }) => {
	// const [seconds, setSeconds] = useState(8);
	useEffect(() => {
		const intervalId = setInterval(() => {
			setSeconds((seconds) => seconds - 1);
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return <h1>{seconds}</h1>;
};

export default Timer;
