/** @format */
import React, { useState,useEffect } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import "fontsource-roboto";
import { Typography, Button, Container, Grid, Box } from '@material-ui/core';
import Sidemenu from '../../adminHelper/sidebar.js';
import Toggle from '../../img/admin/menu.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./Sidebar.css";

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const Sidebar = () => {
  const [isActive, setActive] = useState("");
  const [width, setWidth] = useState(window.innerWidth)
  const inValue = ((width<=812) ?  true : false);
  const [clicked, setClicked] = useState(inValue);
  function addClass() {
   (clicked==false)? setClicked(true):setClicked(false)
  }

  var first = window.location.pathname;

first.indexOf(2);

first.toLowerCase();

first = first.split("/")[2];

//here i added the class on body according to the width size
useEffect(() => {      
  if(width <= 812) {
    document.body.classList.add('side_bar_responsive');
     } else {		   
     document.body.classList.remove('side_bar_responsive');		
    }
  },[width <= 812])

  /** Adding class on body tag for adding some css **/
  useEffect(() => {
		if(clicked) {
			document.body.classList.add('add_space');
		   } else {		   
		   document.body.classList.remove('add_space');		
			}
	  },[clicked])

  const setActiveClass = (menuUrl) => {
    setActive(menuUrl);
  }; 

  window.addEventListener('resize', function(event){
    setWidth(window.innerWidth)
})
  return (
    <>
      <div className={  `sidebar_wrapper base-state ${(clicked==true) ? 'click-state': ""} ${width <= 812 ? "responsive_sideBar" : ""}` }>
        <div className="sidebar_toggle">
          <span onClick={addClass}><img src={Toggle} alt="sideMenu1"/></span>
        </div>
          <div className="sidebar_section" >
            {Sidemenu.menus.map((menu, index) => (
              
                    <div className="SideMenu_box text-center" key={index}>
                        <ul>
                            <li onClick={() =>setActiveClass(menu.url)} className={menu.url==first ? 'active': null} 
       >
                                <Link to={`/admin/${menu.url}`}>
                                    <div key={index} className={`rounded-md flex items-center text-center py-3 px-2 Side_menu_box  ${menu.class}`}>
                                        <Typography variant="span" className="sidebar_icon">
                                        <img className="side_menu_icon w-auto h-auto border border-slate-200 border-solid shadow-lg p-3 bg-white" src={menu.image} alt="sideMenu1"/>
                                        <img className="side_menu_icon_active w-auto h-auto" src={menu.hoverimage} alt="sideMenu1"/>
                                        </Typography>
                                        <p className="text-lg text-left pl-5 text-gray-400 m-0">{menu.name}</p>
                                        <span><KeyboardArrowDownIcon /></span>
                                    </div>
                                </Link>         
                            </li>
                        </ul>
                    </div>
                ))}

          </div>
      </div>
    </>
  );
};

export default Sidebar;



