import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, TextField, Grid } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import 'fontsource-roboto';
import './Settings.css';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
// import { updateUser } from '../../actions/currentUser';
import { updateUser, clearSuccess, clearErrors } from '../../store/user.js';
import SublyApi from '../../helpers/Api';
import NumberFormat from 'react-number-format';
import Alert from '@mui/material/Alert';

const BusinessInfoForm = ({ setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();
	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);
	const errors = useSelector((st) => st.user.errors); // holds server side errors

	//holds success state of call to edit-user api
	const success = useSelector((st) => st.user.success);

	/********* useState hook definitions **********/
	const INITIAL_STATE = {
		phone: currentUser.phone || '',
		licenseNumber: currentUser.licenseNumber || '',
		designations: currentUser.designations || '',
		certifications: currentUser.certifications || '',
		specialties: currentUser.specialties || '',
		officeAddressOne: currentUser.officeAddressOne || '',
		officeAddressTwo: currentUser.officeAddressTwo || '',
		officeCity: currentUser.officeCity || '',
		officeState: currentUser.officeState || '',
		officeZip: currentUser.officeZip || '',
		mlsInfo: currentUser.mlsInfo || '',
		websiteUrl: currentUser.websiteUrl || '',
		googleBusinessUrl: currentUser.googleBusinessUrl || '',
		crmName: currentUser.crmName || '',
		crmUsername: '',
		crmPassword: ''
	};

	// sets state for form data
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for form error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers to true when user submits form. Errors will display if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	// sets state for data that shouldn't be stored in redux. Obtains it straight from api
	const [confidentialInfo, setConfidentialInfo] = useState({ crmUsername: '', crmPassword: '' });

	//sets toggle for showing and hiding confidential data
	const [showData, setShowData] = useState({ crmUsername: true, crmPassword: true });

	/********* useRef hook definitions *************/

	//sets ref for form. used on form submit to check for errors
	const formRef = useRef();

	/**************** useEffect hook definitions ************/

	// gets confidential information for user when component loads.  retrieves once.
	useEffect(() => {
		async function getConfidentialInfo() {
			const { user } = await SublyApi.getUser(currentUser.username, token);
			setConfidentialInfo({
				//Note: The default is null. This pulls what's in the database. If user submits anything other than string, error will arise.
				// Hence, we add an option for an enpty quote so that it's always string
				crmUsername: user.crmUsername || '',
				crmPassword: user.crmPassword || ''
			});
		}

		getConfidentialInfo();
	}, []);

	// puts confidential information (CI) in form data for display. Is triggered once CI is obtained from API
	useEffect(
		() => {
			setFormData((formData) => ({
				...formData,
				crmUsername: confidentialInfo.crmUsername,
				crmPassword: confidentialInfo.crmPassword
			}));
		},
		[confidentialInfo]
	);

	/** changes the edit state depending on the success of call to api.
	 * if success is false, then there has been no call to api or there is an error. user stays on form
	 * if success is true, user has successfully submitted edit to api. From changes to card.
	 **/

	useEffect(
		() => {
			setEdit(!success);
		},
		[success]
	);

	// clears error and success messages from store when component dismounts
	useEffect(() => {
		return () => {
			dispatch(clearSuccess());
			dispatch(clearErrors());
		};
	}, []);

	/******* Helper Functions ********/

	/**
	 * handles submission of the form. Sets error trigger on to show potential errors.
	 * Updates user only if the form has no errors. 
	 */

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorTrigger(true);

		// if (formRef.current.checkValidity()) {
		// 	dispatch(updateUser(currentUser.username, formData, token));
		// 	setEdit(false);
		// }
		if (formRef.current.checkValidity()) {
			dispatch(updateUser({ username: currentUser.username, data: formData, token: token }));
		}
	};

	/**
	 * Handles the change in the form. 
	 * Sets form data and error message in state every time there is a change in a field.
	 */

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((formData) => ({ ...formData, [name]: value }));

		setFormErrorMessage((formErrorMessage) => ({ ...formErrorMessage, [name]: e.target.validationMessage }));
	};

	// toggles the showData state between true and false.
	const handleShowData = (data) => {
		setShowData((showData) => ({
			...showData,
			[data]: !showData[data]
		}));
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// regex patter for american phone numbers
	const phoneNumberPattern = `^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$`;
	// const error_demo = 'You can view alert information for your ThoughtSpot cluster from the Admin Console';

	return (
		<div>
			{/*Business Information Form section start */}
			{/* <div className="generic_error mt-6">
				<Alert className="mt-0 p-0 w-full bottom-0 left-0 z-10 " severity="error" onClose={error_demo}>
					{error_demo}
				</Alert>
			</div> */}
			{Object.keys(errors).length ? (
				<div className="generic_error mt-6">
					<Alert
						className="mt-0 p-0 w-full bottom-0 left-0 z-10 "
						severity="error"
						onClose={() => dispatch(clearErrors())}
					>
						{'ERROR: Something went wrong with submission. Please report to admin.'}
					</Alert>
				</div>
			) : null}
			<div className="detail_sec1">
				<Stack spacing={{ xs: 1, iphone: 2 }} component="form" onSubmit={handleSubmit} noValidate ref={formRef}>
					<div className="profile_form_wrapper edit_info_spacing pt-6">
						<Grid container spacing={3}>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Primary Phone</label>

									<NumberFormat
										value={formData.phone}
										className="w-full py-3 px-4 border-1 border-blue-100"
										name="phone"
										mask="_"
										error={formErrorMessage.phone && formData.phone == '' ? errorTrigger : false}
										helperText={errorTrigger && formData.phone == '' && formErrorMessage.phone}
										customInput={TextField}
										onChange={handleChange}
										format="###-###-####"
										type="text"
										inputProps={{
											pattern: phoneNumberPattern
										}}
										placeholder="e.g. 609-629-0019"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Designations</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="designations"
										name="designations"
										value={formData.designations}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Certifications?</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="certifications"
										name="certifications"
										value={formData.certifications}
										onChange={handleChange}
										type="text"
										inputProps={{
											minLength: 5
										}}
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">
										Real Estate Specialities
									</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="specialties"
										name="specialties"
										value={formData.specialties}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">License</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="licenseNumber"
										name="licenseNumber"
										value={formData.licenseNumber}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>

							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Office Address One</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="officeAddressOne"
										name="officeAddressOne"
										value={formData.officeAddressOne}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Office Address Two</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="officeAddressTwo"
										name="officeAddressTwo"
										value={formData.officeAddressTwo}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Office City</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="officeCity"
										name="officeCity"
										value={formData.officeCity}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">
										Office Address State
									</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="officeState"
										name="officeState"
										value={formData.officeState}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Office Zip</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="officeZip"
										name="officeZip"
										value={formData.officeZip}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>

							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">MLS Association</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="mlsInfo"
										name="mlsInfo"
										value={formData.mlsInfo}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">Website URL</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="websiteUrl"
										name="websiteUrl"
										value={formData.websiteUrl}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">
										Google Business Page URL
									</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="googleBusinessUrl"
										name="googleBusinessUrl"
										value={formData.googleBusinessUrl}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline">
									<label className="leading-9 text-lg text-gray-900 block">CRM Name</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100"
										id="crmName"
										name="crmName"
										value={formData.crmName}
										onChange={handleChange}
										type="text"
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline crm_login_password">
									<label className="leading-9 text-lg text-gray-900 block">CRM Login Username</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100 pawicon"
										id="crmUsername"
										name="crmUsername"
										value={formData.crmUsername}
										onChange={handleChange}
										type={showData.crmUsername ? 'password' : 'text'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														className="eye_icon"
														aria-label="toggle password visibility"
														onClick={() => {
															handleShowData('crmUsername');
														}}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showData.crmUsername ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Typography>
							</Grid>
							<Grid item lg={4} md={6} sm={12} xs={12}>
								<Typography className="p-0 inline crm_login_password">
									<label className="leading-9 text-lg text-gray-900 block">CRM Login Password</label>
									<TextField
										className="w-full py-3 px-4 border-1 border-blue-100 pawicon"
										id="crmPassword"
										type={showData.crmPassword ? 'password' : 'text'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														className="eye_icon"
														aria-label="toggle password visibility"
														onClick={() => {
															handleShowData('crmPassword');
														}}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showData.crmPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
										name="crmPassword"
										value={formData.crmPassword}
										onChange={handleChange}
									/>
								</Typography>
							</Grid>
						</Grid>
						<Box className="flex justify-end" mt={2}>
							<Button
								className="h-12 setting_cancel_btn setting_btn text-white bg-clientBlue"
								type="button"
								onClick={() => setEdit(false)}
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Cancel
							</Button>
							<Button
								className="h-12 setting_btn text-white"
								type="submit"
								sx={{
									fontWeight: 600,
									fontSize: { xs: '1rem', iphone: '1.125rem' },
									lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
								}}
							>
								Save
							</Button>
						</Box>
					</div>
				</Stack>
			</div>
		</div>
	);
};

export default BusinessInfoForm;
