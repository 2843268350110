import React, { useState, useEffect } from 'react';
import SublyApi from '../../helpers/Api';
import { useSelector, useDispatch } from 'react-redux';

const ServiceList = ({ subs }) => {
	/************ Redux definitions ******/
	const token = useSelector((st) => st.admin.token);

	/********* useState hook definitions **********/

	//state for an object that represents how many users are subscribed to each price plan.
	// produces subCounts => {priceName1: count1, priceName2: count2...}
	const [subCounts, setSubCounts] = useState({});

	// boolean for whether the component should generate subCounts.  This should only happen once.  Once it does, it will be set to false.
	const [getSubCounts, setGetSubCounts] = useState(true);

	/********* useEffect hook definitions **********/

	/** effect for calculating subscription counts. This happens only once every mount.
	 * subCount state must be reset before recalculating otherwise the count will be wrong
	 */
	useEffect(() => {
		// don't perform calculations unless subscriptions from stripe are gotten and getSubCounts is set to true
		if (subs.length && getSubCounts) {
			//create subscription price name list by joining product name and price amount from each subscription
			const subPriceNames = subs.map((sub, indx) => {
				const firstSubscriptionItem = sub.items.data[0]; // shouldn't be more than one
				const priceAmount = firstSubscriptionItem.price.unit_amount / 100;
				const priceName = `${sub.productName} (${priceAmount})`;

				return priceName;
			});

			// create subscription count object by going through subscription price names and counting duplicates.
			for (let x = 0; x < subPriceNames.length; x++) {
				const name = subPriceNames[x];
				if (subCounts[name]) {
					subCounts[name] = subCounts[name] + 1;
				} else {
					subCounts[name] = 1;
				}
			}

			setGetSubCounts(false); // getSubCounts is set to false so that subCounts isn't calculated more than once.
		}
	});

	return (
		<React.Fragment>
			{Object.keys(subCounts).length &&
				Object.keys(subCounts).map((s, index) => (
					<li
						className='flex p-3 w-full justify-between items-center'
						key={index}>
						<p>{s}</p>
						<p className='text-right'>{subCounts[s]}</p>
					</li>
				))}
		</React.Fragment>
	);
};

export default ServiceList;
