import Menu1 from '../img/menu_icon1.png';
import Menu2 from '../img/menu_icon2.png';
import Menu3 from '../img/menu_icon3.png';
import Menu4 from '../img/menu_icon4.png';
import Menu5 from '../img/menu_icon5.png';
import Menu6 from '../img/menu_icon6.png';
import ActiveMenu1 from '../img/a_menu_icon1.png';
import ActiveMenu2 from '../img/a_menu_icon2.png';
import ActiveMenu3 from '../img/a_menu_icon3.png';
import ActiveMenu4 from '../img/a_menu_icon4.png';
import ActiveMenu5 from '../img/a_menu_icon5.png';
import ActiveMenu6 from '../img/a_menu_icon6.png';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const handleClick = () => {
	console.info('You clicked the super Chip.');
};

class Home {
	static menus = [
		{
			image: ({ className, testId }) => (
				<SupportAgentIcon className={className} data-testid={testId} />
			),
			hoverimage: `${ActiveMenu1}`,
			name: 'My Services',
			// hovercolor: 'menu_box1',
			hovercolor: 'secondary',
			url: 'my-services',
			accessKey: 'myServices', // key for holding the binary state that controls user access to this module
			initialAccess: true,
			triggerSubscriptions: [],
		},
		{
			image: ({ className, testId }) => (
				<SettingsSuggestIcon className={className} data-testid={testId} />
			),
			// image: <SettingsSuggestIcon />,
			hoverimage: `${ActiveMenu5}`,
			name: 'Settings',
			// hovercolor: 'menu_box5',
			hovercolor: 'quarternary',
			url: 'settings',
			accessKey: 'settings', // key for holding the binary state that controls user access to this module
			initialAccess: true,
			triggerSubscriptions: [],
		},

		// {
		// 	image      : `${Menu2}`,
		// 	hoverimage : `${ActiveMenu2}`,
		// 	name       : 'All Services',
		// 	hovercolor : 'menu_box2'
		// },

		{
			image: ({ className, testId }) => (
				<InsertDriveFileIcon className={className} data-testid={testId} />
			),
			// image: <InsertDriveFileIcon />,
			hoverimage: `${ActiveMenu3}`,
			name: 'My Forms',
			// hovercolor: 'menu_box3',
			hovercolor: 'primary',
			url: 'forms',
			accessKey: 'myForms', // key for holding the binary state that controls user access to this module
			initialAccess: false,
			triggerSubscriptions: [
				'The Gold Package',
				'Social Media Marketing',
				'Listing & Post-Close Execution',
			],
		},

		// {
		// 	image      : `${Menu4}`,
		// 	hoverimage : `${ActiveMenu4}`,
		// 	name       : 'Activity Logs',
		// 	hovercolor : 'menu_box4'
		// },

		{
			image: ({ className, testId }) => (
				<MonetizationOnIcon className={className} data-testid={testId} />
			),
			// image: <MonetizationOnIcon />,
			hoverimage: `${ActiveMenu6}`,
			name: 'Spending Tracker',
			// hovercolor: 'menu_box6',
			hovercolor: 'tertiary',
			url: 'spending-tracker',
			accessKey: 'spendingTracker',
			initialAccess: true,
			triggerSubscriptions: [],
		},
	];
}

export default Home;
