/* This example requires Tailwind CSS v2.0+ */
import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline';

const steps = [
	{
		// name        : 'Signup for free!',
		name        : 'Free consultation!',
		description :
			'Set up time to discuss your needs with no obligation consulations. Together we will decide if Jane is an excellent fit for your business.',
		// 'Signing up is easy and painless. It will give you access to the entire suite of what Jayro has to offer.',
		id          : '1',
		icon        : GlobeAltIcon
	},
	{
		name        : 'Smooth onboarding',
		description :
			'Once signed up, your account manager will contact you to coordinate your onboarding. We will ensure a smooth transition and answer any additional questions you might have.',
		id          : '2',
		icon        : LightningBoltIcon
	},
	{
		name        : 'Select your services',
		description :
			'Every agent’s business is different, so we accommodate that. You are free to subscribe and unsubscribe to services as they fit your needs. We give you a dashboard to easily manage your products and services. ',
		id          : '3',
		icon        : ScaleIcon
	},

	{
		name        : 'Success!',
		description : 'That’s it! Sit back, relax, and let’s take care of your business.',
		id          : '4',
		icon        : MailIcon
	}
];

export default function Steps() {
	return (
		<div className="lg:mt-16 overflow-hidden">
			<div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
				<svg
					className="absolute top-0 left-full transform iphone:-translate-x-1/3 md:-translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
					width={404}
					height={784}
					fill="none"
					viewBox="0 0 404 784"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
						</pattern>
					</defs>
					<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
				</svg>

				<div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
					<div className="lg:col-span-1">
						<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 lg:text-4xl">
							How it works:
						</h2>
					</div>
					<dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
						{steps.map((step) => (
							<div key={step.name}>
								<dt className="">
									<div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
										{/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
										<span className="flex-shrink-0 w-full h-full flex items-center justify-center rounded-full">
											<span className="text-white font-semibold text-lg">{step.id}</span>
										</span>
									</div>
									<div className="mt-5 text-lg leading-6 font-semibold text-gray-900 p-0">
										{step.name}
									</div>
								</dt>
								<dd className="mt-2 text-base text-gray-500">{step.description}</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
