/* This example requires Tailwind CSS v2.0+ */
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline';

import BlockQuote from './BlockQuote';
import { NavLink } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import ReactGA from '../helpers/analytics';

export default function FeaturesRight({
	title,
	message,
	icon,
	img,
	buttonText,
	quote,
	avatar,
	person,
	buttonLink,
	info,
}) {
	const signupButton = (
		<NavLink
			onClick={() => {
				ReactGA.event({
					category: 'Button',
					action: 'Click',
					label: { buttonText },
				});
			}}
			exact
			to='/signup'
			className='inline-flex px-4 py-2 border text-base font-medium rounded-md shadow text-white bg-primary hover:bg-secondary'>
			{buttonText}
		</NavLink>
	);

	const calendlyButton = (
		<div
			onClick={() => {
				ReactGA.event({
					category: 'Button',
					action: 'Click',
					label: { buttonText },
				});
			}}>
			<PopupButton
				url='https://calendly.com/erin-janerothe/zoomcall'
				text={buttonText}
				className='inline-flex px-4 py-2 border text-base font-medium rounded-md shadow text-white bg-primary hover:bg-secondary '
			/>
		</div>
	);
	return (
		<div className='relative bg-white pt-2 overflow-hidden'>
			<div className=''>
				<div className='lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 '>
					<div className='px-4 max-w-xl mx-auto sm:px-6 lg:py-24 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 '>
						<div>
							<div>
								<span className='h-12 w-12 rounded-md flex items-center justify-center bg-primary '>
									{icon}
								</span>
							</div>
							<div className='mt-6'>
								<h2 className='text-2xl sm:text-3xl font-extrabold tracking-tight text-gray-900'>
									{title}
								</h2>
								<p className='mt-4 text-lg text-gray-500 px-0'>{message}</p>
								<div className='mt-6'>
									{/* <a
										href="#"
										className="inline-flex px-4 py-2 border text-base font-medium rounded-md shadow text-white bg-primary hover:bg-secondary"
									>
										{buttonText}
									</a> */}

									{info ? calendlyButton : signupButton}
								</div>
							</div>
							<BlockQuote quote={quote} avatar={avatar} person={person} />
						</div>
					</div>
					<div className='mt-8 sm:mt-16 lg:mt-0 lg:col-start-1'>
						<div className=' lg:px-0 lg:m-0 lg:relative lg:h-full flex items-center'>
							<img
								className=' w-11/12 iphone:w-10/12 md:w-3/4 lg:w-full rounded-xl mx-auto h-auto '
								src={img}
								alt='Value Prop Image'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
