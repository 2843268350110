import React, { useState, useContext, useEffect } from "react";
import {
	Card,
	ButtonBase,
	IconButton,
	CardContent,
	Typography,
	CardActions,
	Button,
	CardActionArea,
	Tooltip,
} from "@material-ui/core";
import "./ProductPreview.css";
import ConfirmationDialog from "./ConfirmationDialog";
import { getPricesFromApi } from "../../actions/prices";
import { unsubscribeFromProduct } from "../../actions/currentUser";
import SublyApi from "../../helpers/Api";

import { useSelector, useDispatch } from "react-redux";

/** This component is used for users to view subscribed products on their profile.  Allows user to unsubscribe. */

const ProductDisplay = ({
	icon,
	price,
	description,
	title,
	id,
	className,
	iconColor,
	actionColor,
	subtitle,
	setLoading,
	cancel,
}) => {
	/************ Redux definitions ******/
	const token = useSelector((st) => st.user.token);
	const currentUser = useSelector((st) => st.user.currentUser);
	const dispatch = useDispatch();

	/********* useState hook definitions **********/
	const [dialogOpen, setDialogOpen] = useState(false);

	/******* Helper Functions ********/

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	//re-routes user to stripe customer portal
	const handleDialogConfirmation = async () => {
		try {
			setLoading(true);
			setDialogOpen(false);
			const portalSessionUrl = await SublyApi.customerPortal(
				currentUser.customerId,
				token
			);
			window.location.href = portalSessionUrl;
		} catch (err) {
			console.log(err);
		}
	};

	const getModalText = () => {
		return cancel
			? "You've already unsuscribed from this service. Want to see the dashboard anyway?"
			: `You are unsubscribing from the ${title} service. You will no longer have access to this service`;
	};

	return (
		<div className={`w-full hover:drop-shadow-xl ${className}`}>
			<Tooltip title={description} arrow placement="top">
				<ButtonBase className="w-full h-full" onClick={handleDialogOpen}>
					<Card
						className="w-full h-full flex flex-col"
						sx={{ overflow: "visible" }}>
						<CardContent className="  flex-grow ">
							<span
								className={`relative bottom-10 inline-flex items-center justify-center p-2  rounded-md shadow-lg border-2 border-stone-500 ${iconColor}`}>
								{icon}
							</span>
							<Typography className="" variant="subtitle1">
								{title}
							</Typography>
							<Typography className="" variant="subtitle1">
								{subtitle}
							</Typography>
							<Typography className="">
								<b>${price}/month</b>
							</Typography>
						</CardContent>
						<CardActionArea component="div" className="">
							<Button component="div" size="small" sx={{ color: actionColor }}>
								{cancel ? "You are unsubscribed" : "unsubscribe"}
							</Button>
						</CardActionArea>
					</Card>
				</ButtonBase>
			</Tooltip>
			<ConfirmationDialog
				title="You are unsubscribing"
				handleClose={handleDialogClose}
				handleConfirmation={handleDialogConfirmation}
				open={dialogOpen}
				confirmation={"Let's do it!"}
				close={"Not Yet"}
				text={getModalText()}
			/>
		</div>
	);
};

export default ProductDisplay;
