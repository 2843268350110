import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SwipeableDrawer, CircularProgress, Grid } from '@material-ui/core';
import { Stack, Container, Box, Divider, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProductPreview from './ProductPreview';
import ProductDisplay from './ProductDisplay';
import { useSelector, useDispatch } from 'react-redux';
import { getPrices } from '../../store/price.js';
import { getUserSubs, clearErrors } from '../../store/user.js';
import 'fontsource-roboto';
import ConfirmationDialog from './ConfirmationDialog';
import { useHistory } from 'react-router-dom';
import serviceIcons from '../../helpers/serviceIcons.js';
import startBuilding from '../../img/my-services/startBuildingTeal.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

/** This Component houses the core of the user profile.
 * It contains a drawer that displays available services to which the user has yet to subscribe
 * User can subscribe to any service via selection.
 */

const MyServices = () => {
	/************ Redux definitions ******/

	const dispatch = useDispatch();
	const token = useSelector((st) => st.user.token);

	const prices = useSelector((st) => st.price.allPrices); // stripe product prices

	//sorting prices from highest to lowest. use slice function to copy array and avoid read only object error
	const sortedPrices = prices
		? prices.slice().sort((a, b) => b.unit_amount - a.unit_amount)
		: [];

	const currentUser = useSelector((st) => st.user.currentUser); // current user object
	// const userSubs = currentUser.stripeSubs ? currentUser.stripeSubs : []; // user subscriptions
	const userSubs = useSelector((st) => st.user.stripeSubs); // user subscriptions

	/********* useState hook definitions **********/

	//state for setting a message when a user either completes the process of subscribing or cancels the process
	const [message, setMessage] = useState('');

	//state for opening confimation dialogue after the process of subscribing is completed on canceled.
	const [dialogOpen, setDialogOpen] = useState(false);

	const [loading, setLoading] = useState(false); // state controls loading/spinner element

	const [drawerOpen, setDrawerOpen] = useState(false); // state controls opening drawer

	/********* useEffect hook definitions **********/

	// gets user subscriptions from stripe immediately
	useEffect(() => {
		const customerId = currentUser.customerId;
		dispatch(getUserSubs({ customerId, token }));
	}, []);

	/** clear Errors */
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors());
		};
	}, []);

	//upon loading, this will retrieve all products directly from Stripe.
	useEffect(() => {
		dispatch(getPrices({ token }));
	}, []);

	//checks redirect from checkout and sets dialog message depending on the URL params
	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search);

		if (query.has('success')) {
			setMessage('Order placed! You will receive an email confirmation.');
			setDialogOpen(true);
		}

		if (query.has('canceled')) {
			setMessage(
				"Order canceled -- continue to shop around and checkout when you're ready."
			);
			setDialogOpen(true);
		}
	}, []);

	/********* react router definitions **********/

	const history = useHistory();

	/******* Helper Functions and definitions ********/

	const handleDialogClose = () => {
		setDialogOpen(false);
		setMessage('');
		history.push('/my-services');
	};

	/**
	 * Filters sorted prices
	 * removes subscribed prices (and other prices in the same product)
	 * removes prices that are not active from selection
	 * * Note: each subscription may have more than one item. We will only have one item per subscription.
	 */

	const filteredPrices = sortedPrices.length
		? sortedPrices.filter((p) => {
				if (!p.active) return false; // remove prices that are not active

				for (let sub of userSubs) {
					if (sub.items.data[0].price.product === p.product.id) return false;
				}

				return true;
		  })
		: [];

	//find price object given sub object
	// get price id from sub object and find the price object in the list of all prices.
	const findPriceObject = (subObject) => {
		const price = prices.find((p) => {
			return p.id === subObject.items.data[0].price.id;
		});

		return price;
	};

	// total cost of all subscriptions
	const monthlyCost = userSubs.reduce((total, current) => {
		console.log(current);
		return total + findPriceObject(current)?.unit_amount / 100;
	}, 0);

	// toggles drawer open and closed
	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setDrawerOpen(open);
	};

	if (loading)
		return (
			<div className='container mx-auto h-screen flex justify-center'>
				<div className='flex flex-col justify-center items-center space-y-12'>
					<CircularProgress color='secondary' size={100} />
					<div className='font-mono hidden iphone:block text-sm iphone:text-base md:text-2xl italic font-semibold text-slate-600'>
						Hang tight! We are rerouting you...
					</div>
				</div>
			</div>
		);

	return (
		<Container maxWidth='xl' className=''>
			<Stack
				direction='row'
				justifyContent='center'
				spacing={2}
				className=' my-10'>
				<Box
					component='span'
					className=' font-sans font-extrabold text-zinc-600 text-3xl md:text-5xl p-4'>
					My Services
				</Box>
			</Stack>
			<Stack
				direction='row'
				justifyContent='space-between'
				spacing={2}
				className='mt-10 md:mb-20 lg:mb-28'>
				<Box component='span' className=' py-2 flex items-center'>
					<Link
						to='/home'
						className='flex items-center lg:justify-start md:justify-start sm:justify-center '>
						{/* <span className=" ">
							<img className="w-full h-full ml-0 my-0 " src={Arrow} alt="Home" />
							{/* <ArrowBackIosIcon sx={{ color: 'rgb(107 114 128)' }} className=" " /> */}
						{/* </span>{' '} */}
						<Button
							variant='contained'
							size='small '
							sx={{
								bgcolor: 'septenary.main',
								borderRadius: '15%',
								'&:hover': {
									bgcolor: 'secondary.main',
									cursor: 'pointer',
									'& .arrowIcon': {
										color: 'white',
									},
								},
							}}>
							<ArrowBackIosIcon
								className='arrowIcon'
								sx={{ color: 'octonary.main' }}
							/>
						</Button>
						<span className='ml-3 hidden md:inline text-gray-500 font-semibold'>
							Home
						</span>
					</Link>
				</Box>
				<Box component='span' className='py-2 flex justify-end items-center '>
					<Button
						variant='contained'
						color='primary'
						onClick={toggleDrawer(true)}
						sx={{ borderRadius: '15%' }}>
						<AddIcon sx={{ color: 'rgb(209 213 219)' }} />
					</Button>
					<span className='hidden md:inline text-gray-500 font-semibold ml-3'>
						Add a service
					</span>
				</Box>
			</Stack>

			{/* Subscriptions Statistics section begins */}
			<Stack
				direction='row'
				justifyContent='between'
				spacing={2}
				className=' my-10 mb-16 iphone:mb-20 '>
				<Box
					component='span'
					className=' py-1 font-bold text-sm iphone:text-base text-gray-500 '>
					Total Subs:{' '}
					<Box
						component='span'
						className='ml-1 iphone:ml-2 font-bold text-base iphone:text-lg text-blue-500'>
						{' '}
						{userSubs.length}
					</Box>
				</Box>

				<Divider orientation='vertical' flexItem />
				<Box
					component='span'
					className=' py-1 font-bold text-sm iphone:text-base text-gray-500  '>
					<span>Total Cost:</span>
					<Box
						component='span'
						className='iphone:ml-2 ml-1 font-bold text-base iphone:text-lg text-blue-500'>
						${monthlyCost}
					</Box>
				</Box>
			</Stack>

			{/* Subscription list section begins */}
			{userSubs[0] ? (
				<Grid container rowSpacing={4} columnSpacing={2} className=''>
					{' '}
					{userSubs.map((s) => {
						return (
							<Grid
								item
								xs={12}
								md={4}
								lg={3}
								className='flex items-stretch '
								key={s.id}>
								<ProductDisplay
									price={findPriceObject(s)?.unit_amount / 100}
									title={findPriceObject(s)?.product.name}
									description={
										findPriceObject(s)?.product.description ||
										'this is a fantastic Jane Rothe Product'
									}
									icon={
										serviceIcons.icons[
											findPriceObject(s)?.product.metadata.icon
										]
									}
									className='border-2 border-stone-400 rounded-lg '
									key={s.id}
									id={s.id}
									subtitle={findPriceObject(s)?.nickname}
									iconColor={
										s.cancel_at_period_end ? 'bg-gray-400' : 'bg-secondary'
									}
									actionColor={
										s.cancel_at_period_end ? 'rgb(156 163 175)' : 'tertiary'
									}
									toggleDrawer={toggleDrawer}
									setLoading={setLoading}
									cancel={s.cancel_at_period_end}
								/>
							</Grid>
						);
					})}
				</Grid>
			) : (
				<Stack
					direction='column'
					alignItems='center'
					spacing={2}
					className=' my-10 mb-24  '>
					<div className='font-mono text-sm iphone:text-base md:text-lg italic'>
						Let's start building! Hit the plus button to add a service...{' '}
					</div>
					<div className='w-3/4 lg:w-2/4'>
						<img
							className='w-full h-full mx-auto '
							src={startBuilding}
							alt='Rocket Illustration'
						/>
					</div>
				</Stack>
			)}

			<SwipeableDrawer
				anchor='bottom'
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}>
				<Container
					maxWidth='xl'
					className='pt-5 pb-5 h-full'
					sx={{ maxHeight: '75vh' }}>
					<div className='flex justify-center mb-14'>
						<span className='text-2xl iphone:text-3xl font-serif text-zinc-600 font-bold'>
							Available Services:
						</span>
					</div>
					<Grid container rowSpacing={4} columnSpacing={2}>
						{' '}
						{filteredPrices.map((p) => {
							return (
								<Grid
									item
									xs={12}
									md={4}
									lg={3}
									className='flex items-stretch '
									key={p.id}>
									<ProductPreview
										price={p.unit_amount / 100}
										title={p.product.name}
										subtitle={p.nickname}
										description={
											p.product.description ||
											'this is an awesome Jane Rothe Product'
										}
										icon={serviceIcons.icons[p.product.metadata.icon]}
										className={`border-2  ${
											p.product.name === 'The Gold Package'
												? 'border-tertiary border-4'
												: 'border-stone-400'
										} rounded-md `}
										key={p.id}
										id={p.id}
										iconColor={
											p.product.name === 'The Gold Package'
												? 'bg-tertiary'
												: 'bg-primary'
										}
										actionColor={
											p.product.name === 'The Gold Package'
												? 'tertiary'
												: 'quarternary'
										}
										toggleDrawer={toggleDrawer}
										setLoading={setLoading}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Container>
			</SwipeableDrawer>
			<ConfirmationDialog
				title='Subscription Status'
				handleClose={handleDialogClose}
				open={dialogOpen}
				text={message}
				close='Got It'
			/>
		</Container>
	);
};

export default MyServices;
