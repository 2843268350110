import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';

const MiniCard = ({ image, title, value }) => {
	return (
		<React.Fragment>
			<li>
				<div className="dashboard_service_box flex p-3 w-full  justify-between items-center">
					<div className="dashboard_service_name">
						<p className="text-lg text-center text-gray-400">{title}</p>
						<span className="text-lg text-center text-gray-900">{value}</span>
					</div>
					<div className="dashboard_service_name">
						<img
							className="side_menu_icon w-auto h-auto shadow-lg p-3 bg-clientBlue"
							src={image}
							alt="sideMenu1"
						/>
					</div>
				</div>
			</li>
		</React.Fragment>
	);
};

export default MiniCard;
