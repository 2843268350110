/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline';

export default function SectionHeader({ snippet, title, body }) {
	return (
		<div className="py-12 bg-white">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="text-center">
					<h2 className="text-base text-tertiary font-semibold tracking-wide uppercase">{snippet}</h2>
					<p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
						{title}
					</p>
					<p className="mt-4 max-w-2xl text-lg sm:text-xl text-gray-500 lg:mx-auto"> {body}</p>
				</div>
			</div>
		</div>
	);
}
