import React, { useEffect, useState } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Homemenu from '../../helpers/home.js';
import { Typography, Button, Container, Grid, Box } from '@material-ui/core';
import { Stack } from '@material-ui/core';
import { getUserSubs, getUser } from '../../store/user.js';
import { getPrices, stripeSubs } from '../../store/price.js';
import SublyApi from '../../helpers/Api';
import {
	groupHoverColorVariants,
	bgGroupHoverColorVariants,
	bgColorVariants,
	bgFadedColorVariants,
	textColorVariants,
} from '../../helpers/helperFunctions.js';

import VerifyModal from './VerifyModal';
import 'fontsource-roboto';

import './Home.css';

import { useFormPackage } from '../../hooks/useFormPackage.js';
import { useTypeformResponses } from '../../hooks/useTypeformResponses.js';

import Forms from '../../helpers/forms.js';

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const Home = () => {
	/************ Redux definitions ******/

	const dispatch = useDispatch();

	const token = useSelector((st) => st.user.token);
	const currentUser = useSelector((st) => st.user.currentUser);
	const stripeSubs = useSelector((st) => st.user.stripeSubs);

	const history = useHistory();

	/********* Other Hook definitions **********/

	// This constant holds the appropriate form package depending on the user's subsription.
	const formPackage = useFormPackage(stripeSubs, Forms);

	//this hook will get the typeform responses based on the user's form package.
	useTypeformResponses(formPackage, currentUser, token, dispatch);

	/********* useState hook definitions **********/

	// This constant creates the initial access state for each menu item using the reduce method.
	const INITIAL_ACCESS_STATE = Homemenu.menus.reduce((acc, menu) => {
		// For each menu item, add a new property to the accumulator object `acc`.
		// The property key will be the menu item's accessKey, and the value will be the menu item's initialAccess.
		acc[menu.accessKey] = menu.initialAccess;

		// Return the updated accumulator object `acc` for the next iteration or the final result.
		return acc;

		// The initial value of the accumulator object `acc` is an empty object.
	}, {});

	//controls user access to modules on the home page.
	const [access, setAccess] = useState(INITIAL_ACCESS_STATE);

	/********* useEffect hook definitions **********/

	// gets user subscriptions from stripe immediately
	useEffect(() => {
		if (currentUser.customerId && token) {
			const customerId = currentUser.customerId;
			dispatch(getUserSubs({ customerId, token }));
		}
	}, [token]);

	//upon loading, this will retrieve all products directly from stripe.
	useEffect(() => {
		if (token) {
			dispatch(getPrices({ token }));
		}
	}, [token]);

	// This useEffect hook will run whenever the `stripeSubs` state changes.
	useEffect(() => {
		// For each menu item in the `Homemenu.menus` array, perform the following operations.
		Homemenu.menus.forEach((menu) => {
			// Check if the menu item has any triggerSubscriptions.
			if (menu.triggerSubscriptions.length) {
				// Determine if the user has any of the required subscriptions to access the menu item.
				const hasSubscription = menu.triggerSubscriptions.some((subName) =>
					stripeSubs.some((s) => s.productName?.includes(subName))
				);
				// If the user has any of the required subscriptions, update their access state.
				if (hasSubscription) {
					// Call the `setAccess` function to update the access state.
					setAccess((access) => {
						// Create a new object by spreading the current access state and updating
						// the value for the current menu's accessKey to true.
						return { ...access, [menu.accessKey]: true };
					});
				}
			}
		});
		// Add `stripeSubs` to the dependency array so that the hook runs whenever it changes.
	}, [stripeSubs]);

	/** when token has been updated, new user credentials are stored into redux
	 * Note: Unsure about the purpose of this useEffect. I believe it's for when the user authenticates email.
	 */

	useEffect(() => {
		if (currentUser?.username)
			dispatch(getUser({ username: currentUser.username, token }));
	}, []);

	return (
		<React.Fragment>
			<div data-testid='home' className='home_wrapper  pb-20'>
				<div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8  pb-5 text-center'>
					<div className='main_heading'>
						<Stack
							direction='row'
							justifyContent='center'
							spacing={2}
							className=' my-10'>
							<Box
								component='span'
								className=' font-sans font-extrabold text-zinc-600 text-3xl md:text-5xl p-4'>
								Home
							</Box>
						</Stack>
					</div>
					<VerifyModal />
					{/*Main home menu start*/}
					<div className='menu_wrapper mt-12 '>
						{/* <Box sx={{ flexGrow: 1 }}> */}
						<Grid
							className='menu_card_wrapper sm:justify-start justify-center'
							container
							spacing={4}>
							{/*My Services menu start*/}
							{Homemenu.menus.map((menu, index) => {
								// Use a variable to simplify the access checking
								const hasAccess = access[menu.accessKey];

								const Component = hasAccess ? Link : 'div';
								// Get the Icon components from the menu
								return (
									<Grid
										item
										lg={3}
										key={index}
										className='group'
										onClick={() => {
											if (!hasAccess) {
												alert('Sorry! You are not subscribed to this service');
											}
										}}>
										<div className='Menu_box text-center w-52 lg:w-52 md:w-52 sm:w-44 '>
											<Component to={hasAccess ? menu.url : undefined}>
												<div
													className={`rounded-3xl shadow border text-center py-10 lg:py-10 md:py-10 sm:py-8 px-5 menu_box  `}>
													<Typography
														variant='span'
														className={`${
															bgFadedColorVariants[menu.hovercolor]
														}  ${bgGroupHoverColorVariants[menu.hovercolor]} `}>
														{menu.image({
															className: `w-auto h-auto p-2 ${
																textColorVariants[menu.hovercolor]
															} ${
																textColorVariants[menu.hovercolor]
															} group-hover:text-white `,
															testId: menu.url,
														})}
													</Typography>
													<p
														className={`pt-4 text-lg lg:text-lg md:text-lg sm:text-base text-center text-gray-400 w-full whitespace-nowrap overflow-hidden text-ellipsis ${
															groupHoverColorVariants[menu.hovercolor]
														}`}>
														{menu.name}
													</p>
												</div>
											</Component>
										</div>
									</Grid>
								);
							})}
						</Grid>
						{/* </Box> */}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Home;
