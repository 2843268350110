import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './Stripe.css';
import axios from 'axios';

//figure out where is the the react app based url stored. It worked for subly but I don't know where it is.
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3001';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	'pk_test_51H3oiCLfG1oim3wflKZ4nTwWTbpSAJI2ymHfwy63Cdn91ep5EKcKFhpu0y88C2oNP0aoQTf59g26GBBQHTKTJHWx004Wda9b31'
);

const ProductDisplay = ({ handleClick }) => (
	<section>
		<div className="product">
			<img src="https://i.imgur.com/EHyR2nP.png" alt="The cover of Stubborn Attachments" />
			<div className="description">
				<h3>Stubborn Attachments</h3>
				<h5>$20.00</h5>
			</div>
		</div>
		<button type="button" id="checkout-button" role="link" onClick={handleClick}>
			Checkout
		</button>
	</section>
);

const Message = ({ message }) => (
	<section>
		<p>{message}</p>
	</section>
);

export default function App() {
	const [ message, setMessage ] = useState('');

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search);

		if (query.get('success')) {
			setMessage('Order placed! You will receive an email confirmation.');
		}

		if (query.get('canceled')) {
			setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
		}
	}, []);

	const handleClick = async (event) => {
		console.log('i clicked it');
		const stripe = await stripePromise;

		// const response = await fetch('/create-checkout-session', {
		// 	method : 'POST'
		// });

		const response = await axios.post(`${BASE_URL}/stripe/create-checkout-session`, {
			priceId : 'price_1IxaiBLfG1oim3wftxu7ZG0a'
		});

		console.log(response);

		const sessionId = response.data.sessionId;

		// When the customer clicks on the button, redirect them to Checkout.
		const result = await stripe.redirectToCheckout({
			sessionId : sessionId
		});

		if (result.error) {
			// If `redirectToCheckout` fails due to a browser or network
			// error, display the localized error message to your customer
			// using `result.error.message`.
		}
	};

	return message ? <Message message={message} /> : <ProductDisplay handleClick={handleClick} />;
}
