import React, { useState, useEffect } from 'react';
import './Settings.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, updateImages } from '../../store/user.js';
import loaderimage from '../../img/gif/loading-arrow.gif';
import { success, clearSuccess } from '../../actions/success';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const ImageUpload = () => {
	/************ Redux definitions ******/
	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);
	const loading = useSelector((st) => st.user.loading);

	const dispatch = useDispatch();

	/********* useState hook definitions **********/

	const INITIAL_STATE = {
		logo: '',
		headshot: ''
	};

	// sets state for logo image URL
	const [logo, setLogo] = useState(null);

	// sets state for headshot image URL
	const [headshot, setHeadshot] = useState(null);

	// sets state for image form data. i.e. name of the file
	const [formData, setFormData] = useState(INITIAL_STATE);

	// sets state for upload error. Holds client side error message.
	const [imageErr, setImageErr] = useState(INITIAL_STATE);

	//sets state for image loader. Toggles to true when user uploads file. Back to false when image url is retrieved.
	const [imageLoader, setImageLoader] = useState({
		logo: false,
		headshot: false
	});

	/**************** useEffect hook definitions ************/

	// retrieves logo and headhot URLs from store on load and every time currentuser changes.
	useEffect(
		() => {
			setLogo(currentUser.logo);
			setHeadshot(currentUser.headshot);
		},
		[currentUser]
	);

	//clear image loader and success state in redux whenever we get a successful api call.
	useEffect(
		() => {
			if (success) {
				setImageLoader({ logo: false, headshot: false });
				dispatch(clearSuccess());
			}
		},
		[success]
	);

	/******* Helper Functions ********/

	/**
   * handles change in file form
   *
   * Validates that the file is not too large
   *
   * Takes BLOB data and sends it to API. Data is sent to AWS S3 and returned a URL.
   *
   * URL is stored in db and retrieved in redux.
   */
	const handleChange = async (e) => {
		if (validateSize(e)) {
			const { name, value, files } = e.target;

			//resets image error if there were any previously.
			setImageErr((imageErr) => ({ ...imageErr, [name]: '' }));

			//sets image loader while URL is retrieved.
			setImageLoader((imageLoader) => ({ ...imageLoader, [name]: true }));

			//control form data by accessing file name
			setFormData((formData) => ({ ...formData, [name]: value }));

			// dispatch binary file data to API
			const form = new FormData();
			form.append(name, files[0]);
			dispatch(
				updateImages({
					username: currentUser.username,
					data: form,
					token: token,
				})
			);
		}
	};

	/**
   * removes image data from form
   *
   * resets reset user property for that image in state to nothing.
   */
	const removeImage = (image) => {
		setFormData((formData) => ({ ...formData, [image]: '' }));

		dispatch(
			updateUser({ username: currentUser.username, data: { [image]: "" }, token: token })
		);
	};

	/**
   * Validates the size of an image to not be any larger that 1MG
   *
   * Sets error message & returns false if file is too large.
   */

	const validateSize = (e) => {
		const { name, files } = e.target;
		const file = files[0];
		const size = 1024 * 1024;

		if (file.size > size) {
			const err = file.type + 'is too large, please pick a smaller file\n';
			setImageErr((imageErr) => ({ ...imageErr, [name]: err }));
			return false;
		}
		return true;
	};

	return (
		<div className={`${imageErr.logo && imageErr.headshot && "error_display_manage"} upload_wrapper`}>
			<div className='relative w-full logo_upload_block'>
				<form className={`${imageErr.logo && "bottom_margin"} upload_box`} encType="multipart/form-data">
					<label htmlFor="logo" className=" text-quarternary customFileUpload ">
						Upload New Logo
					</label>
					<input
						type="file"
						name="logo"
						id="logo"
						accept="image/png, image/gif, image/jpeg"
						onChange={(e) => {
							handleChange(e);
						}}
					/>

					<div className="image_box">
						{imageLoader.logo && loading && (
							<CircularProgress
								sx={{
									position: 'absolute',
									left: '0px',
									right: 0,
									margin: 'auto',
									top: 0,
									bottom: 0,
									zIndex: 10
								}}
								color="primary"
							/>
						)}
						{logo && <img className="border-2 drop-shadow-xl" src={logo} alt="Thumb" />}
					</div>
					{logo && (
						<button type="button" onClick={() => removeImage('logo')}>
							Remove
						</button>
					)}

				</form>
				{/* error message */}

				{imageErr.logo && (
					<div
						className={`${imageErr.logo && "mt-20 logo_alert_responsive"
							} logo_error_alert`}>
						<Alert
							className="mt-0 p-0 absolute top-32 left-0 z-10 w-fit"
							severity="error"
							onClose={() => {
								setImageErr((imageErr) => ({ ...imageErr, logo: "" }));
							}}>
							{imageErr.logo}
						</Alert>
					</div>
				)}
			</div>



			{/* Headshot Form  */}



			<div className='relative w-full headShot_upload_block'>
				<form className={`${imageErr.headshot && "bottom_margin"} upload_box upload_box2`} encType="multipart/form-data">
					<label htmlFor="headshot" className="text-quarternary customFileUpload customHeadshotUpload">
						Upload New Headshot
					</label>
					<input
						type="file"
						name="headshot"
						id="headshot"
						onChange={handleChange}
						accept="image/png, image/gif, image/jpeg"
					/>
					<div className="image_box">
						{imageLoader.headshot && loading && (
							<CircularProgress
								sx={{
									position: 'absolute',
									left: '0px',
									right: 0,
									margin: 'auto',
									top: 0,
									bottom: 0,
									zIndex: 10
								}}
								color="primary"
							/>
						)}
						{headshot && (
							<img
								className="border-2 drop-shadow-xl"
								src={headshot}
								alt="Thumb"
							/>
						)}
					</div>
					{headshot && (
						<button type="button" onClick={() => removeImage("headshot")}>
							Remove
						</button>
					)}

				</form>
				{/* Error Message */}

				{imageErr.headshot &&
					<div
						className={`${imageErr.headshot && "mt-20 headshot_alert_responsive"
							} headshot_error_alert`}>

						<Alert
							className="mt-0 p-0 absolute top-32 right-0 z-10 w-fit"
							severity="error"
							onClose={() => {
								setImageErr((imageErr) => ({ ...imageErr, headshot: "" }));
							}}>
							{imageErr.headshot}
						</Alert>

					</div>
				}
			</div>
		</div>
	);
};

export default ImageUpload;
