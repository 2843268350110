import { useEffect } from 'react';
import { getTypeforms } from '../store/user';

/**
 * Custom React hook to obtain typeform responses.
 *
 * @param {Array} formPackage - An array of objects that contains information about the typeforms to fetch responses for.
 * @param {Object} currentUser - An object that represents the current user.
 * @param {string} token - A string that represents the authentication token.
 * @param {function} dispatch - A function used to dispatch actions to the Redux store.
 * @returns {void}
 */
export function useTypeformResponses(
	formPackage,
	currentUser,
	token,
	dispatch
) {
	useEffect(() => {
		if (formPackage.length)
			dispatch(
				getTypeforms({ username: currentUser.username, token, formPackage })
			);
	}, [formPackage]);
}
