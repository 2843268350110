import { Facebook, GTranslate, Instagram, WebOutlined, mailOutlined } from '@material-ui/icons';
import {
	CloudUploadIcon,
	CogIcon,
	LockClosedIcon,
	RefreshIcon,
	ServerIcon,
	ShieldCheckIcon,
	CheckCircleIcon,
	MailIcon,
	AtSymbolIcon
} from '@heroicons/react/outline';

import { FireIcon } from '@heroicons/react/solid';

class serviceList {
	static services = [
		{
			name      : 'Google Ads Management',
			icon      : GTranslate,
			price     : 175,
			features  : [
				'Google PPC & Local Serice Ads Management',
				'Google Ads Account Setup',
				'Ongoing Ad Optimization',
				'​​Keyword Research',
				'Manual Bidding',
				'Geo-Targeting',
				'Search Term Audit',
				'Selection of Keyword Match Types',
				'Conversion Tracking ',
				'Service-Category Targeting',
				'Ad Scheduling'
			],
			extra     : '+Ad Spend',
			border    : 'border',
			userCount : 105
		},
		{
			name      : 'Facebook & Instagram Ads',
			icon      : Facebook,
			price     : 125,
			features  : [
				'Advertising Software Setup & Maintenance',
				'Facebook & Instagram Retargeting Ad Campaign',
				'Geographic Targeting & Custom Audience Remarketing',
				'Ongoing Ad Optimization',
				'Monthly Reporting (as Requested)',
				'Ad Scheduling'
			],
			extra     : '+Ad Spend',
			border    : 'border',
			userCount : 15
		},
		{
			name      : 'Broad Online Advertising',
			icon      : WebOutlined,
			features  : [
				'Strategically Placed Internet Ads',
				'Banner Advertising',
				'Mobile Advertising',
				'Target Your Sphere of Influence',
				'Target Lookalike Audiences',
				'Target Geo Specific Audiences',
				'Personalized Designs',
				'Software Setup & Management',
				'Ad Optimization'
			],
			price     : 125,
			extra     : '+Ad Spend',
			border    : 'border',
			userCount : 165
		},
		{
			name      : 'Social Media Marketing',
			icon      : Instagram,
			price     : 395,
			features  : [
				'Brand Building',
				'Increase Following',
				'Increase Engagement',
				'Increase Traffic',
				'Personalized Images',
				'Posts 3x week'
			],
			chip      : true,
			border    : 'border',
			userCount : 169
		},
		{
			name      : 'Email Marketing',
			icon      : AtSymbolIcon,
			features  : [
				'24 Emails',
				'Send to Database 1-2x a Month',
				'Option to Send up to 4x a Month',
				'Branded Email Templates',
				'Software Setup & Management'
			],
			price     : 100,
			border    : 'border',
			userCount : 57
		},
		{
			name      : 'Listing & Post-Close Execution',
			icon      : RefreshIcon,
			features  : [
				'Listing - We help you market your listings and provide you material that impresses your clients',
				'Closing - We manage multiple closing tasks, including reputation management and client nurturing.'
			],
			price     : 395,
			// chip     : <Chip label="More Details on This" variant="outlined" onClick={handleClick} />,
			chip      : true,
			border    : 'border',
			userCount : 62
		},

		{
			name      : 'Direct Mail Marketing',
			icon      : MailIcon,
			features  : [
				'Graphic Design',
				'Campaign Management',
				'Hard Mailers 12, 6, or 4x Yearly',
				'Mailers Sent to Database'
			],
			price     : 95,
			border    : 'border',
			userCount : 10
		},
		{
			name      : 'Website Management',
			icon      : ServerIcon,
			features  : [
				'Set Up (if needed)',
				'Co-manage',
				'Add Content 2x Monthly',
				'Edits per Request or as Needed'
			],
			price     : 175,
			border    : 'border',
			userCount : 148
		},
		{
			name      : 'The works',
			icon      : FireIcon,
			features  : [ 'The Entire Package', 'Co-manage CRM', 'Business Book', 'Sellers Guide', 'Buyers Guide' ],
			price     : 800,
			extra     : '+Hard Costs',
			chip      : true,
			border    : 'border-2 border-primary',
			userCount : 451
		}
	];
}

export default serviceList;
