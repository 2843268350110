import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import 'fontsource-roboto';
import './Client.css';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SublyApi from '../../helpers/Api.js';
import { updateUser, clearSuccess, clearErrors, getAllUsers } from '../../store/user.js';
import { useDispatch, useSelector } from 'react-redux';

const ClientBrokerForm = ({ user, setEdit }) => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();

	const token = useSelector((st) => st.admin.token);
	// const token = SublyApi.adminToken;

	/********* useParams hook definitions **********/
	const { username } = useParams(); //here i used useparams for passing the username through Routing

	/********* other hook definitions **********/
	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors }
	} = useForm({
		defaultValues: {
			// here i set the default values in inpur field as a value
			brokerName: user.brokerName || '',
			brokerEmail: user.brokerEmail || '',
			brokerAddressOne: user.brokerAddressOne || '',
			brokerAddressTwo: user.brokerAddressTwo || '',
			brokerCity: user.brokerCity || '',
			brokerState: user.brokerState || '',
			brokerZip: user.brokerZip || '',
			brokerLicense: user.brokerLicense || ''
		}
	});

	/******* Helper Functions ********/

	//fetching the form data and passing to the API
	const onSubmit = async (formData) => {
		SublyApi.updateUser(username, formData, token);
		dispatch(getAllUsers({ token }));

		setEdit(false); //state for after click on save redirect to previous page
	};
	return (
		<React.Fragment>
			<div>
				<Stack
					spacing={{ xs: 1, iphone: 2 }}
					component="form"
					className="px-3"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Grid className="edit_info_spacing " container spacing={4}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Name</label>
								<TextField
									className="w-full"
									id="brokerName"
									type="text"
									name="brokerName"
									{...register('brokerName')}
								/>
							</Typography>
						</Grid>

						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Email</label>
								<TextField
									className="w-full"
									id="brokerEmail"
									type="email"
									name="brokerEmail"
									{...register('brokerEmail')}
								/>
							</Typography>
						</Grid>

						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Broker Address One</label>
								<TextField
									className="w-full"
									id="brokerAddressOne"
									type="text"
									name="brokerAddressOne"
									{...register('brokerAddressOne')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Broker Address Two</label>
								<TextField
									className="w-full"
									id="brokerAddressTwo"
									type="text"
									name="brokerAddressTwo"
									{...register('brokerAddressTwo')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Broker City</label>
								<TextField
									className="w-full"
									id="brokerCity"
									type="text"
									name="brokerCity"
									{...register('brokerCity')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Broker State</label>
								<TextField
									className="w-full"
									id="brokerState"
									type="text"
									name="brokerState"
									{...register('brokerState')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">Broker Zip</label>
								<TextField
									className="w-full"
									id="brokerZip"
									type="text"
									name="brokerZip"
									{...register('brokerZip')}
								/>
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography className="p-0 inline">
								<label className="leading-9 text-gray-900 block">License #</label>
								<TextField
									className="w-full"
									id="brokerLicense"
									type="text"
									name="brokerLicense"
									{...register('brokerLicense')}
								/>
							</Typography>
						</Grid>
					</Grid>

					<Box className="flex justify-end">
						<Button
							className="h-14 Client_btn client_cancel_btn text-white w-10 px-4"
							type="button"
							onClick={() => setEdit(false)}
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Cancel
						</Button>
						<Button
							className="h-14 Client_btn text-white w-10 px-3 bg-clientBlue ml-4"
							type="submit"
							sx={{
								fontWeight: 600,
								fontSize: { xs: '1rem', iphone: '1.125rem' },
								lineHeight: { xs: '1.5rem', iphone: '1.75rem' }
							}}
						>
							Save
						</Button>
					</Box>
				</Stack>
			</div>
		</React.Fragment>
	);
};

export default ClientBrokerForm;
