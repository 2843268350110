import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
// import { clearErr, showErr } from "../actions/errors";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import JRLogo from '../img/JR_Logo.svg';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SublyApi from '../helpers/Api';
import Timer from '../helpers/Timer';
import './ResetPassword.css';
import './Login.css';
import { useForm } from 'react-hook-form';
import { addErrors, clearErrors } from '../store/user';

/** Form for logging user in */
const ResetPassword = () => {
	/************ Redux definitions ******/
	const INITIAL_STATE = {
		password1: '',
		password2: '',
	};

	const { username, code } = useParams();
	const formRef = useRef();
	const history = useHistory();
	const error = useSelector((st) => st.user.errors);
	const dispatch = useDispatch();

	/********* useState hook definitions **********/

	// state for the timer on modal
	const [seconds, setSeconds] = useState(15);

	const [successMessage, setSuccessMessage] = useState('');

	// sets state for object for error messages
	const [formErrorMessage, setFormErrorMessage] = useState(INITIAL_STATE);

	// sets state for error trigger. Triggers when user submits. Errors will trigger if there are any.
	const [errorTrigger, setErrorTrigger] = useState(false);

	const [open, setOpen] = useState(false); // state for confirmation modal

	//sets toggle for showing and hiding password
	const [showPassword, setShowPassword] = useState(false);

	// sets state for form data and the kind of menu, which can be toggled.
	const [formData, setFormData] = useState(INITIAL_STATE);

	/********* useEffect hook definitions **********/

	// sets error to null when the component first mounts and when it dismounts.
	useEffect(() => {
		return () => {
			dispatch(clearErrors()); // dismount
		};
	}, []);

	/** forwards the user to signin page once the count down on modal reaches 0 */
	useEffect(() => {
		if (seconds <= 0) history.push('/login');
	}, [seconds]);

	/********* other hook definitions **********/

	const {
		register,
		handleSubmit, //using hooks useform for validations and sending the input values to API
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			// here i set the default values in inpur field as a value
			password1: '',
			password2: '',
		},
	});

	/**************** Helper Functions ******************/

	const onSubmit = async (formData) => {
		try {
			if (formData.password1 != formData.password2)
				throw ["Your passwords don't match. Try again"];
			const { message } = await SublyApi.changePassword(
				username,
				{ password: formData.password1 },
				code
			);

			if (!!message) {
				setSuccessMessage(message);
				setOpen(true);
			}

			reset();
		} catch (err) {
			dispatch(addErrors(err));
		}
	};

	//handles the closing of the confirmation modal
	const handleClose = () => {
		setOpen(false);
		history.push('/login');
	};

	//handles show password function for the password input field.
	const handleClickShowPassword = () => {
		setShowPassword((showPassword) => {
			return !showPassword;
		});
	};

	const passwordPattern =
		/(?=.*\d)(?=.*[!@#$%^&*]+)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

	return (
		<Box className=' h-screen  bg-real-estate flex items-center justify-center relative z-0'>
			<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: 'center', display: 'flex' }}
				maxWidth='md'
				className=''>
				<Stack spacing={4} className='w-full iphone:w-11/12 sm:w-2/3 md:w-7/12'>
					<Stack
						spacing={4}
						className='rounded-2xl pb-6  px-5 bg-white z-10 pt-0'>
						<Stack
							spacing={1}
							className='py-8 rounded-2xl -mt-12 bg-secondary  shadow-2xl'>
							<img
								className='h-12 iphone:h-16 w-auto m-0'
								src={JRLogo}
								alt='Jane Logo'
							/>
							<div className='text-white text-center text-xl md:text-3xl font-bold'>
								Reset Password
							</div>
						</Stack>
						{/* Form starts here */}
						<Stack
							spacing={{ xs: 2 }}
							component='form'
							onSubmit={handleSubmit(onSubmit)}
							className='px-3 '
							noValidate
							ref={formRef}>
							<TextField
								{...register('password1', {
									required: 'password is required',
									minLength: {
										value: 8,
										message: 'Password must be at least 8 characters',
									},
									maxLength: {
										value: 50,
										message: 'Password must be no more than 30 characters',
									},
									pattern: {
										value: passwordPattern,
										message:
											'password must include at least one number, one lowercase letter, one upper case letter, and one special character from this set `!@#$%^&*`',
									},
								})}
								error={!!errors.password1}
								helperText={errors.password1?.message}
								autoFocus={true}
								id='password1'
								label='Enter Password'
								variant='standard'
								type={showPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								inputProps={{
									'aria-label': 'Enter Password',
								}}
								name='password1'
							/>

							<TextField
								{...register('password2', {
									required: 'password is required',
									minLength: {
										value: 8,
										message: 'Password must be at least 8 characters',
									},
									maxLength: {
										value: 50,
										message: 'Password must be no more than 30 characters',
									},
									pattern: {
										value: passwordPattern,
										message:
											'password must include at least one number, one lowercase letter, one upper case letter, and one special character from this set `!@#$%^&*`',
									},
								})}
								error={!!errors.password2}
								helperText={errors.password2?.message}
								id='password2'
								label='Re-enter Password'
								variant='standard'
								type={showPassword ? 'text' : 'password'}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: '1rem', md: '1.25rem' },
										lineHeight: { xs: '1.25rem', md: '1.5rem' },
									},
								}}
								inputProps={{
									'aria-label': 'Re-enter Password',
								}}
								name='password2'
							/>

							<div className='text-red-900 mb-3 '>
								{error.map((e, indx) => (
									<div key={indx}>
										<small>ERROR: {e}</small>
									</div>
								))}
							</div>

							<Box className='flex justify-center'>
								<Button
									type='submit'
									sx={{
										fontWeight: 600,
										fontSize: { xs: '1rem', sm: '1.125rem' },
										lineHeight: { xs: '1.5rem', sm: '1.75rem' },
									}}
									color='secondary'>
									Reset
								</Button>
							</Box>
						</Stack>
					</Stack>
					<div className=' flex justify-center font-semibold sm:font-bold sm:text-lg md:text-xl text-white py-2 z-10'>
						<div>
							<div />
							<NavLink
								exact
								to='/login'
								className=' underline hover:text-primary block text-center'>
								Back to login
							</NavLink>{' '}
						</div>
					</div>
				</Stack>
			</Container>
			<Dialog
				open={open}
				onClose={handleClose}
				onBackdropClick={handleClose}
				maxWidth='sm'
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				disableEscapeKeyDown
				className='reset_password_wrapper'>
				<DialogTitle id='alert-dialog-title'>{successMessage}</DialogTitle>
				<DialogContent className='reset_password_content'>
					<DialogContentText id='alert-dialog-description'>
						You can now enter the app from the login page. We will re-route you
						in:{' '}
					</DialogContentText>
					<div className='email_timer'>
						<div className='text-6xl font-bold text-white	bg-black rounded-full mt-8 p-4 w-24 m-auto '>
							{<Timer seconds={seconds} setSeconds={setSeconds} />}
						</div>
						<p className='pt-2 pb-6 text-lg font-bold text-gray-700 leading-8'>
							{' '}
							Seconds
						</p>
					</div>
				</DialogContent>
				<DialogActions className='reset_btn'>
					<NavLink exact to='/login' color='green'>
						<Button onClick={handleClose} autoFocus>
							Take me there
						</Button>
					</NavLink>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ResetPassword;
