/** @format */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'fontsource-roboto';
import { Grid, Box, TextField, Typography } from '@material-ui/core';
import Sidebar from '../sidebar/Sidebar';
import clientHelper from '../../adminHelper/clientList';
import SearchIcon from '@mui/icons-material/Search';
import './Client.css';
import SublyApi from '../../helpers/Api';
import JRLogo from '../../img/JR_Logo.svg';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers } from '../../store/user';

/** component for default screen. It shows sign in buttons when user is signed out.  Shows a welcome message when user is logged in */
const ClientList = () => {
	/************ Redux definitions ******/
	const dispatch = useDispatch();
	const users = useSelector((st) => st.user.allUsers);
	const token = useSelector((st) => st.admin.token);

	/********* useState hook definitions **********/
	const [clientList, setClientList] = useState(users);

	const [searchFor, setSearchFor] = useState('');

	// useEffect(async () => {
	// 	setClientList(await SublyApi.getUsers(SublyApi.adminToken));
	// }, []);

	// gets user list immediately
	useEffect(
		() => {
			if (token) {
				dispatch(getAllUsers({ token }));
			}
		},
		[token]
	);

	// update user list immediately
	useEffect(() => {
		setClientList(users)
	}, [users])

	return (
		<React.Fragment>
			<div className="container w-full max-w-full relative client_list_container">
				<div className="dashboard_row">
					<div className="dashboard_col dashboard_col1">
						<Sidebar />
					</div>

					<div className="dashboard_col2 " >
						<div className="mt-16 mb-5">
							<div className="dashboard_wrapper client_list_page relative lg:ml-5 ml:0">
								<Typography className="search_client p-0 inline">
									<TextField
										className="w-80"
										required
										id="search"
										type="text"
										name="search"
										placeholder="Search By Name"
										onChange={(e) => {
											setSearchFor(e.target.value);
										}}
									/>
									<span>
										{' '}
										<SearchIcon />{' '}
									</span>
								</Typography>
							</div>
							<Box className="client_info_grid load_more_btn lg:ml-5 ml:0" >
								<Grid container spacing={4}>
									{clientList ? (
										clientList.filter(client => {
											if (searchFor === '') {
												return client;
											} else {
												if (
													(client.firstName + ' ' + client.lastName).trim().toLowerCase().match(searchFor.toLowerCase().trim())
													|| client.firstName.toLowerCase().match(searchFor.toLowerCase().trim())
													|| client.email.toLowerCase().match(searchFor.toLowerCase().trim())
													|| client.lastName.toLowerCase().match(searchFor.toLowerCase().trim())) {
													return client;
												}
											}
										}).map((value, index) => (
											<Grid item xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
												<div className="client_info_box text-center client_headshot">
													<span>
														<img
															className="w-full h-full rounded-full"
															src={!value.headshot ? JRLogo : value.headshot}
															alt="client"
														/>
													</span>
													<h5 className="mt-6 font-medium text-black text-lg opacity-100">
														{value.firstName} {value.lastName}
													</h5>
													<p className="mt-0 mb-5 font-normal text-gray-500 text-lg opacity-100">
														{value.email}
													</p>
													<Link
														to={`/admin/clients/client-details/${value.username}`}
														className="client_view_detail font-medium opacity-100 text-white py-2 px-4 rounded-lg bg-clientBlue"
													>
														VIEW DETAILS
													</Link>
												</div>
											</Grid>
										))
									) : (
										''
									)}
								</Grid>
								<Button type="button">load more</Button>
							</Box>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ClientList;
