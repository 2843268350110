import "./Nav.css";
import NavGroup from "./NavGroup";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import "fontsource-roboto";
import JRLogo from "../img/JR_Logo.svg";

import React, { useEffect, useContext } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
// import zIndex from '@mui/material/styles/zIndex';

/** Nav bar for the App.  It changes depending on whether user is logged in */
function Nav() {
	/** Get Current user from redux instead of useContext */
	const currentUser = useSelector((st) => st.user.currentUser);
	const currentAdmin = useSelector((st) => st.admin.currentAdmin);

	// define links for when a user is signed in
	const signedInLinks = [
		{
			name: "logout",
			icon: <LogoutIcon sx={{ color: "rgb(156 163 175)" }} />,
			path: currentUser && currentUser.username ? "logout" : "admin/login",
			logout: true,
			style:
				"h-9  sm:h-auto inline-block bg-white py-2 px-4 border border-gray-200 rounded-md text-sm iphone:text-sm text-base font-medium pink-700 hover:bg-gray-50 text-gray-500 hover:text-gray-900 shadow-sm",
			// Modify the css thats why the snap of the Nav.test.js.snap and NavGroup.test.js.snap file mismatched and fail the test cases
		},
	];

	// define links for when a user is signed out
	const signedOutLinks = [
		{
			name: "Sign in",
			path: "login",
			style:
				"inline-block bg-white py-2 px-3 border border-gray-200 rounded-md text-xs iphone:text-sm sm:text-base font-medium pink-700 hover:bg-gray-50 text-gray-500 hover:text-gray-900 shadow-sm",
			// Modify the css thats why the snap of the Nav.test.js.snap and NavGroup.test.js.snap file mismatched and fail the test cases
		},
		{
			name: "Sign up",
			path: "signup",
			style:
				"inline-block bg-primary py-2 px-3 border border-transparent rounded-md text-xs iphone:text-sm sm:text-base font-medium text-white hover:bg-opacity-80",
			// Modify the css thats why the snap of the Nav.test.js.snap and NavGroup.test.js.snap file mismatched and fail the test cases
		},
	];
	const links =
		(currentUser && currentUser.username) ||
			(currentAdmin && currentAdmin.username)
			? signedInLinks
			: signedOutLinks;
	return (
		// <div className="top-0 z-10 nav_wrapper block fixed left-0 right-0 top-0 z-50 bg-white shadow">
		<div className="nav_wrapper block bg-white " data-testid="nav-wrapper">
			<div className={`max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 `}>
				<div
					expand="md"
					className={` ${currentUser && currentUser.username ? "py-2 " : "sm:py-6 py-4"
						} w-full flex items-center justify-between `}>
					<NavLink exact to="/" color="green" className="navbar-brand z-40">
						<span className="sr-only">Jane Logo</span>
						<img
							className="h-9 sm:h-12 w-auto m-0 z-40"
							src={JRLogo}
							alt="Jane Logo"
						/>
					</NavLink>
					<NavGroup links={links} className="z-40" />
				</div>
			</div>
		</div>
	);
}

export default Nav;
