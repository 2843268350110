/** @format */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'fontsource-roboto';
import dashboard from '../../adminHelper/dashboard.js';
import Sidebar from '../sidebar/Sidebar';
import DashboardBg from '../../img/admin/DashboardBg.png';
import './Dashboard.css';
import serviceList from '../../adminHelper/serviceList.js';
import { getAllUsers, getAllSubs } from '../../store/user';
import { getPrices } from '../../store/price';
import MiniCard from './MiniCard.js';
import { RecentActors } from '@material-ui/icons';
import SublyApi from '../../helpers/Api';
import ServiceList from './ServiceList.js';

/** component for default admin dashboard */
const Dashboard = () => {
	/************ Redux definitions ******/

	const dispatch = useDispatch();
	const token = useSelector((st) => st.admin.token);
	const prices = useSelector((st) => st.price.allPrices); // stripe product prices
	const users = useSelector((st) => st.user.allUsers);
	const subs = useSelector((st) => st.user.allSubs);

	/********* useState hook definitions **********/
	const [subCount, setSubCount] = useState(null);

	//state for holding a list of subscription objects gotten from stripe
	// const [ subs, setSubs ] = useState([]);

	/********* useEffect hook definitions **********/

	// gets user subscriptions from stripe immediately
	useEffect(
		() => {
			// async function getStripeSubs() {
			// 	const { stripeSubs } = await SublyApi.getAllStripeSubscriptions(token);

			// 	setSubCount((subCount) => stripeSubs.length);
			// 	setSubs((subs) => stripeSubs);
			// }

			if (token) {
				dispatch(getAllUsers({ token }));
				dispatch(getPrices({ token }));
				dispatch(getAllSubs({ token }));
				// getStripeSubs();
			}
		},
		[token]
	);

	return (
		<React.Fragment>
			<div className="container w-full max-w-full relative">
				<div className="dashboard_row">
					<div className="dashboard_col dashboard_col1">
						<Sidebar />
					</div>

					<div className="dashboard_col2">
						<div className="dashboard_col">
							<div className="dashboard_wrapper mt-8 mb-5">
								<div className="dashboard_admin_services">
									<ul className="flex flex-wrap	">
										{/* Client Count*/}
										<MiniCard
											title={dashboard.summary.clients.title}
											value={users.length}
											image={dashboard.summary.clients.image}
										/>
										{/* Service Count */}
										<MiniCard
											title={dashboard.summary.services.title}
											value={prices.length}
											image={dashboard.summary.services.image}
										/>
										{/* Subscription count */}
										<MiniCard
											title={dashboard.summary.subscriptions.title}
											value={subs.length}
											image={dashboard.summary.subscriptions.image}
										/>
									</ul>
								</div>
								{/* Service List */}
								<div className="dashboard_service_list mt-5">
									<h5 className="font-bold text-gray-900 text-base opacity-100">Services List</h5>
									<div className="dashboard_service_list_box mt-5">
										<ul className="dashboard_service_list_head">
											<li className="flex py-3 w-full justify-between items-center">
												<h5>Service Name</h5>
												<h5>Users</h5>
											</li>
										</ul>

										<ul className="dashboard_service_list_info">
											<ServiceList subs={subs} />
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard_col dashboard_grid3">
							<img className="dashboard_globe h-auto mt-8" src={DashboardBg} alt="DashboardBg" />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Dashboard;
