import React, { useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Widget, PopupButton } from '@typeform/embed-react';
import { Stack, Container, Box, Divider, Button, Grid, Link } from '@mui/material';
import Moment from 'react-moment';
import Typography from '@mui/material/Typography';
import Notrecord from '../../img/not_record_found.png';
import Notaccess from '../../img/Not_Access.png';
import './MyForm.css';
/** Tab Panels the correspond the the tabs on the My forms page */
function TabPanel(props) {
	const {
		children,
		value,
		index,
		formPackage,
		tabName,
		typeforms,
		setTabChange,
		tabChange,
		tabNames,
		...other
	} = props;
	//finds a form in the form package that corresponds with the tab name
	const form = formPackage.find((f) => f.name === tabName);
	//this function use for close a loder
	setTimeout(() => {
		setTabChange(false);
	}, 500);
	/** 
	 * * Function for findng a specific answer field in a typeform response
	 * * each form can have multiple responses, each response has multiple answers that show in a list of objects
	 * * answers = [{field,...}, {field,...}] 
	 * * the answer we are looking for will match our ref. If refs match, return the answer, which is on the field type key
	 *  */
	const findAnswer = (answers, ref, fieldType) => {
		const answer = answers.find((a) => a.field.ref === ref);
		return answer ? answer[fieldType] : '';
	};
	return (
		<React.Fragment>
			{/* If there is a form that matches the tab, show the form. Or else show user a 'Sorry!' message */}
			{form ? (
				<div
					role="tabpanel"
					hidden={value !== index}
					id={`simple-tabpanel-${index}`}
					aria-labelledby={`simple-tab-${index}`}
					{...other}
				>
					{/* Button opens typeform */}
					<PopupButton id={formPackage[index].id} style={{ fontSize: 20 }} className=" mb-10">
						<Button variant="contained" color="primary" sx={{ borderRadius: '15%' }}>
							<LaunchIcon sx={{ color: 'rgb(209 213 219)' }} />
						</Button>
					</PopupButton>
					{/* <span className="hidden md:inline text-gray-500 font-semibold ml-3">Open Form</span> */}
					<Box sx={{ flexGrow: 1 }} className="mb-5">
						{/*If there are typeform responses in storage, map through and display some of the information*/}
						{typeforms && typeforms[form.name] && typeforms[form.name].length > 0 ? (
							<Grid container spacing={2}>
								{typeforms[form.name].map((tf, index) => (
									<Grid item lg={4} md={4} sm={6} xs={12} key={index}>
										<div className="Menu_box">
											{/* <p className="pb-4 text-lg text-left text-#122fd7d9-900 font-bold">Provice {index}</p> */}
											<div
												key={index}
												className={`rounded-2xl shadow border text-left py-5 px-5 menu_box relative`}
											>
												{/* <Typography variant="div"> */}
												<Moment format="LL" className="text-#0f30eb-900 font-bold">
													{tf.submitted_at}
												</Moment>
												{/* </Typography> */}
												<p className="pt-4 text-lg text-left text-gray-400">
													{findAnswer(tf.answers, 'client_address', 'text')}
												</p>
												<p className="pt-4 text-lg text-left text-slate-600	  elpis_work 
">
													{' '}
													{findAnswer(tf.answers, 'client_name', 'text')}
												</p>
												<p className="other_border" />
											</div>
										</div>
									</Grid>
								))}
							</Grid>
						) : (
							<div>
								<img src={Notrecord} alt={Notrecord} className="m-auto h-36 w-36" />
								<p className="text-2xl text-stone-400 text-center">
									You have not filled any {tabName} forms
								</p>
							</div>
						)}
					</Box>
				</div>
			) : (
				<div hidden={value !== index} className='mt-12'>
					<img src={Notaccess} alt={Notaccess} className="m-auto h-32 w-32" />
					<p className="text-2xl text-stone-400 text-center">
						Sorry you do not have access to this form
					</p>
				</div>
			)}
		</React.Fragment>
	);
}

export default TabPanel;
