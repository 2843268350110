/** @format */
import React, { useEffect, useState } from 'react';
import 'fontsource-roboto';
import { Typography, Grid, Button } from '@material-ui/core';
import AdminInfoForm from './AdminInfoForm.js';

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import './Client.css';

/** component for admin information card on each user */
const AdminInfo = ({ user }) => {
	const [edit, setEdit] = useState(false);
	return (
		<React.Fragment>
			<div>
				{edit ? (
					<AdminInfoForm user={user} setEdit={setEdit} />
				) : (
					<div className="profile_form_wrapper edit_info_spacing client_font_stypeP">
						<div className="client_notes_btn">
							<h5>Notes</h5>
						</div>
						<Typography className="pb-12">
							<p className="w-full text-base text-gray-600 justify-start pt-4">{user.notes}</p>
						</Typography>

						<div className="client_notes_btn">
							<h5>Hashtags</h5>
						</div>
						<Typography className="pb-10">
							<p className="w-full text-base text-gray-600 justify-start pt-4">{user.hashtags}</p>
						</Typography>
						<div className="client_notes_btn">
							<h5>Spending Tracker</h5>
						</div>
						<Typography className="pb-12">
							<p className="w-full text-base text-gray-600 justify-start pt-4">{user.spendingTracker}</p>
						</Typography>
						<div className="client_notes_btn">
							<h5>Customer Id</h5>
						</div>
						<Typography className="pb-12">
							<p className="w-full text-base text-gray-600 justify-start pt-4">{user.customerId}</p>
						</Typography>


						<Typography className="text-right flex justify-end" mt={6}>
							<button
								type="submit"
								onClick={() => setEdit(true)}
								variant="contained"
								className="h-14 client_btn"
							>
								<ModeEditOutlineIcon />
							</button>
						</Typography>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};
export default AdminInfo;
