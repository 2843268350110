import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';

const OtherInfoCard = ({ setEdit }) => {
	const currentUser = useSelector((st) => st.user.currentUser);

	return (
		<div className="profile_form_wrapper edit_info_spacing pt-6">
			<Typography className="pb-6">
				<h3 className="text-gray-900 text-lg leading-8">What are your main service areas? </h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">
					{currentUser.serviceAreas}
				</p>
			</Typography>

			<Typography className="pb-6">
				<h3 className="text-gray-900 text-lg leading-8">What makes you unique in real estate?</h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{currentUser.uniqueness} </p>
			</Typography>

			<Typography className="pb-6">
				<h3 className="text-gray-900 text-lg leading-8">
					Where are some of your favorite places within your service area(s)?
				</h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">
					{currentUser.favoritePlaces}{' '}
				</p>
			</Typography>

			<Typography className="pb-6">
				<h3 className="text-gray-900 text-lg leading-8">
					Where is your favorite place(s) to which you have traveled?
				</h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">
					{currentUser.travelDestinations}
				</p>
			</Typography>

			<Typography className="pb-6">
				<h3 className="text-gray-900 text-lg leading-8">What are some of your hobbies?</h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">{currentUser.hobbies}</p>
			</Typography>
			<Typography className="pb-6">
				<h3 className="text-gray-500 text-lg leading-8">Other fun details about you</h3>
				<p className="w-full text-lg text-gray-600 font-medium justify-start pt-2">
					{currentUser.otherDetails}
				</p>
			</Typography>

			<div className="detail_btn_box">
				<Typography className="text-right flex justify-end" mt={6}>
					<Button
						onClick={() => setEdit(true)}
						type="submit"
						variant="contained"
						className="h-12 setting_btn"
					>
						Edit
					</Button>
				</Typography>
			</div>
		</div>
	);
};

export default OtherInfoCard;
