import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import SublyApi from "../helpers/Api";

// First, create the thunk
export const adminSignup = createAsyncThunk(
	"admin/adminSignup",
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.adminSignup(data);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Thunk for admin login
export const adminLogin = createAsyncThunk(
	"admin/adminLogin",
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.adminLogin(data);
			console.log(response);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Thunk for getting admin
export const getAdmin = createAsyncThunk(
	"admin/getAdmin",
	async (data, { rejectWithValue }) => {
		try {
			const response = await SublyApi.getAdmin(data.username, data.token);

			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const initialState = {
	allAdmins: [],
	currentAdmin: {},
	token: null,
	loading: false,
	errors: [],
	success: false, // monitors the success of an api call, in case it needs to be passed to a component
	remember: true, // controls whether an admin's info is stored after dismount
};

export const adminSlice = createSlice({
	name: "admin",
	initialState: initialState,
	reducers: {
		adminLogout: (state, action) => {
			return initialState;
		},
		clearSuccess: (state, action) => {
			state.success = false;
		},
		clearErrors: (state, action) => {
			state.errors = [];
		},
		addToStorage: (state, action) => {
			state.currentAdmin = { ...state.currentAdmin, ...action.payload };
		},
		changeRemember: (state, action) => {
			state.remember = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(adminSignup.fulfilled, (state, action) => {
				state.currentAdmin = action.payload.admin;
				state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(adminSignup.pending, (state, action) => {
				state.errors = [];
			})
			.addCase(adminSignup.rejected, (state, action) => {
				state.errors = action.payload;
			})
			.addCase(adminLogin.fulfilled, (state, action) => {
				state.currentAdmin = action.payload.admin;
				state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(adminLogin.rejected, (state, action) => {
				state.errors = action.payload;
			})
			.addCase(adminLogin.pending, (state, action) => {
				state.errors = [];
			})
			.addCase(getAdmin.fulfilled, (state, action) => {
				state.currentAdmin = action.payload.admin;
				state.token = action.payload.token;
				state.errors = [];
			})
			.addCase(getAdmin.rejected, (state, action) => {
				state.errors = action.payload;
			});
	},
});

export const {
	adminLogout,
	clearSuccess,
	clearErrors,
	addToStorage,
	changeRemember,
} = adminSlice.actions;

export default adminSlice.reducer;

// extraReducers : {
// 	[adminSignup.fulfilled]: (state, action) => {
// 		state.currentAdmin = action.payload.admin;
// 		state.token = action.payload.token;
// 		state.errors = [];
// 	},
// 	[adminSignup.pending]: (state, action) => {
// 		state.errors = [];
// 	},
// 	[adminSignup.rejected]: (state, action) => {
// 		state.errors = action.payload;
// 	},
// 	[adminLogin.fulfilled]: (state, action) => {
// 		state.currentAdmin = action.payload.admin;
// 		state.token = action.payload.token;
// 		state.errors = [];
// 	},
// 	[adminLogin.rejected]: (state, action) => {
// 		state.errors = action.payload;
// 	},
// 	[adminLogin.pending]: (state, action) => {
// 		state.errors = [];
// 	},
// 	[getAdmin.fulfilled]: (state, action) => {
// 		state.currentAdmin = action.payload.admin;
// 		state.token = action.payload.token;
// 		state.errors = [];
// 	},
// 	[getAdmin.rejected]: (state, action) => {
// 		state.errors = action.payload;
// 	}
// }
