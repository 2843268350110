import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavGroup.css';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../store/user.js';
import { adminLogout } from '../store/admin.js';
// import { adminLogout, deleteAdminToken } from '../actions/currentAdmin';

/** Component for a group of links. Takes in an array as a prop and creates navigation links with the given name */
function NavGroup({ links }) {
	const dispatch = useDispatch();

	//function for removing user information from redux storage.
	const logout = async () => {
		dispatch(userLogout());
		dispatch(adminLogout());
	};
	const adminSignout = () => {};

	return (
		<div data-testid="nav-group" className="ml-10 space-x-1 sm:space-x-4 z-40">
			{links.map((link, indx) => (
				//Note: the link needs to be a logout link for it to trigger to logout function
				<NavLink key={indx} className={`${link.style}`} onClick={link.logout && logout} to={`/${link.path}`}>
					{link.name}
				</NavLink>
			))}
		</div>
	);
}

export default NavGroup;
