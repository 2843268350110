import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
// import { logout, deleteToken } from '../../actions/currentUser';
import { userLogout, getUser } from '../../store/user.js';
// import { getUserFromApi } from '../../actions/currentUser';
import { Typography, Button, Container, Grid, Box } from '@material-ui/core';
import { Link, useHistory, NavLink } from 'react-router-dom';

import Email from '../../img/email.png';
import './Home.css';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	bgcolor: 'background.paper',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 12,
};

/** Popup component for verifying a user
 * Unverified user will initially see this component with a welcome message.
 * Once user is verified, user can click "verify" button to to confirm verification.
 * If verified, modal goes away.  If not, modal gives 'fail' message.
 */

const VerifyModal = ({ seconds, setSeconds }) => {
	const dispatch = useDispatch();
	const userMessage = {
		start:
			'Welcome to Jane! To have access, you must verify your email. Check your email and click on the provided link',
		fail: 'Sorry. Try again. Your email is not yet verified. Contact admin, if this persists',
	};

	const currentUser = useSelector((st) => st.user.currentUser);
	const token = useSelector((st) => st.user.token);

	//this is the state for the modal being open or closed
	const [open, setOpen] = useState(!currentUser.verified);

	//this is the state for determining which message to show on the modal
	const [modalMessage, setModalMessage] = useState(userMessage.start);

	//this state is true or false depending on whether the user has clicked the 'verify' button to confirm verification.
	const [checkVerify, setCheckVerify] = useState(false);

	//check if user is verified.  If so, close modal.
	useEffect(() => {
		if (currentUser.verified) setOpen(false);
	}, [currentUser.verified, checkVerify]);

	//if the user hits 'verified' button and is still not verified (modal open), we change the modal message to the fail message.
	useEffect(() => {
		if (open && checkVerify) setModalMessage(userMessage.fail);
	}, [open, checkVerify]);

	//this handles what happens when the user tries to close the modal by hitting 'verified' button.
	const handleClose = () => {
		dispatch(getUser({ username: currentUser.username, token: token }));
		setCheckVerify(true);
	};

	/** When user isn't verified, a modal covers this page. Signout button isn't available.
	 * We need to provide a link on modal for user to log out if they can't be verified, or they will be stuck on this page.
	 */
	const signOut = () => {
		dispatch(userLogout());
	};

	return (
		<Modal
			data-testid='verify-modal'
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
			className='border-2 border-gree-800 modal_full_bg'>
			<Box className='modal_bg rounded-2xl relative z-1 ' sx={style}>
				<div className='popup_img absolute bg-white rounded-full p-6 -top-20 z-1'>
					<img className='w-auto h-auto ' src={Email} alt='Email' />
				</div>
				<h2
					className='pt-20 text-2xl font-bold text-left text-gray-900'
					id='parent-modal-title'>
					Verify email address
				</h2>
				<Typography
					className='pt-4 pb-12 px-0 text-base text-left text-gray-700 font-normal leading-8'
					id='modal-modal-description'
					sx={{ mt: 1 }}>
					{modalMessage}
				</Typography>
				<Button
					className='bg-blue-700 w-5/12 h-12 text-xl veryfy_btn'
					onClick={handleClose}
					variant='contained'>
					I’M VERIFIED
				</Button>
				<NavLink
					className='veryfy_go_back text-base font-medium text-blue-600 pl-4'
					onClick={signOut}
					to={`/login`}>
					I want to go back
				</NavLink>
			</Box>
		</Modal>
	);
};

export default VerifyModal;
