import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import 'fontsource-roboto';
import './Settings.css';

const BusinessInfoCard = ({ setEdit }) => {
	const dispatch = useDispatch();
	const currentUser = useSelector((st) => st.user.currentUser);

	return (
		<div className="profile_form_wrapper edit_info_spacing pt-6">
			<Grid container spacing={3}>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Primary Phone</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">{currentUser.phone}</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Designations</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">
							{currentUser.designations}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Certifications</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">
							{currentUser.certifications}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Real Estate Specialities</label>
						<p className="w-full break-all  text-lg text-gray-600 font-medium justify-start">
							{currentUser.specialties}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">License #</label>
						<p className="w-full break-all  text-lg text-gray-600 font-medium
                       justify-start">
							{currentUser.licenseNumber}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Office Address One</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">
							{currentUser.officeAddressOne}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Office Address Two</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">
							{currentUser.officeAddressTwo}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Office City</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">
							{currentUser.officeCity}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Office State</label>
						<p className="w-full break-all  text-lg text-gray-600 font-medium justify-start">
							{currentUser.officeState}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Office Zip</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">
							{currentUser.officeZip}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">MLS Association</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">{currentUser.mlsInfo}</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Website URL</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">
							{currentUser.websiteUrl}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">Google Business Page URL</label>
						<p className="w-full break-all text-lg text-gray-600 font-medium justify-start">
							{currentUser.googleBusinessUrl}
						</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">CRM Name</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">{currentUser.crmName}</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">CRM Login Username</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">**********</p>
					</Typography>
				</Grid>
				<Grid item lg={4} md={6} sm={12} xs={12}>
					<Typography className="w-2/4 p-0 inline">
						<label className="leading-9 text-lg text-gray-900">CRM Login Password</label>
						<p className="w-full text-lg text-gray-600 font-medium justify-start">**********</p>
					</Typography>
				</Grid>
				<Typography className="text-right flex justify-end" mt={2}>
					<Button
						onClick={() => setEdit(true)}
						type="submit"
						variant="contained"
						className="h-12 setting_btn"
					>
						Edit
					</Button>
				</Typography>
			</Grid>
		</div>
	);
};

export default BusinessInfoCard;
