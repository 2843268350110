import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addErrors, clearErrors } from "../store/user";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import JRLogo from "../img/JR_Logo.svg";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SublyApi from "../helpers/Api";
import "./Login.css";

/** Form for logging user in */
const ForgotPassword = () => {
	const dispatch = useDispatch();
	const error = useSelector((st) => st.user.errors);
	const [open, setOpen] = useState(false); // state for confirmation modal
	const [windowHeight, setWindowHeight] = useState(); //state to store the height of the window screen

	useEffect(() => {
		function getWindowHeight() {
			// we have set a dynamic height to make the screen responsive in all screen resolution
			setWindowHeight(window.screen.availHeight);
		}
		getWindowHeight();
		window.addEventListener("resize", getWindowHeight); // the event trigered on the resolution change
		return () => window.removeEventListener("resize", getWindowHeight);
	}, []);

	// sets error to null when the component first mounts and when it dismounts.
	useEffect(() => {
		dispatch(clearErrors());
		return () => {
			dispatch(clearErrors()); // dismount
		};
	}, []);

	const INITIAL_STATE = {
		username: "",
	};

	// sets state for form data and the kind of menu, which can be toggled.
	const [formData, setFormData] = useState(INITIAL_STATE);

	// handles the change in the form. react sets form data in state every time there is a change.
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((formData) => ({ ...formData, [name]: value }));
	};

	//submits the form data to the Api to send user email
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			dispatch(clearErrors());
			await SublyApi.forgotPassword(formData.username);
			setFormData(INITIAL_STATE);
			setOpen(true);
		} catch (err) {
			dispatch(addErrors(err));
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box
			className={`${
				windowHeight <= 480 ? "max-h-max h-full" : " h-screen"
			} bg-real-estate flex items-center justify-center relative z-0`}>
			<Backdrop sx={{ color: "#fff", zIndex: 1 }} open={true} />
			<Container
				sx={{ justifyContent: "center", display: "flex" }}
				maxWidth="md"
				className="mt-28 mb-10">
				<Stack spacing={4} className="w-full iphone:w-11/12 sm:w-2/3 md:w-7/12">
					<Stack
						spacing={4}
						className="rounded-2xl pb-6  px-5 bg-white z-10 pt-0">
						<Stack
							spacing={1}
							className="py-8 rounded-2xl -mt-12 bg-secondary  shadow-2xl">
							<img
								className="h-12 iphone:h-16 w-auto m-0"
								src={JRLogo}
								alt="Jane Logo"
							/>
							<div className="text-white text-center text-xl md:text-3xl font-bold">
								Forgot password?
							</div>
						</Stack>
						<Stack
							spacing={{ xs: 2 }}
							component="form"
							onSubmit={handleSubmit}
							className="px-3 ">
							<TextField
								autoFocus={true}
								id="username"
								label="Username"
								variant="standard"
								type="text"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<AccountCircle fontSize="small" />
										</InputAdornment>
									),
									sx: {
										fontSize: { xs: "1rem", md: "1.25rem" },
										lineHeight: { xs: "1.25rem", md: "1.5rem" },
									},
								}}
								name="username"
								value={formData.username}
								onChange={handleChange}
								className="border-5"
							/>

							<div className="text-red-900 mb-3 ">
								{error.length
									? error.map((e, indx) => (
											<div key={indx}>
												<small>ERROR: {e}</small>
											</div>
									  ))
									: null}
							</div>

							<Box className="flex justify-center">
								<Button
									type="submit"
									sx={{
										fontWeight: 600,
										fontSize: { xs: "1rem", sm: "1.125rem" },
										lineHeight: { xs: "1.5rem", sm: "1.75rem" },
									}}
									color="secondary">
									Email Instructions
								</Button>
							</Box>
						</Stack>
					</Stack>
					<div className=" flex justify-center font-semibold sm:font-bold sm:text-lg md:text-xl text-white py-2 z-10">
						<div>
							<div />
							<NavLink
								exact
								to="/login"
								className=" underline hover:text-primary block text-center">
								Back to login
							</NavLink>{" "}
						</div>
					</div>
				</Stack>
			</Container>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{"Email Sent!"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						An email has been sent to your provided email address with
						instructions to reset your password.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Sounds Good!
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ForgotPassword;
