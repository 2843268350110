import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { changeToVerified } from '../store/user';
import Timer from '../helpers/Timer';

import EmailConfirm from '../img/emailconfirm.png';
import './Verify.css';

/** component for screen that confirms user has verified their email.
 *
 * users get to this screen after clicking on their email.
 *
 * Component give user a success message and forwards user to home page.
 */
const Verify = () => {
	const [seconds, setSeconds] = useState(8);
	const [isVerifiedChangeCalled, setIsVerifiedChangeCalled] = useState(false);
	const { username, code } = useParams();

	const currentUser = useSelector((st) => st.user.currentUser);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (currentUser && !currentUser.verified && !isVerifiedChangeCalled) {
			dispatch(changeToVerified({ username: username, token: code }));
			setIsVerifiedChangeCalled(true);
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser?.verified) {
			console.log('current user is verified');
			if (seconds <= 0) history.push('/home');
		}
	}, [currentUser?.verified, seconds]);

	return (
		<div
			data-testid='verify-wrapper'
			className='email_confirm_wrapper text-center relative z-1'>
			<div className='PayPage max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-4 pb-5 text-center'>
				<div className='container text-center m-auto'>
					<div className='emain_img'>
						<Typography>
							<img
								className='w-auto h-auto text-center'
								src={EmailConfirm}
								alt='EmailConfirm'
							/>
						</Typography>
					</div>
					<Typography
						gutterBottom
						variant='h1'
						className='emailverify_heading font-bold text-center text-4xl text-gray-900 pt-5 leading-5 m-0'>
						{' '}
						Email Confirmation
					</Typography>
					<Typography
						className='pt-0 pb-8 px-0 text-base text-center text-gray-700 font-normal leading-7 emailverify_time'
						variant='h6'
						gutterBottom>
						Your Email has been Verified. We will reroute you in{' '}
					</Typography>
					<div className='email_timer'>
						<div className='text-6xl font-bold text-white	bg-black rounded-full p-4 w-24 m-auto '>
							{<Timer seconds={seconds} setSeconds={setSeconds} />}
						</div>
						<p className='pt-2 pb-9 text-lg font-bold text-gray-700 leading-8'>
							{' '}
							Seconds
						</p>
					</div>
					<NavLink exact to='/home' color='green' className=''>
						<Button
							className='md:w-3/12 sm:6/12 h-12 text-base veryfy_btn rounded-xl'
							variant='contained'
							color='primary'>
							Go to Jane
						</Button>
					</NavLink>{' '}
				</div>
			</div>
		</div>
	);
};

export default Verify;
