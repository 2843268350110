import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
	Typography,
	Button,
	Tab,
	Box,
	Tabs,
	Grid,
	Container,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import BusinessInfo from './BusinessInfo';
import BrokerInfo from './BrokerInfo';
import OtherInfo from './OtherInfo';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					{children}
					{/* <Typography></Typography> */}
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const DetailCard = () => {
	const [value, setValue] = React.useState(0);
	const scollToRef = useRef();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClickScroll = () => {
		// Will scroll smoothly to the top of the next section
		scollToRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
	};
	return (
		<div ref={scollToRef} className=' p-8 bg-white  shadow '>
			<div className='profile_form_wrapper pb-4'>
				<div className='detail_wrapper'>
					{/* View only Detail section start */}
					<Tabs
						className='justify-center'
						// orientation="vertical"
						variant='scrollable'
						value={value}
						onChange={handleChange}>
						<Tab
							onClick={() => handleClickScroll()}
							className=' text-base '
							label='Business Info'
							{...a11yProps(0)}
						/>
						<Tab
							onClick={() => handleClickScroll()}
							className=' text-base'
							label='Broker Info'
							{...a11yProps(1)}
						/>
						<Tab
							onClick={() => handleClickScroll()}
							className=' text-base'
							label='Other Info'
							{...a11yProps(2)}
						/>
					</Tabs>
					<Grid className='pl-0'>
						<TabPanel value={value} index={0}>
							<BusinessInfo />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<BrokerInfo />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<OtherInfo />
						</TabPanel>
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default DetailCard;
