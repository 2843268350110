import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ConfirmationDialog({ title, text, handleClose, open, handleConfirmation, confirmation, close }) {
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="quarternary">
						{close}
					</Button>
					{confirmation && (
						<Button onClick={handleConfirmation} color="primary" autoFocus>
							{confirmation}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default ConfirmationDialog;
